<template>
  <div
    class="vvenue-chat"
    data-testid="vvenue-chat"
    :style="cssVars"
    :class="{
      'full-screen': fullScreen || (mobileMode && mobileButtonChecked),
      'mobile-hidden': !mobileButtonChecked && !fullScreen && mobileMode,
      'is-embed': isEmbed
    }"
    v-if="chatIsVisibleAndUnmutedData"
  >
    <VideoChat
      v-if="showVideoChat && enableVideoChat && currentVideoChat"
      @close="showVideoChat = false"
      :current-video-chat="currentVideoChat"
      :profile-settings="profileSettings"
      :translations="translations"
      :chat-is-visible-and-unmuted-data="chatIsVisibleAndUnmutedData"
      :ot-get-token="otGetToken"
    >
    </VideoChat>
    <!-- Chat windows -->
    <div class="responsive-button" v-if="mobileMode && !fullScreen" v-on:click="toggleMobileButton()">
      <messages-button></messages-button>
      <div class="counter clickable" v-if="allMessageCount">
        {{ allMessageCount }}
      </div>
    </div>

    <ChatWindow
      v-for="(key, index) in onlyOpenChannels"
      :key="index"
      :pns="pns"
      :channel-key="key"
      :uuid="uuid"
      :channels="channels"
      :class="{
        open: channels[key].isExpanded || (isEmbed && mainWindowExpanded),
        'resp-open': isEmbed && respMainWindowOpen,
        isLandscapeMode: isLandscapeMode
      }"
      :full-screen="fullScreen"
      :isEmbed="isEmbed"
      :is-landscape-mode="isLandscapeMode"
      :isAnonymVenue="isAnonymVenue"
      :hasOnlyEmbedded="hasOnlyEmbedded"
      :profile-settings="profileSettings"
      :enable-video-chat="videoChatButtonEnabled && enableVideoChat"
      :message-count="channelMessageCounts[channels[key].subscription_name]"
      :messages="messages[channels[key].subscription_name]"
      :data-files-enabled="dataFilesEnabled"
      :users-data="usersData"
      :primary-color="primaryColor"
      :secondary-color="secondaryColor"
      :active-users-uuids="activeUsersUUIDs"
      :translations="translations"
      @force-rerender="forceRerenderComputedProps++"
      @close-profile-settings="checkAndCloseProfileSettings()"
      @uuid-clicked="(uuid) => uuidClicked(uuid)"
      @update-screen-settings="
        (s) => {
          fullScreen = s.fullScreen;
          respMainWindowOpen = s.respMainWindowOpen;
          mobileButtonChecked = s.mobileButtonChecked;
        }
      "
      @open-chat="(s) => openChat(s.channelKey, s.forceToggle, s.forceOpen, s.forceClose)"
      @open-video-chat="(channel, sendFromMessage) => openVideoChat(channel, sendFromMessage)"
      @send-message="
        async (key, message, cb) => {
          await pns.pubnub_pushMessage(key, message);
          cb();
        }
      "
    >
    </ChatWindow>

    <!-- Main Window  -->
    <div
      v-if="!isEmbed"
      class="chat-window"
      :class="{
        open: mainWindowExpanded,
        'responsive-main': mobileMode,
        'resp-open': respMainWindowOpen
      }"
    >
      <div class="slide-in-btn" v-on:click="toggleRespMainWindowOpen()" v-if="fullScreen && mobileMode">
        <chevron-right :class="{ rotated: respMainWindowOpen }"></chevron-right>
      </div>
      <div class="header" :class="{ 'settings-open': settingsOpen }">
        <span v-if="!settingsOpen">
          <div class="main-title" v-if="!fullScreen">
            {{ translations.main.chats }}
            <div
              class="counter clickable"
              v-if="!mainWindowExpanded && allMessageCount"
              v-on:click="
                toggleMainWindowExpanded(true);
                openLastIncoming(true, true, false);
              "
            >
              {{ allMessageCount }}
            </div>
            <span class="icon icon-close" v-on:click="hideAndMuteChat()" v-if="showHideAndMuteChatButtonProp"></span>
            <span class="icon icon-minus" v-if="mainWindowExpanded" v-on:click="toggleMainWindowExpanded(false)"></span>
            <span class="icon icon-open" v-if="!mainWindowExpanded" v-on:click="toggleMainWindowExpanded(true)"></span>

            <full-screen-button
              class="icon-component"
              v-on:click.native="
                fullScreen = true;
                toggleMainWindowExpanded(true);
              "
            ></full-screen-button>
          </div>
          <div class="my-name" v-if="mainWindowExpanded">
            <div
              class="status-ellipse"
              :style="{
                backgroundColor: statusColors[(!chatIsOnline ? 'Offline' : false) || profileSettings.status]
              }"
            ></div>

            {{ cutString(profileSettings.name) }}
            <settings-button
              class="icon-component settings-btn"
              v-on:click.native="(settingsOpen = true), toggleMainWindowExpanded(true)"
            ></settings-button>
            <full-screen-button
              class="icon-component svg-primary-color transformrotate90 transform47"
              style="margin-top: 4px"
              v-if="fullScreen"
              v-on:click.native="toggleMobileButton(false)"
            ></full-screen-button>
          </div>
        </span>
        <!-- SETTINGS START -->
        <span v-if="settingsOpen" v-on:keyup.enter="checkAndCloseProfileSettings()">
          <div class="main-title">
            Settings<span class="icon icon-close" v-on:click="checkAndCloseProfileSettings()"></span>
          </div>
          <div class="settings-wrap">
            <div class="settings-section" v-if="optionsShowChangingAliasName">
              <div class="main-title">
                <avatar-button class="icon-component svg-white-color avatar"></avatar-button>
                <p
                  class="change-name main-title"
                  v-on:keydown="$event.stopPropagation()"
                  v-on:keyup="$event.stopPropagation()"
                  v-on:keypress="$event.stopPropagation()"
                  contenteditable="true"
                  v-text="profileSettings.name"
                  v-on:keydown.enter="profileSettings.name = ($event.target as HTMLElement).innerText"
                  v-on:blur="profileSettings.name = ($event.target as HTMLElement).innerText"
                  placeholder="Enter a Name"
                ></p>
                <edit-button
                  class="icon-component edit-btn"
                  v-on:click.native="setCaretToEnd($event.currentTarget.previousElementSibling)"
                ></edit-button>
              </div>
            </div>
            <div class="settings-section status-wrap" v-if="optionsShowChangingStatus">
              <div v-for="(stat, index) of ['Available', 'Idle', 'Busy', 'Offline']" :key="index">
                <div
                  class="main-title"
                  :class="{ opacity: stat != profileSettings.status }"
                  v-on:click="profileSettings.status = stat"
                >
                  <div class="status-ellipse" :style="{ backgroundColor: statusColors[stat] }"></div>
                  {{ translations.main.settings[stat] }}
                </div>
              </div>
            </div>
            <div class="settings-section">
              <div
                class="main-title"
                :class="{ opacity: !profileSettings.muteAllSounds }"
                v-on:click="profileSettings.muteAllSounds = !profileSettings.muteAllSounds"
              >
                <mute-button
                  class="icon-component no-margin-left transform-btn"
                  v-if="profileSettings.muteAllSounds"
                  :class="{ 'no-opacity': profileSettings.muteAllSounds }"
                ></mute-button>
                <speaker-button
                  class="icon-component no-margin-left transform-btn"
                  v-if="!profileSettings.muteAllSounds"
                  :class="{ 'no-opacity': profileSettings.muteAllSounds }"
                ></speaker-button>
                {{ translations.main.settings.mute_all_sounds }}
              </div>
            </div>
          </div>
        </span>
        <!-- SETTINGS END -->
      </div>
      <div class="content" :class="{ open: mainWindowExpanded }" v-if="mainWindowExpanded && !chatIsOnline">
        <div class="category">
          <div class="category-title">
            {{ translations.main.chat_is_offline }}
          </div>
        </div>
      </div>
      <div class="content" :class="{ open: mainWindowExpanded }" v-if="mainWindowExpanded && chatIsOnline">
        <div v-for="(cts, i) in channelsComputed" :key="i" :class="{ category: cts.length }">
          <div class="category-title" v-if="cts.length || (i == 2 && activeUsersUUIDsFiltered.length)">
            <span v-if="i == 0">{{ translations.main.recent_chats }}</span>
            <span v-if="i == 1">{{ translations.main.group_chats }}</span>
            <span v-if="i == 2">{{ translations.main.active_users }}</span>
            <span class="cat-count">{{ cts.length + (i == 2 ? activeUsersUUIDsFiltered.length : 0) }} </span>
          </div>
          <transition-group name="fade">
            <div class="li-el" v-for="(key, index) in cts" :key="key" v-on:click="uuidClicked(channels[key].uuid)">
              <div
                class="avatar"
                v-bind:style="{
                  backgroundColor: channels[key].avatarBackground,
                  backgroundImage:
                    channels[key].type == 'public' && !channels[key].dynamic && logoSrc
                      ? 'url(' + logoSrc + ')'
                      : getProfilePic(channels[key].uuid)
                }"
                v-on:click="openChat(key, true, true, false)"
              >
                <span
                  v-if="
                    !(channels[key].type == 'public' && !channels[key].dynamic && logoSrc) &&
                    !getProfilePic(channels[key].uuid)
                  "
                  v-bind:class="{
                    bold: channelMessageCounts[channels[key].subscription_name]
                  }"
                >
                  {{ getAvatarLetters(channels[key].display_name) }}
                </span>
              </div>
              <div
                class="name"
                :class="{
                  opacity: channels[key].isOpen,
                  bold: channelMessageCounts[channels[key].subscription_name]
                }"
                v-on:click="openChat(key, true, true, false)"
              >
                {{ cutString(channels[key].display_name) }}
                <transition name="bounce">
                  <div v-if="channels[key].new" class="new" :key="index">
                    {{ translations.main.new }}
                  </div>
                </transition>
              </div>
              <transition name="bounce">
                <div
                  :key="'counter' + i + channels[key].subscription_name"
                  class="counter"
                  v-if="channelMessageCounts[channels[key].subscription_name]"
                  v-bind:style="{
                    color: channels[key].avatarBackground
                  }"
                >
                  {{ channelMessageCounts[channels[key].subscription_name] }}
                </div>
              </transition>
              <close-button
                v-if="channels[key].isOpen"
                class="icon-component svg-primary-color transform47 margin-left-auto"
                v-on:click.native="openChat(key, true, false, true)"
                :style="{ path: { fill: channels[key].avatarBackground } }"
              ></close-button>
            </div>
          </transition-group>
        </div>
        <div>
          <div class="category-title" v-if="activeUsersUUIDsFiltered.length">
            <span>{{ translations.main.active_users }}</span>
            <span class="cat-count">{{ activeUsersUUIDsFiltered.length }} </span>
          </div>
          <div class="li-el" v-for="chat of activeUsersUUIDsFiltered" :key="chat.uuid">
            <div
              class="avatar"
              v-bind:style="{
                backgroundColor: (usersData[chat.uuid] && usersData[chat.uuid].avatarBackground) || secondaryColor,
                backgroundImage: chat.profile_picture
              }"
              v-on:click="
                openChat(chat.uuid, true, true, false, true);
                uuidClicked(chat.uuid);
              "
            >
              <span v-if="!chat.profile_picture">
                {{ getAvatarLetters(chat.display_name || "N L") }}
              </span>
            </div>
            <div
              class="name"
              v-on:click="
                openChat(chat.uuid, true, true, false, true);
                uuidClicked(chat.uuid);
              "
            >
              {{ cutString(chat.display_name || translations.main.loading) }}
            </div>
          </div>
        </div>
      </div>
      <div class="footer" :class="{ open: mainWindowExpanded }" v-if="chatIsOnline">
        <input
          @keydown.stop=""
          @keyup.stop=""
          @keypress.stop=""
          type="text"
          v-model="mainWindowSearch"
          class="search-input"
          :placeholder="translations.main.search"
          :class="{ 'input-sticky': stickyInput === 'search-input' && isLandscapeMode }"
          @focus="stickyInput = 'search-input'"
          @blur="stickyInput = ''"
        />
        <search-button class="svg-primary-color" v-if="!mainWindowSearch.length"></search-button>
        <div
          class="empty-search"
          v-if="mainWindowSearch.length"
          v-bind:style="{ color: primaryColor }"
          v-on:click="mainWindowSearch = ''"
        >
          ×
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { nanoid } from "nanoid";
import { Channel, ChatData, Translations } from "./types";
import { expMixins } from "./functions";
import {
  SearchButton,
  CloseButton,
  MuteButton,
  SpeakerButton,
  AvatarButton,
  EditButton,
  SettingsButton,
  ChevronRight,
  MessagesButton,
  FullScreenButton
} from "./components/svgs.vue";
import VideoChat from "./components/VideoChat.vue";
import ChatWindow from "./components/ChatWindow.vue";
import { PNService } from "./pubnub_functions";
import Pubnub from "pubnub";
import { PropType } from "vue";
import { translations } from "./translations";

export default {
  name: "VvenueChat", // vue component name
  components: {
    SearchButton,
    CloseButton,
    MuteButton,
    SpeakerButton,
    ChatWindow,
    AvatarButton,
    EditButton,
    SettingsButton,
    ChevronRight,
    MessagesButton,
    FullScreenButton,
    VideoChat
  },
  data(): ChatData {
    return {
      surnames: expMixins.data.surnames,
      stickyInput: "",
      chatIsVisibleAndUnmutedData: true,
      subscribePrivateChannelsInterval_queue: [],
      pns: null,
      debug: {
        deleteAllMemberships: false,
        openVideoAtStartUp: false,
        overwriteSessionForVideo: false
      },
      channels: {},
      messages: {},
      usersData: {},
      activeUsersUUIDs: [],
      pubnub: null,
      pubnubListener: null,
      baseChannel: "test_base",
      setActiveUsersUUIDsArrayInterval_id: null,
      mainWindowSearch: "",
      channelMessageCounts: {},
      messagesLoading: false,
      mainWindowExpanded: true,
      settingsOpen: false,
      statusColors: {
        Available: "#0ACEE7",
        Idle: "#E1921C",
        Busy: "#BB2222",
        Offline: "#0C1D39"
      },
      profileSettings: {
        name: "Guest",
        status: "Available",
        muteAllSounds: true,
        uuid: "",
        profilePic: null
      },
      sizes: {
        amountChatWindowsPossible: 2,
        windowWidth: 0,
        windowHeight: 0
      },
      isLandscapeMode: false,
      openChatCount: 1,
      fullScreen: false,
      respMainWindowOpen: true,
      mobileButtonChecked: false,
      showVideoChat: false,
      // chatRoom: null,
      chatIsOnline: true,
      uuid: null,
      videoChatButtonEnabled: true,
      currentVideoChat: null,
      forceRerenderComputedProps: 0,
      dataFilesEnabled: true,
      lockSend: false,
      lastIncomingChannels: [],
      mobileMode: false,
      intervalIsUpdatingChats: false,
      actualChannelUuids: [],
      isEmbed: false
    };
  },

  props: {
    publishKey: {
      type: String,
      required: true
    },
    subscribeKey: {
      type: String,
      required: true
    },
    //should be a full hex string. no shortcodes.
    primaryColor: {
      type: String,
      default: "#22AABB"
    },
    //should be a full hex string. no shortcodes.
    secondaryColor: {
      type: String,
      default: "#983AD2"
    },
    globalChannels: {
      type: Array,
      default: []
    },
    // watched array of channel names. These get subscribed as soon as they assigned. They are also persistent for a user if he already interacted with the channel
    dynamicChannels: {
      type: Array,
      default: []
    },
    // this must be unique for every session for restoring the pubnub session. Either firebase token or email.
    // If not provided it will be generated for every session and the user is named arbitrarely.
    predefinedUuid: {
      type: String
    },
    // provide an alternative initial alias (in sync if Changes enabled in optionsShowChangingAliasName)
    predefinedAliasName: {
      type: String,
      required: false
    },
    //some functions are interval based. you can manually set this value
    intervalLengthInMs: {
      type: Number,
      default: 30000
    },
    //provide the sound as https:// url - important for cors
    soundUrl: {
      type: String
    },
    mainWindowInitiallyExpanded: {
      default: true
    },
    enableVideoChat: {
      type: Boolean,
      default: false
    },
    //saves the automatically generated uuid in the localStorage - mostly for debug reasons
    saveUserInLocalStorage: {
      default: false
    },
    //logo which is used for globalChannel avatars, either url or base64: so that background-image:url(logoSrc) works
    logoSrc: {
      required: false
    },
    //toggle fullScreen
    isFullScreenProp: {
      required: false,
      type: Boolean
    },
    mainWindowExpandedProp: {
      required: false,
      type: Boolean
    },
    //open chat by (predefined) uuid
    openChatByUuidProp: {
      required: false,
      type: String
    },
    //chat is visible and un-muted
    chatIsVisibleAndUnmutedProp: {
      required: false,
      type: Boolean,
      default: true
    },
    //On message open Chat (if chat invisible and muted) if !chatIsVisibleAndUnmutedProp
    onMessageOpenChatProp: {
      required: false,
      type: Boolean,
      default: true
    },
    //not sync but watched
    showActiveUserListProp: {
      required: false,
      type: Boolean,
      default: true
    },
    //not sync but watched
    //Use this with chatisvisibleandunmuted -> dont use it you dont provide chatIsVisibleAndUnmutedProp from outside
    showHideAndMuteChatButtonProp: {
      required: false,
      type: Boolean,
      default: true
    },
    //custom translations
    translations: {
      type: Object as PropType<Translations>,
      required: false,
      default: translations
    },
    //Function to Generate a OpenTok Session
    // async () => string
    otCreateSession: {
      required: false
    },
    //Function to Generate a OpenTok Token
    // async () => string
    otGetToken: {
      required: false
    },
    // OpenTok ApiKey
    otApiKey: {
      required: false
    },
    // Optional function for logging errors: errorLogger(errorMsg: string, trace:string)
    externalErrorLogger: {
      required: false
    },
    filesEnabled: {
      required: false,
      default: true
    },
    // Enable changing the username for the user (predefinedAliasName is in sync)
    optionsShowChangingAliasName: {
      type: Boolean,
      required: false,
      default: true
    },
    // Enables the user to change his status
    optionsShowChangingStatus: {
      type: Boolean,
      required: false,
      default: true
    },
    // current status (in sync if enabled in optionsShowChangingStatus)
    // Available, Idle, Busy, Offline
    currentStatusProp: {
      type: String,
      required: false
    },
    // predefined profile pic // not sync
    profilePic: {
      type: String,
      required: false
    },
    // if defined the chat will open as an embedded chat with the passed string as chatname
    embeddedChat: {
      type: String,
      required: false,
      default: null
    },
    //padding for embedded mode for preventing overlapping embedded container buttons
    embeddedIconPaddingRight: {
      type: String,
      default: "0px"
    },
    // used for showing settings in embedded mode if isAnonymVenue and hasOnlyEmbedded
    isAnonymVenue: {
      type: Boolean,
      default: false
    },
    hasOnlyEmbedded: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    ...expMixins.filters
  },
  methods: {
    ...expMixins.filters,
    ...expMixins.methods,
    checkTranslationObjectsAndAddMissing() {
      const objectRecursive = function (local, external) {
        for (const key of Object.keys(local)) {
          if (typeof local[key] === "string" && key in external && typeof external[key] === "string") {
            local[key] = external[key];
          } else if (
            local[key] &&
            typeof local[key] === "object" &&
            key in external &&
            typeof external[key] === "object"
          ) {
            local[key] = objectRecursive(local[key], external[key]);
          }
        }
        return local;
      };
      try {
        if (typeof this.trans === "object" && this.trans) {
          this.transLocal = objectRecursive(this.transLocal, this.trans);
        }
      } catch (error) {
        this.logErrors(error);
      }
    },
    getProfilePic(uuid: string) {
      if (!uuid) {
        return null;
      }
      if (this.usersData[uuid]) {
        if (this.usersData[uuid]["custom"] && this.usersData[uuid]["custom"]["profilePic"]) {
          return `url(${this.usersData[uuid]["custom"]["profilePic"]})`;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    uuidClicked(uuid: string) {
      this.consoleLog(`this.usersData[uuid]`, this.usersData[uuid]);
      if (this.usersData[uuid] && this.usersData[uuid]["custom"] && this.usersData[uuid]["custom"].uuid) {
        this.$emit("uuid-clicked", this.usersData[uuid]["custom"].uuid);
      }
    },

    async checkAndCloseProfileSettings() {
      if (!this.profileSettings.name || this.profileSettings.name === "") {
        alert(this.translations.errors.please_enter_a_name);
        return;
      }
      this.profileSettings.name = this.profileSettings.name.replace(/\r?\n|\r/g, "");
      this.profileSettings.name = this.profileSettings.name.replace(/\&nbsp;/g, "");
      this.profileSettings.name = this.profileSettings.name.trim();
      this.$emit("update:predefinedAliasName", this.profileSettings.name);
      this.$emit("update:currentStatusProp", this.profileSettings.status);
      this.pns.pubnub_saveProfileSettings();
      this.settingsOpen = false;
    },
    async update_previewUrls() {
      for (const key of Object.keys(this.messages)) {
        for (const msg of [...this.messages[key]].reverse()) {
          try {
            if (!msg.preview && msg.message && msg.message.file) {
              const fname = msg.message.file.name;
              if (
                fname.endsWith(".jpg") ||
                fname.endsWith(".jpeg") ||
                fname.endsWith(".png") ||
                fname.endsWith(".gif")
              ) {
                const res = await this.pns.pubnub_downloadFile(msg, true);
                msg.preview = res;
              }
            }
          } catch (error) {
            this.logErrors(error);
          }
        }
      }
    },
    setCaretToEnd(target /*: HTMLDivElement*/) {
      if (typeof target === "string") {
        target = this.$el.querySelector("#chat_input_" + target);
      }
      setTimeout(() => {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(target);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
        target.focus();
        range.detach(); // optimization

        // set scroll to the end if multiline
        target.scrollTop = target.scrollHeight;
      }, 200);
    },
    updateSearch(event: any) {
      this.mainWindowSearch = event.target.innerText;
    },
    async openVideoChat(chatWindow: Channel, fromMessage = false) {
      if (!this.lockSend) {
        this.lockSend = true;
        try {
          if (typeof this.otCreateSession === "undefined" || typeof this.otGetToken === "undefined" || !this.otApiKey) {
            alert(this.translations.errors.video_chat_is_not_enabled);
            this.lockSend = false;
            return;
          }

          // We need the same session token for all clients to connect to each-other
          if (fromMessage && !chatWindow.otSession) {
            await this.pns.pubnub_getChannelMetaData(chatWindow, true);
          }

          this.currentVideoChat = {
            otApiKey: this.otApiKey,
            display_name: chatWindow.display_name,
            type: chatWindow.type,
            otSession: chatWindow.otSession
          };

          // only send invitation when not already sent
          if (!fromMessage) {
            if (!chatWindow.otSession) {
              const token = await this.otCreateSession();
              chatWindow.otSession = token;
              this.currentVideoChat.otSession = token;
              this.showVideoChat = true;
            }
            await this.pns.pubnub_setChannelMetaData(chatWindow.subscription_name);
            const message = "otChatOpen_____invite";
            await this.pns.pubnub_pushMessage(chatWindow.subscription_name, message);
          }
          this.showVideoChat = true;
          this.lockSend = false;
        } catch (error) {
          this.lockSend = false;
          this.logErrors(error);
        }
      }
    },
    openLastIncoming(forceToggle = false, forceOpen = false, forceClose = false, fromUuid = false) {
      if (!this.lastIncomingChannels.length) {
        return;
      }
      this.openChat(this.lastIncomingChannels.shift().subscription_name, forceToggle, forceOpen, forceClose, fromUuid);
    },
    async openChat(key: string, forceToggle = false, forceOpen = false, forceClose = false, fromUuid = false) {
      // this.consoleLog('openChat')
      if (forceClose) {
        this.channels[key].isExpanded = false;
        this.channels[key].isOpen = false;
        this.forceRerenderComputedProps++;
        return;
      }

      if (this.fullScreen && this.mobileMode) {
        this.toggleRespMainWindowOpen();
      }

      if (!(key in this.channels)) {
        let uuid = null;
        if (fromUuid) {
          uuid = key;
          key = await this.digestMessage([this.uuid, key].sort().join("_"));
        }
        key = (await this.pns.setPrivateChannelAndSubscribe(key, false, uuid)).subscription_name;
        // this.consoleLog('openChat this.channels[key]', this.channels[key])
      }

      if (this.channels[key].type === "group" && !this.channels[key].group_participants) {
        try {
          await this.pns.pubnub_getChannelMembers(this.channels[key]);
        } catch (error) {
          console.error(error);
        }
      }

      //delete from
      if (this.lastIncomingChannels && this.lastIncomingChannels.length) {
        let lIndex = this.lastIncomingChannels.findIndex((el) => el.subscription_name == key);
        if (lIndex > -1) {
          this.lastIncomingChannels.splice(lIndex, 1);
        }
      }

      if (this.lastIncomingChannels.length) {
        let lastIncomingChannelsIndex = this.lastIncomingChannels.findIndex((el: Channel) => {
          return el.subscription_name == key;
        });
        if (lastIncomingChannelsIndex > -1) {
          this.lastIncomingChannels.splice(lastIncomingChannelsIndex, 1);
        }
      }

      this.channels[key].new = false;

      const searchOpenChatIndex = this.onlyOpenChannels.indexOf(key);

      if (searchOpenChatIndex < 0 || forceOpen) {
        forceOpen = true;
        let openToggled = false;
        const openStateBefore = this.channels[key].isOpen;
        if (!this.channels[key].expansion_touched || forceToggle) {
          this.channels[key].isOpen = forceOpen || !this.channels[key].isOpen;
          this.channels[key].isExpanded = forceOpen || this.channels[key].isOpen;
        }
        openToggled = openStateBefore !== this.channels[key].isOpen;

        // because flex-direction end we put the actual open windows at the end of the array, from newest opened window to latest
        if (this.channels[key].isOpen) {
          this.channels[key].openChatCount = this.openChatCount;
          this.openChatCount++;
        }

        if (
          this.channels[key].isOpen &&
          this.channels[key].isExpanded &&
          !(this.mobileMode && !this.mobileButtonChecked)
        ) {
          this.channelMessageCounts[key] = 0;

          this.forceRerenderComputedProps++;

          setTimeout(() => {
            //close all not open channels

            const openChans = this.onlyOpenChannels;
            for (const channelKey of Object.keys(this.channels)) {
              if (!openChans.includes(channelKey)) {
                this.channels[channelKey].isOpen = false;
                this.channels[channelKey].isExpanded = false;
              }
            }

            openChans.forEach((channel) => {
              this.scrollToBottom("#content_" + this.channels[channel].container_div_id, 50, openToggled);
            });
            this.scrollToBottom("#content_" + this.channels[key].container_div_id, 50, openToggled);
            const focusEl = this.$el.querySelector("#chat_input_" + this.channels[key].container_div_id);
            this.setCaretToEnd(focusEl);
            this.forceRerenderComputedProps++;
          }, 300);
        }
      }
      this.forceRerenderComputedProps++;
    },
    handleResize(withEmit: boolean = true) {
      const isMobileBreakpoint =
        (window.innerWidth <= 930 && this.sizes.windowWidth > 930) ||
        (window.innerHeight <= 550 && this.sizes.windowHeight > 550);
      const isNormalBreakpoint =
        (window.innerWidth >= 930 && this.sizes.windowWidth < 930) ||
        (window.innerHeight >= 550 && this.sizes.windowHeight < 550);

      if (isMobileBreakpoint || isNormalBreakpoint) {
        this.mobileButtonChecked = false;
      }

      this.sizes.windowWidth = window.innerWidth;
      this.sizes.windowHeight = window.innerHeight;

      this.mobileMode = this.sizes.windowWidth < 930 || this.sizes.windowHeight < 550;

      if (this.mobileMode && this.sizes.windowWidth > this.sizes.windowHeight && this.sizes.windowHeight < 270) {
        //remove padding in landscape
        this.isLandscapeMode = true;
      } else {
        this.isLandscapeMode = false;
      }

      this.sizes.amountChatWindowsPossible = Math.floor(this.sizes.windowWidth / 360) - 1;
      if (withEmit) {
        this.emitCurrentMainWindow();
      }
      this.forceRerenderComputedProps++;
    },
    hideAndMuteChat() {
      this.chatIsVisibleAndUnmutedData = true;
      this.$emit("update:chatIsVisibleAndUnmutedProp", false);
    },
    emitCurrentMainWindow() {
      if (this.mobileMode) {
        this.$emit(
          "update:mainWindowExpandedProp",
          this.fullScreen && this.respMainWindowOpen && this.mainWindowExpanded
        );
      } else {
        this.$emit("update:mainWindowExpandedProp", this.mainWindowExpanded);
      }
    },
    handleFullScreenWatcher() {
      this.fullScreen = this.isFullScreenProp;
      if (this.fullScreen) {
        this.mainWindowExpanded = true;
      } else {
        if (this.sizes.windowWidth < 930) {
          this.mobileButtonChecked = false;
        }
      }
      this.forceRerenderComputedProps++;
    },
    handleMainWindowExpandedProp() {
      if (this.mobileMode) {
        if (this.mainWindowExpandedProp) {
          this.fullScreen = this.mainWindowExpanded = this.mobileButtonChecked = true;
        }
        this.respMainWindowOpen = this.mainWindowExpandedProp;
      } else {
        if (this.fullScreen) {
          this.respMainWindowOpen = this.mainWindowExpandedProp;
        } else {
          this.mainWindowExpanded = this.mainWindowExpandedProp;
        }
      }
      this.forceRerenderComputedProps++;
    },
    toggleMobileButton(open: boolean = undefined) {
      if (typeof open === "undefined") {
        this.mobileButtonChecked = !this.mobileButtonChecked;
      } else {
        this.mobileButtonChecked = open;
      }
      if (this.mobileButtonChecked) {
        this.mainWindowExpanded = true;
        this.respMainWindowOpen = true;
        if (this.allMessageCount && this.lastIncomingChannels.length) {
          this.respMainWindowOpen = false;
          this.openLastIncoming(true, true, false);
        }
      }
      this.fullScreen = this.mobileButtonChecked;
      this.forceRerenderComputedProps++;
      this.emitCurrentMainWindow();
    },
    toggleRespMainWindowOpen() {
      this.respMainWindowOpen = !this.respMainWindowOpen;
      this.forceRerenderComputedProps++;
      this.emitCurrentMainWindow();
    },
    toggleMainWindowExpanded(open: boolean) {
      if (typeof open === "undefined") {
        this.mainWindowExpanded = !this.mainWindowExpanded;
      } else {
        this.mainWindowExpanded = open;
      }
      this.forceRerenderComputedProps++;
      this.emitCurrentMainWindow();
    },
    consoleTime(name: string) {
      if (process.env.NODE_ENV !== "production" && this.isEmbed) {
        console.time(name);
      }
    },
    consoleTimeEnd(name: string) {
      if (process.env.NODE_ENV !== "production" && this.isEmbed) {
        console.timeEnd(name);
      }
    }
  },

  async mounted() {
    if (this.embeddedChat && this.embeddedChat !== "") {
      //load embeddedchat
      this.isEmbed = true;
    }

    this.chatIsVisibleAndUnmutedData = this.chatIsVisibleAndUnmutedProp;
    this.consoleLog(`process.env.NODE_ENV`, process.env.NODE_ENV);

    this.consoleLog(`this.openChatByUuidProp`, this.openChatByUuidProp);

    this.pns = new PNService(this);
    this.baseChannel = btoa(this.subscribeKey);

    this.channels = {};
    this.messages = {};

    this.checkTranslationObjectsAndAddMissing();

    this.dataFilesEnabled = this.filesEnabled;

    if (
      typeof this.otCreateSession === "undefined" ||
      typeof this.otGetToken === "undefined" ||
      !this.otApiKey ||
      this.otApiKey === ""
    ) {
      this.videoChatButtonEnabled = false;
    }
    this.handleResize(false);

    if (!this.predefinedUuid || this.predefinedUuid === "") {
      this.uuid = "user_" + nanoid();
    } else {
      this.uuid = this.predefinedUuid.replace(/[^0-9a-zA-Z_]/g, (char) => char.charCodeAt(0));
    }

    if (this.saveUserInLocalStorage && (!this.predefinedUuid || this.predefinedUuid === "")) {
      if (localStorage.vvenue_uuid) {
        this.uuid = localStorage.vvenue_uuid;
      }
      localStorage.vvenue_uuid = this.uuid;
    }

    const originalUUID = this.uuid;
    this.uuid = await this.digestMessage(this.uuid);
    this.consoleLog("mounted this.uuid", this.uuid);

    if (typeof this.isFullScreenProp !== "undefined") {
      this.handleFullScreenWatcher();
    }
    if (typeof this.mainWindowExpandedProp !== "undefined") {
      this.handleMainWindowExpandedProp();
    }

    this.mainWindowExpanded = this.mainWindowInitiallyExpanded;
    if (this.soundUrl) {
      this.sound = new Audio(this.soundUrl);
    }

    this.pns.pubnub_getInstance();

    // need to set a channel membership to baseChannel to receive User Metadata Events - STRANGE!
    (this.pubnub as Pubnub).objects
      .setChannelMembers({
        channel: this.baseChannel,
        uuids: [this.uuid]
      })
      .catch((error) => this.consoleError("setChannelMembers error", error));

    this.pns.pubnub_setListener();

    //### get existing settings
    //also check if update of profile settings is needed:
    let isProfileSettingsUpdateNeeded = false;
    await this.pns.pubnub_setProfileSettings();

    // ### overwrite predefined settings
    if (this.profileSettings.name === "Guest" || this.predefinedAliasName) {
      this.profileSettings.name =
        this.predefinedAliasName ||
        `${this.translations.main.guest} ${this.surnames[Math.floor(Math.random() * 10000) % this.surnames.length]}`;
      isProfileSettingsUpdateNeeded = true;
    }

    if (this.profileSettings.uuid !== originalUUID) {
      this.profileSettings.uuid = originalUUID;
      isProfileSettingsUpdateNeeded = true;
    }

    if (this.profilePic && this.profileSettings["profilePic"] !== this.profilePic) {
      this.profileSettings["profilePic"] = this.profilePic;
      this.consoleLog("this.profileSettings.profilePic", this.profileSettings.profilePic);
      isProfileSettingsUpdateNeeded = true;
    }

    if (this.currentStatusProp && this.profileSettings["status"] !== this.currentStatusProp) {
      this.profileSettings["status"] = this.currentStatusProp;
    }

    if (isProfileSettingsUpdateNeeded) {
      this.pns.pubnub_saveProfileSettings();
    }
    this.pns.pubnub_updateAllUsersMetadata();

    if (!this.isEmbed) {
      // ### subscribe globalChannels channels ###
      const channelsToSubscribe = this.globalChannels.map((display_name) => {
        return { display_name, dynamic: false };
      });
      if (this.dynamicChannels && this.dynamicChannels.length) {
        for (const chan of this.dynamicChannels) {
          if (chan.url) {
            channelsToSubscribe.push({
              display_name: chan.channel,
              dynamic: true
            });
          } else if (typeof chan === "string") {
            channelsToSubscribe.push({ display_name: chan, dynamic: true });
          }
        }
      }

      for (const el of channelsToSubscribe) {
        //clean string according to rules here : https://www.pubnub.com/docs/platform/channels/overview
        // let cleanName = el.replace(/[^0-9a-zA-Z_\-\=\@\.\!\$\#\%\&\^\s;]/g, "_");
        //new cleaning because channel names for file uploads seem much stricter than the docs...
        const cleanName = el.display_name.replace(/[^0-9a-zA-Z_]/g, (char) => char.charCodeAt(0));
        const chatWindow = {
          //### ChatWindow ###
          isOpen: false,
          isExpanded: false,
          currentMessage: "",
          container_div_id: (Math.random() * 1000000).toFixed(0),

          //### ChannelToSubscribe ###
          display_name: el.display_name,
          subscription_name: cleanName,
          avatarBackground: this.primaryColor,
          //make colors for channelnames
          avatarBackgroundOpacity: this.hslColorFromArbitraryString(this.primaryColor, 50, 75),
          type: "public",
          mute: false,
          emojiExpanded: false,
          uploadExpanded: false,
          fileRecords: [],
          openChatCount: 0,
          dynamic: el.dynamic
          // relativePath: el.url
        } as Channel;
        this.channels[chatWindow.subscription_name] = chatWindow;
      }
      this.consoleLog(`this.uuid`, this.uuid);
      this.consoleLog("this.baseChannel", this.baseChannel);
      // separately subscribe to a baseChannel for events for reducing redundant signalling
      const cleanChannelNames = Object.keys(this.channels);
      this.pns.pubnub_subscribeToChannels(cleanChannelNames);
      this.pns.handleDynamicChannelOnUpdate();
      (this.pubnub as Pubnub).subscribe({
        channelGroups: [this.uuid]
      });
    } else {
      // EMBED
      const embeddedChannelClean = this.embeddedChat.replace(/[^0-9a-zA-Z_]/g, (char) => char.charCodeAt(0));
      const chatWindow = {
        //### ChatWindow ###
        isOpen: true,
        isExpanded: true,
        currentMessage: "",
        container_div_id: (Math.random() * 1000000).toFixed(0),

        //### ChannelToSubscribe ###
        display_name: this.embeddedChat,
        subscription_name: embeddedChannelClean,
        avatarBackground: this.primaryColor,
        //make colors for channelnames
        avatarBackgroundOpacity: this.hslColorFromArbitraryString(this.primaryColor, 50, 75),
        type: "public",
        mute: false,
        emojiExpanded: false,
        uploadExpanded: false,
        fileRecords: [],
        openChatCount: 0,
        dynamic: false
        // relativePath: el.url
      } as Channel;
      this.channels[chatWindow.subscription_name] = chatWindow;
      //subscribe and open embed channel

      (this.pubnub as Pubnub).subscribe({
        channels: [embeddedChannelClean]
      });
      this.pns.pubnub_fetchMessages([embeddedChannelClean]).then(async () => {
        this.forceRerenderComputedProps++;
        await this.update_previewUrls();
        this.scrollToBottom("#content_" + chatWindow.container_div_id, 800, true);
      });

      //disable fullscreen on start for embed
      this.mobileButtonChecked = false;
      this.fullScreen = false;
    }

    this.pns.pubnub_subscribePrivateChannelsInterval();
    this.pns.pubnub_subscribePrivateChannels().then(() => {
      if (!this.isEmbed && Object.keys(this.channels).length > 0) {
        this.pns.pubnub_setUnreadMessagesCount();
        this.pns.pubnub_fetchMessages(Object.keys(this.channels));
      }
    });

    this.pubnub
      .listFiles({
        channel: this.baseChannel,
        limit: 2
      })
      .then(() => {})
      .catch((err) => {
        this.logErrors(err);
        try {
          if (err.status.errorData.error.code === 3305) {
            this.dataFilesEnabled = false;
          }
        } catch (error) {}
      });
    //video debug
    // return
    if (this.debug.openVideoAtStartUp) {
      setTimeout(() => {
        const videoChannel = Object.values(this.channels).filter(
          (el: Channel) => el.display_name === "sweet video chat 2"
        );
        this.openVideoChat(videoChannel[0], true);
      }, 5000 + Math.floor(Math.random() * 1000));
    }
    this.forceRerenderComputedProps++;
  },
  computed: {
    allMessageCount() {
      return Object.values(this.channelMessageCounts).reduce((prev: number, curr: number) => prev + curr, 0);
    },
    onlyOpenChannels() {
      if (!this.chatIsOnline) {
        return [];
      }
      let openChannels = Object.keys(this.channels)
        .filter((key) => this.channels[key].isOpen)
        .sort((a, b) => {
          if (this.channels[a].openChatCount > this.channels[b].openChatCount) {
            return 1;
          } else if (this.channels[a].openChatCount < this.channels[b].openChatCount) {
            return -1;
          } else {
            return 0;
          }
        });

      if (!this.isEmbed) {
        openChannels = openChannels.slice(this.fullScreen ? -1 : -this.sizes.amountChatWindowsPossible);
      }

      if (this.fullScreen && openChannels.length) {
        this.channels[openChannels[0]].isExpanded = true;
      }

      return openChannels;
    },
    cssVars() {
      return {
        "--vvc-primary-color": this.primaryColor,
        "--vvc-secondary-color": this.secondaryColor
      };
    },
    activeUsersUUIDsFiltered() {
      if (!this.showActiveUserListProp) {
        return [];
      }
      return this.activeUsersUUIDs
        .filter((uuid) => {
          if (
            this.uuid === uuid ||
            typeof this.usersData[uuid] === "undefined" ||
            this.usersData[uuid]?.custom?.status === "Offline" ||
            !this.usersData[uuid]?.name
          ) {
            return false;
          }
          if (this.mainWindowSearch === "") {
            return true;
          } else {
            if (typeof this.usersData[uuid]?.name !== "undefined") {
              const wordExp = new RegExp(this.mainWindowSearch, "i");
              return wordExp.test(this.usersData[uuid].name);
            }
            return false;
          }
        })
        .map((uuid) => {
          return {
            display_name: this.usersData[uuid]?.name,
            profile_picture: this.getProfilePic(uuid),
            uuid
          };
        })
        .sort((a, b) => {
          return a.display_name.localeCompare(b.display_name);
        });
    },
    channelsComputed() {
      let channels = [
        //recent active, groups
        [
          ...Object.keys(this.channels).filter(
            (key) => this.channels[key].hasMembership && this.channels[key].type === "public"
          ),
          ...Object.keys(this.channels).filter(
            (key) => this.channels[key].hasMembership && ["private", "group"].includes(this.channels[key].type)
          )
        ],
        //public chats, groups
        Object.keys(this.channels).filter(
          (key) => this.channels[key].type === "public" || this.channels[key].type === "group"
        )
      ];

      channels = channels.map((el) =>
        el
          .filter((key) => this.channels[key].display_name)
          .sort((a, b) => {
            if (this.channels[a].type === "public" && this.channels[b].type !== "public") {
              return -1;
            } else if (this.channels[a].type !== "public" && this.channels[b].type === "public") {
              return 1;
            } else {
              return this.channels[a].display_name > this.channels[b].display_name
                ? 1
                : this.channels[a].display_name < this.channels[b].display_name
                ? -1
                : 0;
            }
          })
          .sort((a, b) => {
            let msg_count_a =
              this.messages[this.channels[a].subscription_name] &&
              this.messages[this.channels[a].subscription_name].length;
            let msg_count_b =
              this.messages[this.channels[b].subscription_name] &&
              this.messages[this.channels[b].subscription_name].length;

            if (!msg_count_a && !msg_count_b) return 0;
            if (msg_count_a && !msg_count_b) return -1;
            if (!msg_count_a && msg_count_b) return 1;

            let a_timetoken =
              this.messages[this.channels[a].subscription_name][
                this.messages[this.channels[a].subscription_name].length - 1
              ].timetoken;
            let b_timetoken =
              this.messages[this.channels[b].subscription_name][
                this.messages[this.channels[b].subscription_name].length - 1
              ].timetoken;

            if (a_timetoken > b_timetoken) return -1;
            if (a_timetoken < b_timetoken) return 1;
            return 0;
          })
      );

      if (this.mainWindowSearch === "") {
        return channels;
      } else {
        let wordExp = new RegExp(this.mainWindowSearch, "i");
        return channels.map((el) => el.filter((key) => wordExp.test(this.channels[key].display_name)));
      }
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("beforeunload", () => {
      try {
        if (this.setActiveUsersUUIDsArrayInterval_id) {
          clearInterval(this.setActiveUsersUUIDsArrayInterval_id);
        }
        this.pubnub.unsubscribeAll();
        this.pubnub.stop();
        this.pubnub.removeListener(this.pubnubListener);
      } catch (error) {
        this.errorLogger(error);
      }
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    try {
      if (this.setActiveUsersUUIDsArrayInterval_id) {
        clearInterval(this.setActiveUsersUUIDsArrayInterval_id);
      }
      this.pubnub.unsubscribeAll();
      this.pubnub.stop();
      this.pubnub.removeListener(this.pubnubListener);
    } catch (error) {
      this.errorLogger(error);
    }
  },
  watch: {
    fullScreen() {
      if (this.isEmbed) {
        this.$emit("update:isFullScreenProp", this.fullScreen);
      }
    },
    translations() {
      this.checkTranslationObjectsAndAddMissing();
    },
    async dynamicChannels() {
      this.pns.handleDynamicChannelOnUpdate();
    },
    isFullScreenProp() {
      this.handleFullScreenWatcher();
    },
    mainWindowExpandedProp: {
      handler() {
        //oldVal, newVal
        this.handleMainWindowExpandedProp();
      }
    },
    chatIsVisibleAndUnmutedProp: {
      handler() {
        this.chatIsVisibleAndUnmutedData = this.chatIsVisibleAndUnmutedProp;
      }
    },
    openChatByUuidProp: {
      async handler() {
        if (!this.openChatByUuidProp) {
          return;
        }
        let hash = this.openChatByUuidProp.replace(/[^0-9a-zA-Z_]/g, (char) => char.charCodeAt(0));
        hash = await this.digestMessage(hash);

        if (Object.keys(this.usersData).includes(hash)) {
          if (this.mobileMode) {
            this.openChat(hash, false, true, false, true);
            this.fullScreen = true;
            this.respMainWindowOpen = false;
            this.emitCurrentMainWindow();
          } else {
            this.openChat(hash, true, true, false, true);
          }
        }
      }
    },
    predefinedAliasName: {
      handler() {
        this.profileSettings.name = this.predefinedAliasName;
        this.pns.pubnub_saveProfileSettings();
      }
    },
    currentStatusProp: {
      handler() {
        this.profileSettings.status = this.currentStatusProp;
        this.pns.pubnub_saveProfileSettings();
      }
    },
    profilePic: {
      handler() {
        this.profileSettings.profilePic = this.profilePic;
        this.pns.pubnub_saveProfileSettings();
      }
    }
  }
};
</script>
<style lang="scss">
@import "./vvenue-chat.scss";
</style>
