import { Translations } from "./types";

export const translations: Translations = {
  main: {
    chats: "Chats",
    guest: "Gast",
    settings: {
      Available: "Available",
      Idle: "Idle",
      Busy: "Busy",
      Offline: "Offline",
      mute_all_sounds: "Mute all notifications"
    },
    recent_chats: "RECENT CHATS",
    group_chats: "GROUP CHATS",
    active_users: "ACTIVE USERS",
    new: "new",
    chat_is_offline: "Chat is offline",
    search: "Search",
    loading: "name loading..."
  },
  chat: {
    group_chatroom: "Group Chatroom",
    private_group_chat: "Private Group Chat",
    private_chat: "Private Chat",
    video_chat_invitation: "Click here to join my video chat!",
    group_channel: {
      channel_name: "Channel Name",
      enter_a_channel_name: "Enter a channel name",
      channel_users: "Channel Users",
      selected_users: "Selected Users",
      available_users: "Available Users",
      search_user: "Search User",
      update: "Update",
      create: "Create",
      group_channel: "Group Channel",
      delete: "Delete",
      are_you_sure_delete: "Are you sure to delete the Channel?",
      leave_channel: "Leave Channel",
      are_you_sure_leave: "Are you sure to leave the channel?"
    },
    write_message: "Write Message",
    emoji_search: "Search..",
    file_upload: {
      help_text: "Choose file or drag & drop here",
      max_size_error: "Files should not exceed 5MB in size",
      not_allowed_type_error: "Invalid file type.",
      num_files_attached: " file(s) attached"
    }
  },
  video_chat: {
    currently_no_other_users: "There are currently no other users.",
    // new 2021-11-11 start
    hardware_selection: "Camera and Microphone Settings",
    video_input: "Camera",
    audio_input: "Microphone"
    // new 2021-11-11 end
  },
  write_message: "Write Message",
  errors: {
    the_file_upload_failed: "We are sorry, the upload failed.",
    the_requested_file_is_not_available: "The requested file is not available anymore.",
    please_enter_a_name: "Please enter a name!",
    //could happen if old chat is in messages.
    video_chat_is_not_enabled: "Video Chat not enabled",
    please_select_at_least_two_users: "Please select at least two users!",
    please_enter_a_channel_name: "Please enter a channel name!",
    something_went_wrong_with_the_videochat: "We are sorry. Something went wrong with the video chat.",
    //video publisher new 2020-02-11
    unfortunately_you_cannot_publish: "Unfortunately you cannot publish.",
    video_microphone_access_denied:
      "You have denied access to your microphone/camera. Check your Browser- or Operating System Settings.",
    please_grant_access_to_video_microphone:
      "Please grant access to your microphone/camera. A dialog should be opened right now.",
    // new 2021-11-11 start
    your_hardware_is_occupied_by_another_program:
      "The selected voice or video devices are unavailable. Verify that the chosen devices are not in use by another application."
    // new 2021-11-11 end
  }
};
