<script lang="ts">
import { defineComponent } from "vue";

export const SvgComponent = defineComponent({
  name: "SvgComponent",
  template: ``
});
export const SendButton = defineComponent({
  name: "send-button",
  template: `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.4 17.4L18.85 9.91999C19.66 9.56999 19.66 8.42999 18.85 8.07999L1.4 0.599986C0.74 0.309986 0.00999999 0.799987 0.00999999 1.50999L0 6.11999C0 6.61999 0.37 7.04999 0.87 7.10999L15 8.99999L0.87 10.88C0.37 10.95 0 11.38 0 11.88L0.00999999 16.49C0.00999999 17.2 0.74 17.69 1.4 17.4Z" fill="#22AABB"/>
    </svg>
`
});
export const SmileyButton = defineComponent({
  name: "smiley-button",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z" fill="#22AABB"/>
<path d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z" fill="#22AABB"/>
<path d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z" fill="#22AABB"/>
<path d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z" fill="#22AABB"/>
<path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20.5C7.58 20.5 3.5 16.42 3.5 12C3.5 7.58 7.58 3.5 12 3.5C16.42 3.5 20.5 7.58 20.5 12C20.5 16.42 16.42 20.5 12 20.5ZM12 17.5C13.9035 17.5 15.5801 16.5322 16.5663 15.0602C16.887 14.5814 16.4979 14 15.9217 14C15.6297 14 15.365 14.1595 15.1902 14.3934C14.4641 15.365 13.3104 16 12 16C10.6881 16 9.54129 15.3636 8.81013 14.3903C8.63553 14.1578 8.3715 14 8.08079 14C7.50335 14 7.11345 14.5826 7.43505 15.0622C8.42143 16.5331 10.0974 17.5 12 17.5Z" fill="#22AABB"/>
</svg>
`
});
export const AttachButton = defineComponent({
  name: "attach-button",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 6.74999V17.33C16.5 19.42 14.97 21.28 12.89 21.48C10.5 21.71 8.5 19.84 8.5 17.5V5.13999C8.5 3.82999 9.44 2.63999 10.74 2.50999C12.24 2.35999 13.5 3.52999 13.5 4.99999V15.5C13.5 16.05 13.05 16.5 12.5 16.5C11.95 16.5 11.5 16.05 11.5 15.5V6.74999C11.5 6.33999 11.16 5.99999 10.75 5.99999C10.34 5.99999 10 6.33999 10 6.74999V15.36C10 16.67 10.94 17.86 12.24 17.99C13.74 18.14 15 16.97 15 15.5V5.16999C15 3.07999 13.47 1.21999 11.39 1.01999C9.01 0.789993 7 2.65999 7 4.99999V17.27C7 20.14 9.1 22.71 11.96 22.98C15.25 23.28 18 20.72 18 17.5V6.74999C18 6.33999 17.66 5.99999 17.25 5.99999C16.84 5.99999 16.5 6.33999 16.5 6.74999Z" fill="#22AABB"/>
</svg>
`
});
export const SearchButton = defineComponent({
  name: "search-button",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4999 14H14.7099L14.4299 13.73C15.6299 12.33 16.2499 10.42 15.9099 8.39002C15.4399 5.61002 13.1199 3.39002 10.3199 3.05002C6.08989 2.53002 2.52989 6.09001 3.04989 10.32C3.38989 13.12 5.60989 15.44 8.38989 15.91C10.4199 16.25 12.3299 15.63 13.7299 14.43L13.9999 14.71V15.5L18.2499 19.75C18.6599 20.16 19.3299 20.16 19.7399 19.75C20.1499 19.34 20.1499 18.67 19.7399 18.26L15.4999 14ZM9.49989 14C7.00989 14 4.99989 11.99 4.99989 9.50002C4.99989 7.01002 7.00989 5.00002 9.49989 5.00002C11.9899 5.00002 13.9999 7.01002 13.9999 9.50002C13.9999 11.99 11.9899 14 9.49989 14Z" fill="#22AABB"/>
</svg>
`
});

export const CloseButton = defineComponent({
  name: "close-button",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3002 5.71C17.9102 5.32 17.2802 5.32 16.8902 5.71L12.0002 10.59L7.11022 5.7C6.72022 5.31 6.09021 5.31 5.70021 5.7C5.31021 6.09 5.31021 6.72 5.70021 7.11L10.5902 12L5.70021 16.89C5.31021 17.28 5.31021 17.91 5.70021 18.3C6.09021 18.69 6.72022 18.69 7.11022 18.3L12.0002 13.41L16.8902 18.3C17.2802 18.69 17.9102 18.69 18.3002 18.3C18.6902 17.91 18.6902 17.28 18.3002 16.89L13.4102 12L18.3002 7.11C18.6802 6.73 18.6802 6.09 18.3002 5.71Z" fill="white"/>
</svg>
`
});
export const AddUserButton = defineComponent({
  name: "add-user-button",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C12.79 4 11 5.79 11 8C11 10.21 12.79 12 15 12ZM6 10V8C6 7.45 5.55 7 5 7C4.45 7 4 7.45 4 8V10H2C1.45 10 1 10.45 1 11C1 11.55 1.45 12 2 12H4V14C4 14.55 4.45 15 5 15C5.55 15 6 14.55 6 14V12H8C8.55 12 9 11.55 9 11C9 10.45 8.55 10 8 10H6ZM15 14C12.33 14 7 15.34 7 18V19C7 19.55 7.45 20 8 20H22C22.55 20 23 19.55 23 19V18C23 15.34 17.67 14 15 14Z" fill="white"/>
</svg>

`
});
export const MuteButton = defineComponent({
  name: "mute-button",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.63 3.63C3.24 4.02 3.24 4.65 3.63 5.04L7.29 8.7L7 9H4C3.45 9 3 9.45 3 10L3 14C3 14.55 3.45 15 4 15H7L10.29 18.29C10.92 18.92 12 18.47 12 17.58L12 13.41L16.18 17.59C15.69 17.96 15.16 18.27 14.58 18.5C14.22 18.65 14 19.03 14 19.42C14 20.14 14.73 20.6 15.39 20.33C16.19 20 16.94 19.56 17.61 19.02L18.95 20.36C19.34 20.75 19.97 20.75 20.36 20.36C20.75 19.97 20.75 19.34 20.36 18.95L5.05 3.63C4.66 3.24 4.03 3.24 3.63 3.63ZM19 12C19 12.82 18.85 13.61 18.59 14.34L20.12 15.87C20.68 14.7 21 13.39 21 12C21 8.17 18.6 4.89 15.22 3.6C14.63 3.37 14 3.83 14 4.46L14 4.65C14 5.03 14.25 5.36 14.61 5.5C17.18 6.54 19 9.06 19 12ZM10.29 5.71L10.12 5.88L12 7.76L12 6.41C12 5.52 10.92 5.08 10.29 5.71ZM16.5 12C16.5 10.23 15.48 8.71 14 7.97L14 9.76L16.48 12.24C16.49 12.16 16.5 12.08 16.5 12Z" fill="white"/>
</svg>`
});
export const SpeakerButton = defineComponent({
  name: "speaker-button",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 10.0001V14.0001C3 14.5501 3.45 15.0001 4 15.0001H7L10.29 18.2901C10.92 18.9201 12 18.4701 12 17.5801V6.41008C12 5.52008 10.92 5.07008 10.29 5.70008L7 9.00008H4C3.45 9.00008 3 9.45008 3 10.0001ZM16.5 12.0001C16.5 10.2301 15.48 8.71008 14 7.97008V16.0201C15.48 15.2901 16.5 13.7701 16.5 12.0001ZM14 4.45008V4.65009C14 5.03009 14.25 5.36009 14.6 5.50009C17.18 6.53009 19 9.06008 19 12.0001C19 14.9401 17.18 17.4701 14.6 18.5001C14.24 18.6401 14 18.9701 14 19.3501V19.5501C14 20.1801 14.63 20.6201 15.21 20.4001C18.6 19.1101 21 15.8401 21 12.0001C21 8.16008 18.6 4.89008 15.21 3.60009C14.63 3.37009 14 3.82008 14 4.45008Z" fill="white"/>
</svg>
`
});
export const MinimizeButton = defineComponent({
  name: "minimize-button",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 11L17 11C17.55 11 18 11.45 18 12C18 12.55 17.55 13 17 13L7 13C6.45 13 6 12.55 6 12C6 11.45 6.45 11 7 11Z" fill="white"/>
</svg>
`
});
export const AvatarButton = defineComponent({
  name: "avatar-button",
  template: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z" fill="white"/>
</svg>
`
});
export const EditButton = defineComponent({
  name: "edit-button",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 17.4601V20.5001C3 20.7801 3.22 21.0001 3.5 21.0001H6.54C6.67 21.0001 6.8 20.9501 6.89 20.8501L17.81 9.94006L14.06 6.19006L3.15 17.1001C3.05 17.2001 3 17.3201 3 17.4601ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z" fill="white"/>
</svg>
`
});
export const NotificationButton = defineComponent({
  name: "notification-button",
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0001 22C13.1001 22 14.0001 21.1 14.0001 20H10.0001C10.0001 21.1 10.8901 22 12.0001 22ZM18.0001 16V11C18.0001 7.93 16.3601 5.36 13.5001 4.68V4C13.5001 3.17 12.8301 2.5 12.0001 2.5C11.1701 2.5 10.5001 3.17 10.5001 4V4.68C7.63005 5.36 6.00005 7.92 6.00005 11V16L4.71005 17.29C4.08005 17.92 4.52005 19 5.41005 19H18.5801C19.4701 19 19.9201 17.92 19.2901 17.29L18.0001 16Z" fill="white"/>
</svg>

`
});
export const SettingsButton = defineComponent({
  name: "settings-button",
  template: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.43 10.98C17.47 10.66 17.5 10.34 17.5 10C17.5 9.66 17.47 9.34 17.43 9.02L19.54 7.37C19.73 7.22 19.78 6.95 19.66 6.73L17.66 3.27C17.54 3.05 17.27 2.97 17.05 3.05L14.56 4.05C14.04 3.65 13.48 3.32 12.87 3.07L12.49 0.42C12.46 0.18 12.25 0 12 0H7.99996C7.74996 0 7.53996 0.18 7.50996 0.42L7.12996 3.07C6.51996 3.32 5.95996 3.66 5.43996 4.05L2.94996 3.05C2.71996 2.96 2.45996 3.05 2.33996 3.27L0.339961 6.73C0.209961 6.95 0.269962 7.22 0.459962 7.37L2.56996 9.02C2.52996 9.34 2.49996 9.67 2.49996 10C2.49996 10.33 2.52996 10.66 2.56996 10.98L0.459962 12.63C0.269962 12.78 0.219961 13.05 0.339961 13.27L2.33996 16.73C2.45996 16.95 2.72996 17.03 2.94996 16.95L5.43996 15.95C5.95996 16.35 6.51996 16.68 7.12996 16.93L7.50996 19.58C7.53996 19.82 7.74996 20 7.99996 20H12C12.25 20 12.46 19.82 12.49 19.58L12.87 16.93C13.48 16.68 14.04 16.34 14.56 15.95L17.05 16.95C17.28 17.04 17.54 16.95 17.66 16.73L19.66 13.27C19.78 13.05 19.73 12.78 19.54 12.63L17.43 10.98ZM9.99996 13.5C8.06996 13.5 6.49996 11.93 6.49996 10C6.49996 8.07 8.06996 6.5 9.99996 6.5C11.93 6.5 13.5 8.07 13.5 10C13.5 11.93 11.93 13.5 9.99996 13.5Z" fill="white"/>
</svg>

`
});
export const ChevronRight = defineComponent({
  name: "chevron-right",
  template: `<svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7  c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8  L298.3,256z"/></svg>
`
});
export const MessagesButton = defineComponent({
  name: "messages-button",
  template: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 261.803 261.803" style="enable-background:new 0 0 261.803 261.803;" xml:space="preserve">
<path style="fill:#000002;" d="M249.303,78.176h-69.768V31.207c0-6.893-5.607-12.5-12.5-12.5H12.5c-6.893,0-12.5,5.607-12.5,12.5
	v102.178c0,6.893,5.607,12.5,12.5,12.5h9.585l-9.045,25.029c-1.594,4.411-0.458,7.433,0.776,9.19
	c1.546,2.203,4.102,3.519,6.838,3.519c2.022,0,4.073-0.699,6.096-2.078l50.082-34.146c1.038-0.707,3.652-1.514,4.907-1.514h0.527
	v46.97c0,6.893,5.607,12.5,12.5,12.5h85.294c1.231,0,3.891,0.82,4.907,1.513l50.082,34.147c2.291,1.563,4.351,2.081,6.073,2.081
	c2.613-0.001,4.455-1.191,5.166-1.742c1.179-0.915,4.843-4.408,2.472-10.971l-9.045-25.028h9.586c6.893,0,12.5-5.607,12.5-12.5
	V90.676C261.803,83.783,256.195,78.176,249.303,78.176z M68.384,135.005L33.33,158.906l4.795-13.27
	c1.288-3.562,0.856-7.274-1.184-10.179c-2.039-2.906-5.382-4.572-9.17-4.572H15V33.707h149.535v97.178H89.903
	c-0.046-0.001-0.09-0.007-0.136-0.007s-0.09,0.006-0.136,0.007h-7.891C77.504,130.885,71.887,132.617,68.384,135.005z
	 M246.803,190.354h-12.771c-3.788,0-7.131,1.667-9.171,4.572c-2.04,2.906-2.471,6.616-1.184,10.179l4.795,13.27l-35.056-23.901
	c-3.501-2.387-9.118-4.119-13.356-4.119H97.268v-44.47h69.767c6.893,0,12.5-5.607,12.5-12.5V93.176h67.268V190.354z"/>
</svg>
`
});
export const FullScreenButton = defineComponent({
  name: "full-screen-button",
  template: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 16L3 16C2.45 16 2 15.55 2 15L2 3C2 2.45 2.45 2 3 2L8 2C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0L2 0C0.89 0 0 0.9 0 2L0 16C0 17.1 0.9 18 2 18L16 18C17.1 18 18 17.1 18 16L18 10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10L16 15C16 15.55 15.55 16 15 16ZM11 1C11 1.55 11.45 2 12 2L14.59 2L5.46 11.13C5.07 11.52 5.07 12.15 5.46 12.54C5.85 12.93 6.48 12.93 6.87 12.54L16 3.41L16 6C16 6.55 16.45 7 17 7C17.55 7 18 6.55 18 6L18 1C18 0.45 17.55 0 17 0L12 0C11.45 0 11 0.45 11 1Z" fill="white"/>
</svg>
`
});
export const VideoButton = defineComponent({
  name: "video-button",
  template: `<svg width="18" height="23" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488.3 488.3"><path d="M488.3 142.5v203.1c0 15.7-17 25.5-30.6 17.7l-84.6-48.8v13.9c0 41.8-33.9 75.7-75.7 75.7H75.7C33.9 404.1 0 370.2 0 328.4V159.9c0-41.8 33.9-75.7 75.7-75.7h221.8c41.8 0 75.7 33.9 75.7 75.7v13.9l84.6-48.8c13.5-8 30.5 1.9 30.5 17.5z"/></svg>
`
});
export const NoVideoButton = defineComponent({
  name: "no-video-button",
  template: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520"  xmlns:v="https://vecta.io/nano"><path d="M505.29 157.622c-9.005-5.568-20.585-6.075-30.037-1.342L397 195.244v-42.185c0-16.862-13.256-30.136-30.118-30.136H183.734l-68.365-80.99c-10.883-12.866-30.131-14.591-43.027-3.685C59.476 49.14 57.866 68.36 68.764 81.233l335.867 396.909c6.038 7.134 14.641 10.797 23.318 10.797 6.962 0 13.97-2.377 19.71-7.23 12.866-10.891 14.276-30.164 3.378-43.038L397 375.045v-19.903l78.136 38.964c4.309 2.154 9.098 3.22 13.764 3.22 5.576 0 11.435-1.528 16.34-4.562 8.99-5.561 14.76-15.386 14.76-25.971v-183.2c0-10.586-5.72-20.41-14.71-25.971zM0 153.059v244.267c0 16.862 14.138 30.597 31 30.597h260.756L29.879 122.647C13.443 123.128 0 136.499 0 153.059z"/></svg>`
});
export const MicrophoneButton = defineComponent({
  name: "microphone-button",
  template: `<svg xmlns="http://www.w3.org/2000/svg" width="475.085" height="475.085"  xmlns:v="https://vecta.io/nano" viewBox="0 0 475.085 475.085"><path d="M237.541 328.897c25.128 0 46.632-8.946 64.523-26.83s26.833-39.399 26.833-64.525V91.365c0-25.126-8.938-46.632-26.833-64.525S262.669 0 237.541 0s-46.632 8.951-64.524 26.84-26.838 39.399-26.838 64.525v146.177c0 25.125 8.949 46.641 26.838 64.525s39.399 26.83 64.524 26.83zM396.563 188.15c-3.606-3.617-7.898-5.426-12.847-5.426s-9.226 1.809-12.847 5.426c-3.613 3.616-5.421 7.898-5.421 12.845v36.547c0 35.214-12.518 65.333-37.548 90.362-25.022 25.03-55.145 37.545-90.36 37.545s-65.334-12.515-90.365-37.545c-25.028-25.022-37.541-55.147-37.541-90.362v-36.547c0-4.947-1.809-9.229-5.424-12.845s-7.895-5.426-12.847-5.426-9.235 1.809-12.85 5.426-5.426 7.898-5.426 12.845v36.547c0 42.065 14.04 78.659 42.112 109.776s62.762 48.961 104.068 53.526v37.691h-73.089c-4.949 0-9.231 1.811-12.847 5.428s-5.426 7.898-5.426 12.847c0 4.941 1.809 9.233 5.426 12.847s7.898 5.428 12.847 5.428h182.719c4.948 0 9.236-1.813 12.847-5.428 3.621-3.613 5.431-7.905 5.431-12.847 0-4.948-1.81-9.232-5.431-12.847-3.61-3.617-7.898-5.428-12.847-5.428h-73.08v-37.691c41.299-4.565 75.985-22.408 104.061-53.526s42.12-67.711 42.12-109.776v-36.547c0-4.946-1.813-9.225-5.435-12.845z"/></svg>`
});
export const MicrophoneMuteButton = defineComponent({
  name: "microphone-mutet-button",
  template: `<svg xmlns="http://www.w3.org/2000/svg" width="565.512" height="565.512"  xmlns:v="https://vecta.io/nano" viewBox="0 0 565.512 565.512"><path d="M86.999 239.255v43.507c0 37.986 9.639 72.216 28.874 102.707l31.788-31.787c-11.432-21.311-17.161-44.945-17.161-70.914v-43.507c0-5.881-2.16-10.979-6.457-15.294-4.309-4.302-9.406-6.45-15.294-6.45-5.894 0-10.991 2.148-15.294 6.45-4.309 4.309-6.456 9.407-6.456 15.288zm304.513-130.497c0-29.908-10.655-55.508-31.946-76.812C338.262 10.648 312.661 0 282.753 0s-55.515 10.648-76.806 31.94C184.649 53.25 174 78.844 174 108.752v174.01c0 13.537 2.295 26.138 6.659 37.914L391.505 109.83v-1.071h.007zm-31.947 250.816c21.291-21.291 31.946-46.904 31.946-76.812v-43.103L245.923 385.242c11.469 4.1 23.709 6.266 36.83 6.266 29.908.006 55.509-10.629 76.812-31.934zm97.198-142.063c-5.894 0-10.991 2.148-15.294 6.451-4.309 4.314-6.456 9.412-6.456 15.293v43.507c0 41.921-14.902 77.779-44.688 107.572-29.798 29.791-65.655 44.682-107.571 44.682-25.576 0-48.88-5.576-69.939-16.664l-31.861 31.859c23.85 14.67 50.521 23.68 80.05 26.941v44.871H174c-5.894 0-10.992 2.137-15.294 6.451-4.309 4.303-6.457 9.4-6.457 15.293 0 5.881 2.148 10.98 6.457 15.295 4.302 4.301 9.4 6.449 15.294 6.449h217.511c5.887 0 10.985-2.148 15.294-6.449 4.302-4.314 6.456-9.414 6.456-15.295 0-5.893-2.154-10.99-6.456-15.293-4.309-4.314-9.407-6.451-15.294-6.451h-87.009v-44.871c49.162-5.43 90.454-26.672 123.882-63.723 33.415-37.037 50.129-80.594 50.129-130.673v-43.507c0-5.881-2.154-10.979-6.457-15.294-4.308-4.296-9.406-6.444-15.293-6.444zm21.616-172.908L43.86 479.123c-11.953 11.945-11.953 31.322 0 43.268 5.967 5.979 13.807 8.967 21.634 8.967s15.667-2.988 21.634-8.967l434.525-434.52c11.952-11.946 11.952-31.322 0-43.268-11.946-11.953-31.334-11.953-43.274 0z"/></svg>`
});
export const HangUpButton = defineComponent({
  name: "hang-up-button",
  template: `<svg xmlns="http://www.w3.org/2000/svg" width="435.933" height="435.933"  xmlns:v="https://vecta.io/nano" viewBox="0 0 435.933 435.933"><path d="M286.482 279.088a3.48 3.48 0 0 1-.023 4.225l-65.723 85.483c-.658.867-1.682 1.368-2.762 1.368s-2.102-.501-2.764-1.368l-65.727-85.483c-.476-.621-.722-1.368-.722-2.12 0-.741.236-1.485.705-2.104a3.47 3.47 0 0 1 4.069-1.137l43.516 17.176V194.393c0-1.926 1.556-3.48 3.486-3.48h34.877c1.92 0 3.485 1.554 3.485 3.48v100.74l43.516-17.177c1.458-.586 3.125-.115 4.067 1.132zM196.93 65.927C-28.392 70.252-1.475 181.812 3.69 198.193c5.532 27.833 26.852 41.76 48.488 33.864l41.166-12.881c22.179-8.096 36.25-38.272 31.408-67.418l-.917-5.518c74.849-21.183 139.407-15.746 189.768-1.727l-.396 2.353c-4.819 29.149 9.231 59.333 31.423 67.423l41.164 17.16c18.604 6.791 36.965-6.287 45.271-27.171a6.59 6.59 0 0 1 .173.15s1.139-2.812 2.288-7.625c.093-.375.163-.786.262-1.155l.575-2.785.353-1.658-.041-.035c6.017-35.513-.204-129.777-237.745-125.243z"/></svg>`
});
export const CameraSwitchButton = defineComponent({
  name: "camera-switch-button",
  template: `<svg xmlns="http://www.w3.org/2000/svg" height="512" width="512"  xmlns:v="https://vecta.io/nano" viewBox="0 0 512 512"><path d="M452 213.316V61c0-8.291-6.709-15-15-15h-84.789l-16.816-16.816C326.898 20.687 315.59 16 303.578 16h-95.156c-12.012 0-23.32 4.688-31.816 13.184L159.789 46H120V31c0-8.291-6.709-15-15-15s-15 6.709-15 15v15H75c-8.291 0-15 6.709-15 15v152.316C21.374 237.411 0 268.244 0 301c0 61.108 72.834 112.066 181 129.115V481c0 5.537 3.047 10.62 7.925 13.228a15 15 0 0 0 15.396-.747l90-60c4.175-2.783 6.68-7.471 6.68-12.48s-2.505-9.697-6.68-12.48l-90-60c-4.614-3.062-10.547-3.354-15.396-.747C184.047 350.38 181 355.463 181 361v38.419C93.298 384.393 30 344.487 30 301c0-17.734 10.485-35.425 30-51.244V301c0 8.291 6.709 15 15 15h362c8.291 0 15-6.709 15-15v-51.244c19.515 15.818 30 33.51 30 51.244 0 41.06-56.444 79.863-138.974 96.577-8.13 1.655-13.374 9.565-11.733 17.681 1.67 8.201 9.69 13.38 17.681 11.733C447.396 407.055 512 357.601 512 301c0-32.756-21.374-63.589-60-87.684zM256 256c-41.353 0-75-33.647-75-75s33.647-75 75-75 75 33.647 75 75-33.647 75-75 75zm120-120c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15z"/><circle cx="256" cy="181" r="45"/></svg>`
});

export default SvgComponent;
</script>
