import Pubnub, { DownloadFileParameters, MessageEvent, ObjectCustom, ObjectsEvent, SetUUIDMetadataEvent } from "pubnub";
import { saveAs } from "file-saver";
import { Channel, ChatMessage, UserData } from "./types";
import VvenueChat from "./vvenue-chat.vue";

export class PNService {
  protected vue_el: InstanceType<typeof VvenueChat>;

  constructor(vue_el) {
    this.vue_el = vue_el;
  }

  // ### PubNub Functions ###
  async pubnub_pushMessage(key: string, message: string) {
    this.vue_el.consoleLog(`pubnub_pushMessage message`, message);
    this.vue_el.consoleLog(`pubnub_pushMessage channel`, this.vue_el.channels[key]);
    try {
      if (typeof this.vue_el.messages[key] === "undefined" || !this.vue_el.messages[key].length) {
        this.pubnub_setChannelMetaData(key);
      }
      let status: Pubnub.PubnubStatus = await new Promise((resolve) => {
        let publishPayload: Pubnub.PublishParameters = {
          channel: key,
          message: {
            description: message
          }
        };
        this.vue_el.pubnub.publish(publishPayload, (status) => resolve(status));
      });
      this.vue_el.consoleLog("pubnub_pushMessage status", status, key);
      if (status.error) {
        throw new Error("Message could not be sent.");
      }
    } catch (error) {
      this.vue_el.logErrors(error);
    }
  }
  async pubnub_sendFiles(key: string) {
    for (const fileRecord of this.vue_el.channels[key].fileRecords) {
      try {
        const result = await this.vue_el.pubnub.sendFile({
          channel: this.vue_el.channels[key].subscription_name,
          file: fileRecord.file
        });
        result["channel"] = this.vue_el.channels[key].subscription_name;
      } catch (error) {
        alert(this.vue_el.translations.errors.the_file_upload_failed);
        this.vue_el.logErrors(error);
      }
    }
  }
  async pubnub_downloadFile(message: ChatMessage, preview = false): Promise<string | null> {
    if (!this.vue_el.filesEnabled) {
      this.vue_el.logErrors("Files not enabled");
      return null;
    }
    if (!message?.message?.file?.id) {
      this.vue_el.logErrors("No file or file id found");
      return null;
    }
    try {
      const downloadParams: DownloadFileParameters = {
        channel: message.channel,
        id: message.message.file.id,
        name: message.message.file.name
      };
      const file = await this.vue_el.pubnub.downloadFile(downloadParams);

      const url = URL.createObjectURL(await file.toFile());

      if (!preview) {
        saveAs(url);
      }

      return url;
    } catch (error) {
      this.vue_el.logErrors(error);
      if (!preview) {
        alert(this.vue_el.translations.errors.the_requested_file_is_not_available);
      }
    }
    return null;
  }
  pubnub_getInstance() {
    this.vue_el.pubnub = new Pubnub({
      publishKey: this.vue_el.publishKey,
      subscribeKey: this.vue_el.subscribeKey,
      uuid: this.vue_el.uuid,
      // uuid: this.vue_el.uuid,
      autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
      restore: true // enable catchup on missed messages
      // logVerbosity: true,
    });
  }
  pubnub_saveProfileSettings() {
    //https://www.pubnub.com/docs/platform/connections/users-metadata
    this.vue_el.consoleLog("pubnub_saveProfileSettings", this.vue_el.profileSettings);

    return this.vue_el.pubnub.objects.setUUIDMetadata({
      data: {
        name: this.vue_el.profileSettings.name,
        custom: this.vue_el.profileSettings
      }
    });
  }
  async pubnub_setProfileSettings() {
    try {
      const response: Pubnub.GetUUIDMetadataResponse<Pubnub.ObjectCustom> = await new Promise((res, rej) =>
        this.vue_el.pubnub.objects.getUUIDMetadata(
          {
            uuid: this.vue_el.uuid
          },
          (status, result) => {
            if (status.error) {
              return rej(status);
            }
            return res(result);
          }
        )
      );
      if (response.data?.custom) {
        this.vue_el.profileSettings = { ...this.vue_el.profileSettings, ...response.data.custom };
      }
    } catch (error) {
      this.vue_el.logErrors(error);
    }
  }
  increaseCounter(msg: ChatMessage) {
    if (msg.publisher !== this.vue_el.uuid) {
      const chan = this.vue_el.channels[msg.channel];
      if (typeof chan !== "undefined") {
        // && this.vue_el.mainWindowExpanded && !this.vue_el.fullScreen
        // if (!chan.isOpen && !chan.isExpanded && !chan.expansion_touched) {
        //   this.vue_el.openChat(chan, false, true,false);
        // }
        if (chan.isOpen && chan.isExpanded && !(this.vue_el.mobileMode && !this.vue_el.mobileButtonChecked)) {
          const now = Date.now();
          this.pubnub_setChannelMembership(chan, Number.parseInt(`${now}9999`, 10));
        } else {
          if (typeof this.vue_el.channelMessageCounts[msg.channel] === "undefined") {
            this.vue_el.channelMessageCounts[msg.channel] = 1;
          } else {
            this.vue_el.channelMessageCounts[msg.channel]++;
          }
          this.vue_el.forceRerenderComputedProps++;
        }
      }
    }
  }
  async pubnub_setListener() {
    this.vue_el.pubnubListener = {
      status: (status: Pubnub.StatusEvent) => {
        if (status.category === "PNNetworkUpCategory" || status.category === "PNConnectedCategory") {
          this.vue_el.chatIsOnline = true;
          this.vue_el.forceRerenderComputedProps++;
        }
        if (status.category === "PNNetworkDownCategory") {
          this.vue_el.chatIsOnline = false;
          this.vue_el.forceRerenderComputedProps++;
        }
      },
      file: async (event: Pubnub.FileEvent) => {
        this.vue_el.consoleLog(`####### file #######`, event);

        const message: ChatMessage = {
          channel: event.channel,
          subscription: event.subscription,
          publisher: event.publisher,
          message: { ...event.message },
          timetoken: event.timetoken,
          timetokenInt: parseInt(event.timetoken, 10)
        };

        if (
          event.file.name.endsWith(".jpg") ||
          event.file.name.endsWith(".jpeg") ||
          event.file.name.endsWith(".png") ||
          event.file.name.endsWith(".gif")
        ) {
          message.preview = event.file.url;
        }

        message.message.file = event.file;

        this.vue_el.pns.setMessageMetaData(message);

        await this.pushMessageToMessages(message);

        this.increaseCounter(message);
      },
      message: async (event: MessageEvent) => {
        this.vue_el.consoleLog(`####### msg #######`, event);

        const message: ChatMessage = {
          channel: event.channel,
          subscription: event.subscription,
          publisher: event.publisher,
          message: { ...event.message },
          timetoken: event.timetoken,
          timetokenInt: parseInt(event.timetoken, 10)
        };

        this.vue_el.pns.setMessageMetaData(message);

        await this.pushMessageToMessages(message);
        this.increaseCounter(message);
      },
      objects: async (obj: ObjectsEvent) => {
        // delete channel member in real time from groups.
        // Activate Presence -> Objects
        this.vue_el.consoleLog(`####### obj #######`, obj);

        if (obj.message.type === "uuid") {
          const message = (obj as SetUUIDMetadataEvent<ObjectCustom>).message;
          const uuid = message.data.id;
          const displayName = message.data.custom.name as string;

          if (message.data?.custom?.profilePic) {
            message.data.custom.profilePic = (message.data.custom.profilePic as string).replace("'", "");
          }

          if (this.vue_el.usersData[uuid]) {
            this.vue_el.usersData[uuid].custom = message.data["custom"];
            this.vue_el.usersData[uuid].uuid = uuid;
            this.vue_el.usersData[uuid]["display_name"] = displayName;
            this.vue_el.usersData[uuid].name = displayName;
          } else {
            this.vue_el.usersData[uuid] = {
              custom: message.data.custom,
              uuid,
              display_name: displayName,
              name: displayName
            };
          }
        }

        if (obj.channel.startsWith("group_") && obj.message.event === "delete") {
          const channel = obj.channel;
          const uuidToDelete = obj.message.data["uuid"]["id"];
          if (this.vue_el.channels[channel] && this.vue_el.channels[channel].group_participants) {
            const index = this.vue_el.channels[channel].group_participants.indexOf(uuidToDelete);
            if (index > -1) {
              this.vue_el.channels[channel].group_participants.splice(index, 1);
            }
          }
        }
        this.vue_el.forceRerenderComputedProps++;
      }
    } as Pubnub.ListenerParameters;

    this.vue_el.pubnub.addListener(this.vue_el.pubnubListener);
    this.vue_el.consoleLog("pubnub_setListener####");
  }
  async pubnub_subscribeToChannels(channelNames: string[], channelGroups: string[] = [this.vue_el.uuid]) {
    if (channelNames && channelNames.length) {
      const promises = [];
      const channelNameChunks = this.chunkArray(channelNames, 30);
      for (const chunk of channelNameChunks) {
        promises.push(
          ...channelGroups.map((uuid) => {
            return {
              channels: chunk,
              channelGroup: uuid
            };
          })
        );
      }
      this.vue_el.consoleLog(`pubnub_subscribeToChannels promises`, promises);
      await Promise.all(promises.map((el) => this.vue_el.pubnub.channelGroups.addChannels(el)));
    }
  }
  chunkArray(myArray, chunk_size) {
    const results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }

    return results;
  }
  async pubnub_unsubscribeFromChannels(channelNames, channelGroups = [this.vue_el.uuid]) {
    if (channelNames.length) {
      let promises = channelGroups.map((uuid) =>
        this.vue_el.pubnub.channelGroups.removeChannels({
          channels: channelNames,
          channelGroup: uuid
        })
      );
      await Promise.all(promises);
      // await this.vue_el.pubnub.channelGroups.addChannels({
      //   channels: channelNames,
      //   channelGroup: this.vue_el.uuid,
      // });
    }
    return;
  }
  async pushMessageToMessages(message: ChatMessage) {
    if (this.vue_el.onMessageOpenChatProp && !this.vue_el.chatIsVisibleAndUnmutedData) {
      this.vue_el.chatIsVisibleAndUnmutedData = true;
      this.vue_el.$emit("update:chatIsVisibleAndUnmutedProp", true);
    }

    const key = message.channel;
    if (!this.vue_el.messages[key]) {
      this.vue_el.messages[key] = [];
    }
    this.vue_el.messages[key].push(message);
    this.vue_el.messages[key].sort((a, b) => a.timetokenInt - b.timetokenInt);
    this.vue_el.forceRerenderComputedProps++;

    if (!(key in this.vue_el.channels)) {
      await this.setPrivateChannelAndSubscribe(key, true, message.publisher);
      this.vue_el.channels[key].hasMembership = true;
      this.pubnub_setChannelMembership(this.vue_el.channels[key], message.timetokenInt - 1000);
    }

    if (message.publisher === this.vue_el.uuid) {
      this.pubnub_setChannelMembership(this.vue_el.channels[key], message.timetokenInt + 1);
      this.vue_el.channels[key].hasMembership = true;
    } else {
      if (this.vue_el.lastIncomingChannels.findIndex((el: Channel) => el.subscription_name === key) === -1) {
        this.vue_el.lastIncomingChannels.push(this.vue_el.channels[key]);
      }
    }

    this.vue_el.forceRerenderComputedProps++;

    if ((this.vue_el.channels[key].isOpen && this.vue_el.channels[key].isExpanded) || this.vue_el.isEmbed) {
      this.vue_el.consoleLog("scroll after message");
      this.vue_el.scrollToBottom(
        "#content_" + this.vue_el.channels[key].container_div_id,
        message.message.file ? 500 : 50
        // true
      );
    }
    if (
      !this.vue_el.channels[key].mute &&
      message.publisher !== this.vue_el.uuid &&
      !this.vue_el.profileSettings.muteAllSounds &&
      this.vue_el.chatIsVisibleAndUnmutedData
    ) {
      this.vue_el.playSound();
    }
  }
  async pubnub_fetchMessages(channels: string[], end: number = null) {
    const start = end ? end.toString() : null;

    try {
      const response: Pubnub.FetchMessagesResponse = await new Promise((resolve, reject) => {
        this.vue_el.pubnub.fetchMessages(
          {
            channels,
            start,
            count: 25 //max
          },
          (status, responseData) => (status.error ? reject(status) : resolve(responseData))
        );
      });
      if (!response.channels) {
        return;
      }
      console.log("Messages loaded", response);
      for (const channelKey in response.channels) {
        const channel = this.vue_el.messages[channelKey];
        if (typeof channel === "undefined") {
          this.vue_el.messages[channelKey] = [];
        } else {
          const messages: ChatMessage[] = response.channels[channelKey].map((m) => {
            const uuid = m.uuid || m.message.uuid;

            return {
              ...m,
              timetoken: m.timetoken as string,
              timetokenInt: parseInt(m.timetoken as string, 10),
              uuid,
              avatarColor: this.vue_el.hslColorFromArbitraryString(uuid, 50, 75),
              name: this.getUsersName(uuid)
            };
          });
          channel.unshift(...messages);
          channel.sort((a, b) => a.timetokenInt - b.timetokenInt).map(() => this.setMessageMetaData);
        }
      }
      this.vue_el.forceRerenderComputedProps++;
    } catch (error) {
      this.vue_el.logErrors(error);
    }
  }

  getUsersName(uuid: string) {
    if (this.vue_el.usersData.hasOwnProperty(uuid) && this.vue_el.usersData[uuid].hasOwnProperty("name")) {
      return (this.vue_el.usersData[uuid] as UserData).name;
    }
    return this.vue_el.translations.main.loading;
  }

  setMessageMetaData(msg: ChatMessage) {
    const uuid = msg.publisher || msg.uuid || msg.message.uuid;
    msg.avatarColor = this.vue_el.hslColorFromArbitraryString(uuid, 50, 75);
    msg.name = this.getUsersName(msg.publisher);
  }

  async pubnub_subscribePrivateChannelsInterval() {
    // TODO: What is this trying to acheive? Fix it!
    if (!this.vue_el.setActiveUsersUUIDsArrayInterval_id) {
      this.vue_el.setActiveUsersUUIDsArrayInterval_id = window.setInterval(() => {
        if (this.vue_el.subscribePrivateChannelsInterval_queue.length < 3) {
          this.vue_el.subscribePrivateChannelsInterval_queue.push(() => this.pubnub_subscribePrivateChannels());
        }
      }, this.vue_el.intervalLengthInMs);
    }

    while (true) {
      if (this.vue_el.subscribePrivateChannelsInterval_queue.length && !this.vue_el.intervalIsUpdatingChats) {
        this.vue_el.consoleLog("this_el.subscribePrivateChannelsInterval_queue ### START");
        await this.vue_el.subscribePrivateChannelsInterval_queue.pop()();
        this.vue_el.consoleLog("this_el.subscribePrivateChannelsInterval_queue ### END");
        await new Promise((res) =>
          setTimeout(() => {
            res(true);
          }, 300)
        );
      } else {
        await new Promise((res) =>
          setTimeout(() => {
            res(true);
          }, 3000)
        );
      }
    }
  }

  async pubnub_subscribePrivateChannels() {
    if (!this.vue_el.chatIsOnline) {
      this.vue_el.consoleLog("Chat offline");
      return;
    }
    try {
      this.vue_el.intervalIsUpdatingChats = true;
      this.vue_el.consoleLog("### pubnub_subscribePrivateChannels START");
      this.vue_el.consoleTime("pubnub_subscribePrivateChannels");

      if (!this.vue_el.isEmbed) {
        await this.pubnub_setActiveUsersUUIDsArray();
        await this.pubnub_setChannelsWithMembership();
        await this.pubnub_subscribeToChannels(
          Object.values(this.vue_el.channels)
            .filter((el: Channel) => "group" === el.type || el.dynamic || el.hasMembership)
            .map((el: Channel) => el.subscription_name)
        );
      }

      await this.updateChannelNamesAndMessageNamesFromUsersData();

      this.vue_el.consoleLog("### pubnub_subscribePrivateChannels END");
      this.vue_el.consoleTimeEnd("pubnub_subscribePrivateChannels");

      this.vue_el.forceRerenderComputedProps++;
      if (!this.vue_el.isEmbed) {
        this.vue_el.consoleLog("Subscribing to base channel");
        this.vue_el.pubnub.subscribe({
          channels: [this.vue_el.baseChannel]
        });
      }

      this.vue_el.intervalIsUpdatingChats = false;
    } catch (error) {
      this.vue_el.intervalIsUpdatingChats = false;
      this.vue_el.logErrors(error);
    }
  }

  getChannelValues(): Channel[] {
    return Object.values(this.vue_el.channels);
  }

  getChannelKeys(): string[] {
    return Object.keys(this.vue_el.channels);
  }

  async updateChannelNamesAndMessageNamesFromUsersData() {
    this.vue_el.consoleLog("### updateChannelNamesAndMessageNamesFromUsersData isEmbed ###");
    for (const key of this.getChannelKeys()) {
      // private
      if (this.vue_el.channels[key].uuid && this.vue_el.channels[key].type === "private") {
        this.vue_el.channels[key].display_name = this.getUsersName(this.vue_el.channels[key].uuid);
      }
    }
    for (const key of Object.keys(this.vue_el.messages)) {
      for (const msg of this.vue_el.messages[key]) {
        this.setMessageMetaData(msg);
      }
    }
  }
  async pubnub_updateAllUsersMetadata() {
    try {
      this.vue_el.consoleLog("pubnub_updateAllUsersMetadata");
      let req: Pubnub.GetAllMetadataParameters = {
        include: {
          customFields: true
        }
      };
      while (req) {
        let metadata: Pubnub.GetAllUUIDMetadataResponse<Pubnub.ObjectCustom> = await new Promise((resolve, reject) => {
          this.vue_el.pubnub.objects.getAllUUIDMetadata(req, (status, response) =>
            status.error ? reject(status) : resolve(response)
          );
        });
        if (metadata.data) {
          metadata.data.forEach((meta) => {
            this.vue_el.usersData[meta.id] = Object.assign(this.vue_el.usersData[meta.id] || {}, {
              ...meta,
              uuid: meta.id,
              display_name: meta.name
            });
          });
          this.vue_el.forceRerenderComputedProps++;
        }
        if (typeof metadata.next !== "undefined" && metadata.data.length) {
          req.page = { next: metadata.next };
        } else {
          req = null;
        }
      }
      this.assignBackroundColor();
      // this.vue_el.consoleLog(`this.vue_el.usersData`, Object.keys(this.vue_el.usersData).length);
      this.vue_el.consoleLog(`this.vue_el.usersData`, this.vue_el.usersData);
    } catch (error) {
      this.vue_el.logErrors(error);
    }
  }
  assignBackroundColor() {
    for (const key in this.vue_el.usersData) {
      this.vue_el.usersData[key].avatarBackground = this.vue_el.hslColorFromArbitraryString(key);
    }
  }
  async pubnub_updateUsersMetadata(uuid_array: string[] = []) {
    const uuids = uuid_array;
    for (const uuid of uuids) {
      try {
        const metadata: Pubnub.GetUUIDMetadataResponse<Pubnub.ObjectCustom> = await new Promise((resolve, reject) => {
          this.vue_el.pubnub.objects.getUUIDMetadata(
            {
              uuid,
              include: {
                customFields: true
              }
            },
            (status, response) => (status.error ? reject(status) : resolve(response))
          );
        });
        if (metadata.data) {
          this.vue_el.usersData[metadata.data.id] = {
            ...metadata.data,
            uuid: metadata.data.id,
            display_name: metadata.data.name
          };
        }
      } catch (error) {
        // console.error('pubnub_updateUsersMetadata',error)
        // throws Error when a requested object is not found.
        // this.vue_el.logErrors(error);
      }
    }
  }
  async pubnub_setActiveUsersUUIDsArray() {
    if (this.vue_el.isEmbed) {
      return;
    }
    try {
      const subscribedUsers: Pubnub.HereNowResponse = await new Promise((resolve, reject) => {
        this.vue_el.pubnub.hereNow(
          {
            channels: [this.vue_el.baseChannel]
            //   includeUUIDs: true,
            //   includeState: true
          },
          (status, result) => {
            status.error ? reject(status) : resolve(result);
          }
        );
      });
      this.vue_el.consoleLog(`subscribedUsers`, subscribedUsers);
      if (typeof subscribedUsers.channels[this.vue_el.baseChannel] != "undefined") {
        this.vue_el.activeUsersUUIDs = subscribedUsers.channels[this.vue_el.baseChannel].occupants
          .filter((user) => user.uuid !== this.vue_el.uuid)
          .map((el) => el.uuid);
      } else {
        this.vue_el.activeUsersUUIDs = [];
      }
      this.sortUUIDArray();
      this.vue_el.consoleLog(`this.vue_el.activeUsersUUIDs`, this.vue_el.activeUsersUUIDs);
    } catch (error) {
      this.vue_el.logErrors(error);
    }
  }
  sortUUIDArray() {
    this.vue_el.activeUsersUUIDs.sort((a, b) => {
      if (typeof this.vue_el.usersData[a] === "undefined" && typeof this.vue_el.usersData[b] === "undefined") {
        return 0;
      }
      if (typeof this.vue_el.usersData[a] === "undefined") {
        return 1;
      }
      if (typeof this.vue_el.usersData[b] === "undefined") {
        return -1;
      }
      return (this.vue_el.usersData[a] as UserData).name.localeCompare((this.vue_el.usersData[b] as UserData).name);
    });
  }
  async setPrivateChannelAndSubscribe(
    // uuid:string =null,
    subscription_name: string = null,
    hasMembership: boolean = false,
    uuid: string = null
  ): Promise<Channel> {
    try {
      this.vue_el.consoleLog("## setPrivateChannelAndSubscribe START");

      const isGroup = subscription_name && subscription_name.includes("group_");

      this.vue_el.consoleLog(`subscription_name`, subscription_name);
      if (subscription_name in this.vue_el.channels) {
        this.vue_el.consoleLog(`subscription_name in`, subscription_name);
        return this.vue_el.channels[subscription_name];
      }
      const display_name = this.getUsersName(uuid);

      const channel = {
        //### ChatWindow ###
        hasMembership,
        isOpen: false,
        isExpanded: false,
        currentMessage: "",
        container_div_id: (Math.random() * 1000000).toFixed(0),
        //### ChannelToSubscribe ###
        display_name,
        subscription_name,
        avatarBackground: this.vue_el.hslColorFromArbitraryString(uuid || subscription_name),
        avatarBackgroundOpacity: this.vue_el.hslColorFromArbitraryString(uuid || subscription_name, 50, 75),
        type: isGroup ? "group" : "private",
        unread_messages: hasMembership ? 1 : 0,
        mute: false,
        emojiExpanded: false,
        uploadExpanded: false,
        fileRecords: [],
        openChatCount: 0
      } as Channel;
      if (uuid && !isGroup) {
        channel["uuid"] = uuid;
      }
      if (isGroup) {
        await this.pubnub_getChannelMetaData(channel, true);
      }

      this.updateChannelObject(channel, false);
      this.vue_el.forceRerenderComputedProps++;
      const subscribers = [this.vue_el.uuid];
      if (uuid !== "") {
        subscribers.push(uuid);
      }
      this.pubnub_subscribeToChannels([channel.subscription_name], subscribers);
      this.vue_el.consoleLog("## setPrivateChannelAndSubscribe END", channel);
      return channel;
    } catch (error) {
      this.vue_el.logErrors(error);
    }
  }
  updateChannelObject(chan: Channel, overwrite = true) {
    if (!overwrite && chan.subscription_name in this.vue_el.channels) return;
    this.vue_el.channels[chan.subscription_name] = Object.assign(
      this.vue_el.channels[chan.subscription_name] || {},
      chan
    );
  }
  async pubnub_setChannelsWithMembership() {
    if (this.vue_el.isEmbed) return;
    try {
      let memberships = [];
      let req: Pubnub.GetMembershipsParametersv2 = {
        uuid: this.vue_el.uuid,
        include: { customFields: true }
      };

      while (req) {
        let mem: Pubnub.ManageMembershipsResponse<Pubnub.ObjectCustom, Pubnub.ObjectCustom> = await new Promise(
          (resolve, reject) => {
            this.vue_el.pubnub.objects.getMemberships(req, function (status, result) {
              if (status.error) {
                reject(status);
              } else {
                resolve(result);
              }
            });
          }
        );
        if (mem.data) {
          memberships.push(...mem.data.filter((membership) => membership.channel.id != this.vue_el.baseChannel));
        }
        if (typeof mem.next !== "undefined" && mem.data.length) {
          req.page = { next: mem.next };
        } else {
          req = null;
        }
        // this.vue_el.consoleLog(`mem`, mem);
      }

      if (this.vue_el.debug.deleteAllMemberships) {
        this.vue_el.pubnub.objects.removeMemberships({
          channels: memberships.map((mem) => mem.channel.id)
        });
      }

      // this.vue_el.consoleLog(`memberships`, memberships);
      let membership_channel_ids = [];
      if (memberships.length) {
        for (let membership of memberships) {
          let channel_id = membership.channel.id;
          membership_channel_ids.push(channel_id);
          let lastReadTimetoken = null;
          let type: any = null;
          let uuid: any = null;

          if (membership.custom) {
            lastReadTimetoken = membership.custom.lastReadTimetoken || null;
            type = membership.custom.type || null;
            uuid = membership.custom.uuid || null;
          }

          let isPublicChannel = false;
          let channelExists = false;

          // let subscription_names = Object.keys(this.vue_el.channels);
          if (channel_id in this.vue_el.channels) {
            this.vue_el.channels[channel_id].hasMembership = true;
            this.vue_el.channels[channel_id].lastReadTimetoken = lastReadTimetoken;
            isPublicChannel = this.vue_el.channels[channel_id].type != "public";
            channelExists = true;
          }

          //if a membership is not in channels, create one
          //vue_el happens when:
          // 1. old chat, with user, which isn't currently active
          // 2. group chat
          if (!channelExists && !isPublicChannel) {
            let display_name = null;
            let channel = {
              //### ChatWindow ###
              isOpen: false,
              isExpanded: false,
              currentMessage: "",
              container_div_id: (Math.random() * 1000000).toFixed(0),

              //### ChannelToSubscribe ###
              display_name,
              subscription_name: channel_id,
              hasMembership: true,
              lastReadTimetoken,
              type: type,
              avatarBackground: this.vue_el.hslColorFromArbitraryString(channel_id),
              avatarBackgroundOpacity: this.vue_el.hslColorFromArbitraryString(channel_id, 50, 75),
              unread_messages: 0,
              mute: false,
              emojiExpanded: false,
              uploadExpanded: false,
              fileRecords: [],
              openChatCount: 0
            } as Channel;

            if (uuid) {
              channel["uuid"] = uuid;
              channel["type"] = "private";
            }
            if (channel.type != "private") {
              await this.pubnub_getChannelMetaData(channel, false);
            }
            if (channel.deleted) {
              // await this.vue_el.deleteChannel(channel);
              continue;
            }

            //if its a dynamic channel we dont need the members and the channel is valid
            let channelValid = true;
            if (channel.type == "public") {
              channel.avatarBackground = this.vue_el.primaryColor;
              channel.avatarBackgroundOpacity = this.vue_el.hslColorFromArbitraryString(
                this.vue_el.primaryColor,
                50,
                75
              );
            } else if (channel.type == "group") {
              channelValid = await this.pubnub_getChannelMembers(channel);
            }

            if (!channelValid) {
              this.vue_el.pubnub.objects.removeMemberships({
                channels: [channel.subscription_name]
              });
            }

            this.updateChannelObject(channel);
          }
        }
      }
      await this.removeDeletedChannels(membership_channel_ids);
    } catch (error) {
      this.vue_el.logErrors(error);
    }
    return;
  }
  async removeDeletedChannels(membership_channel_ids = null) {
    //remove group channels without membership
    let channelsToUnsubscribe = [];
    this.vue_el.consoleLog(`### removeDeletedChannels`, this.vue_el.channels);
    for (let key of this.getChannelKeys()) {
      if (this.vue_el.channels[key]?.type == "group") {
        await this.pubnub_getChannelMetaData(this.vue_el.channels[key], true);
      }
      if (
        this.vue_el.channels[key]?.deleted ||
        (membership_channel_ids &&
          this.vue_el.channels[key]?.type == "group" &&
          membership_channel_ids.findIndex((el) => el == key) < 0)
      ) {
        channelsToUnsubscribe.push(key);
        delete this.vue_el.messages[key];
        delete this.vue_el.channels[key];
      }
    }
    if (channelsToUnsubscribe.length) {
      await this.vue_el.pubnub.channelGroups.removeChannels({
        channels: channelsToUnsubscribe,
        channelGroup: this.vue_el.uuid
      });
    }
  }
  async deleteChannel(channel: Channel, onlyLeaveChannelUuid: string = null) {
    if (onlyLeaveChannelUuid) {
      channel.group_participants.splice(channel.group_participants.indexOf(onlyLeaveChannelUuid), 1);
    }
    await this.pubnub_setChannelMembership(
      channel,
      null,
      !!onlyLeaveChannelUuid,
      onlyLeaveChannelUuid ? [onlyLeaveChannelUuid] : [...channel.group_participants, channel.group_owner],
      true
    );

    if (channel.subscription_name in this.vue_el.channels && !onlyLeaveChannelUuid) {
      // channel.
      this.vue_el.channels[channel.subscription_name].group_participants = [];
      this.vue_el.channels[channel.subscription_name].group_owner = null;
      this.vue_el.channels[channel.subscription_name].deleted = true;
      await this.pubnub_setChannelMetaData(channel.subscription_name);
    } else if (channel.subscription_name in this.vue_el.channels && onlyLeaveChannelUuid) {
      this.vue_el.channels[channel.subscription_name].group_participants = channel.group_participants;
      // await this.pubnub_setChannelMetaData(channel.subscription_name)
      this.vue_el.channels[channel.subscription_name].deleted = true;
    }
  }
  pubnub_getChannelMetaData(channel: Channel, updateOriginalChannel = false) {
    return new Promise((resolve) => {
      this.vue_el.pubnub.objects.getChannelMetadata(
        {
          channel: channel.subscription_name
        },
        (status, response) => {
          if (status.error) {
            console.error("pubnub_getChannelMetaData", status, channel);
            //because private channels have no metadata (yet)
            resolve(status);
          } else {
            this.vue_el.consoleLog("pubnub_getChannelMetaData current channel", channel);
            this.vue_el.consoleLog("pubnub_getChannelMetaData response data", response.data);

            if (response.data.name) {
              channel.display_name = response.data.name;
            }

            const customFields = {} as Partial<Channel>;

            if (response.data.custom) {
              const customData = response.data.custom;

              if (customData.type) {
                customFields.type = customData.type as string;
              }

              if (customData.group_owner) {
                customFields.group_owner = customData.group_owner as string;
              }

              if (customData.dynamic) {
                customFields.dynamic = customData.dynamic as boolean;
              }

              if (customData.otSession) {
                customFields.otSession = customData.otSession as string;
              }

              if (customData.deleted) {
                customFields.deleted = customData.deleted as boolean;
              }
            }
            channel = Object.assign(channel, customFields);

            if (updateOriginalChannel && channel.subscription_name in this.vue_el.channels) {
              this.vue_el.channels[channel.subscription_name] = Object.assign(
                this.vue_el.channels[channel.subscription_name],
                customFields
              );
            }

            resolve(response);
          }
        }
      );
    });
  }
  async pubnub_getChannelMembers(channel: Channel) {
    let channelMembers: Pubnub.ManageChannelMembersResponse<Pubnub.ObjectCustom, Pubnub.ObjectCustom> =
      await new Promise((resolve, reject) => {
        this.vue_el.pubnub.objects.getChannelMembers(
          {
            channel: channel.subscription_name,
            include: {
              customFields: true
            }
          },
          (status, response) => (status.error ? reject(status) : resolve(response))
        );
      });
    //vue_el case occures sometimes -> means only myself is member of the channel -> vue_el can happen when the other user is deleted or some connection problem occured
    if (channel.type == "private" && channelMembers.data.length == 1) {
      return false;
    }
    for (let data of channelMembers.data) {
      if (data.uuid.id != this.vue_el.uuid && channel.type == "private") {
        channel.uuid = data.uuid.id;
      } else {
        if (!channel.group_participants) {
          channel.group_participants = [];
        }
        if (channel.group_owner != data.uuid.id) {
          channel.group_participants.push(data.uuid.id);
        }
      }
    }
    return true;
  }
  async pubnub_setChannelMetaData(key: string) {
    if (this.vue_el.isEmbed) return;
    await new Promise((resolve, reject) => {
      let custom = {};
      let data = {};
      custom["type"] = this.vue_el.channels[key].type;
      if (this.vue_el.channels[key].deleted) {
        custom["deleted"] = true;
      }
      if (this.vue_el.channels[key].group_owner) {
        custom["group_owner"] = this.vue_el.channels[key].group_owner;
      }
      if (this.vue_el.channels[key].dynamic) {
        custom["dynamic"] = this.vue_el.channels[key].dynamic;
      }
      if (this.vue_el.channels[key].otSession) {
        custom["otSession"] = this.vue_el.channels[key].otSession;
      }
      if (this.vue_el.channels[key].type != "private") {
        data["name"] = this.vue_el.channels[key].display_name;
      }
      data["custom"] = custom;

      this.vue_el.pubnub.objects.setChannelMetadata(
        {
          channel: this.vue_el.channels[key].subscription_name,
          data
        },
        (status, response) => (status.error ? reject(status) : resolve(response))
      );
    });
  }
  async pubnub_setChannelMembership(
    channel: Channel,
    lastReadTimetoken: number = null,
    setMembershipForAll: boolean = false,
    membershipsToRemove: string[] = [],
    onlyDelete = false
  ) {
    if (this.vue_el.isEmbed) return;
    // this.vue_el.consoleLog(`pubnub_setChannelMembership channel`, channel);
    let membersToSet = !onlyDelete ? [this.vue_el.uuid] : [];
    if ((setMembershipForAll || onlyDelete) && membershipsToRemove.length) {
      this.vue_el.pubnub.objects.removeChannelMembers({
        channel: channel.subscription_name,
        uuids: membershipsToRemove
      });
    }
    if (setMembershipForAll && channel.group_participants) {
      membersToSet = membersToSet.concat(channel.group_participants);
    }
    if (setMembershipForAll && channel.uuid) {
      membersToSet = [channel.uuid];
    }

    let promises = [];
    for (let member_uuid of membersToSet) {
      promises.push(
        new Promise((resolve, reject) => {
          let custom = {
            type: channel.type,
            lastReadTimetoken: lastReadTimetoken
          };
          if (channel.uuid) {
            custom["uuid"] = channel.uuid;
          }
          this.vue_el.pubnub.objects.setMemberships(
            {
              uuid: member_uuid,
              channels: [
                {
                  id: channel.subscription_name,
                  custom
                }
              ]
            },
            (status, result) => (status.error ? reject(status) : resolve(result))
          );
        })
      );
    }
    Promise.all(promises);
    return;
  }
  async pubnub_setUnreadMessagesCount() {
    try {
      const openChatChannelUids = this.getChannelValues()
        .filter((el: Channel) => el.isOpen)
        .map((el: Channel) => el.subscription_name);
      const subscription_names = [];
      const timetokens = [];
      this.getChannelValues().forEach((e: Channel) => {
        if (!openChatChannelUids.includes(e.subscription_name) && e.lastReadTimetoken) {
          subscription_names.push(e.subscription_name);
          timetokens.push(e.lastReadTimetoken);
        }
      });

      if (subscription_names.length) {
        let subscription_names_chunk = this.chunkArray(subscription_names, 30);
        let timetokens_chunk = this.chunkArray(timetokens, 30);

        for (let i = 0; i < subscription_names_chunk.length; i++) {
          let messageCounts: Pubnub.MessageCountsResponse = await new Promise((resolve, reject) => {
            this.vue_el.pubnub.messageCounts(
              {
                channels: subscription_names_chunk[i],
                channelTimetokens: timetokens_chunk[i]
              },
              (status, response) => (status.error ? reject(status) : resolve(response))
            );
          });

          if (messageCounts.channels) {
            for (let key in messageCounts.channels) {
              this.vue_el.channelMessageCounts[key] = messageCounts.channels[key];
            }
            // this.vue_el.channelMessageCounts = Object.assign(this.vue_el.channelMessageCounts,messageCounts.channels);

            // set last channel after offline
            for (let key in this.vue_el.channelMessageCounts) {
              if (this.vue_el.channelMessageCounts[key] && this.vue_el.channelMessageCounts[key] > 0) {
                let lastChannel = this.vue_el.channels[key];
                if (typeof lastChannel !== "undefined") {
                  this.vue_el.lastIncomingChannels.push(lastChannel);
                  // this.vue_el.lastIncomingChannel = lastChannel;
                  break;
                }
              }
            }
          }
        }
      }
      this.vue_el.forceRerenderComputedProps++;

      return;
    } catch (error) {
      this.vue_el.logErrors(error);
    }
  }
  async handleDynamicChannelOnUpdate() {
    let channelNamesToAdd = this.vue_el.dynamicChannels.slice();
    let channelIndexesToSplice = [];
    let channelsToUnsubscribe = [];

    for (let el of this.getChannelValues()) {
      // let el: Channel = this.vue_el.channels[i];
      let channelAlreadyInChannelsIndex = channelNamesToAdd.indexOf(el.display_name);
      if (el.dynamic && !el.hasMembership && channelAlreadyInChannelsIndex == -1) {
        channelsToUnsubscribe.push(el.subscription_name);
        channelIndexesToSplice.push(el.subscription_name);
      }
      if (channelAlreadyInChannelsIndex > -1) {
        channelNamesToAdd.splice(channelAlreadyInChannelsIndex, 1);
      }
    }

    for (const key of channelIndexesToSplice) {
      delete this.vue_el.channels[key];
    }
    const newChannels = channelNamesToAdd
      .map((el: string) => {
        return { display_name: el, dynamic: true };
      })
      .map((el) => {
        //clean string according to rules here : https://www.pubnub.com/docs/platform/channels/overview
        // let cleanName = el.replace(/[^0-9a-zA-Z_\-\=\@\.\!\$\#\%\&\^\s;]/g, "_");
        //new cleaning because channel names for file uploads seem much stricter than the docs...
        const cleanName = el.display_name.replace(/[^0-9a-zA-Z_]/g, (char) => char.charCodeAt(0).toString());
        const chatWindow = {
          //### ChatWindow ###
          isOpen: false,
          isExpanded: false,
          currentMessage: "",
          container_div_id: (Math.random() * 1000000).toFixed(0),

          //### ChannelToSubscribe ###
          display_name: el.display_name,
          subscription_name: cleanName,
          avatarBackground: this.vue_el.primaryColor,
          //make colors for channelnames
          avatarBackgroundOpacity: this.vue_el.hslColorFromArbitraryString(this.vue_el.primaryColor, 50, 75),
          type: "public",
          mute: false,
          emojiExpanded: false,
          uploadExpanded: false,
          fileRecords: [],
          openChatCount: 0,
          dynamic: el.dynamic
          // relativePath: el.url
        } as Channel;
        return chatWindow;
      });
    newChannels.forEach((element) => {
      this.updateChannelObject(element);
    });
    newChannels.forEach((el) => {
      this.pubnub_setChannelMetaData(el.subscription_name);
    });

    await this.pubnub_subscribeToChannels(newChannels.map((el) => el.subscription_name));
    setTimeout(() => {
      for (const key of this.getChannelKeys()) {
        const chan = this.vue_el.channels[key];
        if (chan.dynamic && !chan.hasMembership) {
          chan.new = true;
        } else {
          chan.new = false;
        }
      }
      this.vue_el.forceRerenderComputedProps++;
    }, 500);
    if (channelsToUnsubscribe.length) {
      const unsubscribeParams = {
        channels: channelsToUnsubscribe,
        channelGroup: this.vue_el.uuid
      };
      this.vue_el.pubnub.channelGroups.removeChannels(unsubscribeParams);
    }
  }
}
