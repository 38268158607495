<template>
  <div class="emojies">
    <close-button
      v-on:click.native="$emit('close')"
      class="icon-component"
      :class="{
        'svg-primary-color': channel.type === 'public',
        'svg-secondary-color': channel.type !== 'public'
      }"
    ></close-button>
    <input
      v-model="search"
      :placeholder="translations.chat.emoji_search"
      v-on:keydown="$event.stopPropagation()"
      v-on:keyup="$event.stopPropagation()"
      v-on:keypress="$event.stopPropagation()"
    />
    <div class="emoji-wrap">
      <div
        v-for="(em, index) of searchedEmojis"
        :key="index"
        class="em-el"
        v-on:click="$emit('selectEmoji', em), $emit('close')"
      >
        {{ em.data }}
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.emojies {
  width: 100%;
  padding: 5px;
  margin-bottom: 14px;
  input {
    @media all and (max-height: 400px) {
      display: none;
    }
    margin-bottom: 15px;
    //  background-color: #F0F1F3;
    padding: 5px;
    width: 100%;
    display: block;
    border: none;
  }
  .emoji-wrap {
    height: 180px;
    overflow-y: scroll;
    line-height: 30px;
    font-size: 25px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
    grid-gap: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      border-radius: 4px;
      width: 4px;
      overflow: hidden;
    }
  }
  .em-el {
    cursor: pointer;
  }
}
</style>

<script lang="ts">
import { PropType } from "vue";
import { Channel, Translations } from "../types";
import { CloseButton } from "./svgs.vue";

export default {
  name: "Emojis", // vue component name
  components: {
    CloseButton
  },
  data: () => {
    return {
      emojisDefault,
      search: ""
    };
  },
  props: {
    channel: {
      type: Object as PropType<Channel>,
      required: false
    },
    translations: {
      type: Object as PropType<Translations>,
      required: true
    }
  },
  computed: {
    searchedEmojis() {
      return this.emojisDefault.filter((el: Emoji) => {
        return el.aliases.find((e) => this.search === "" || e.toLowerCase().includes(this.search.toLowerCase()));
      });
    }
  }
};

export interface IEmoji {
  data: string;
  category: string;
  aliases: string[];
}
export class Emoji implements IEmoji {
  constructor(public data: string, public category: string, public aliases: string[]) {}
}
export const emojisDefault: IEmoji[] = [
  new Emoji("😀", "Peoples", ["grinning"]),
  new Emoji("😃", "Peoples", ["smiley"]),
  new Emoji("😄", "Peoples", ["smile"]),
  new Emoji("😁", "Peoples", ["grin"]),
  new Emoji("😆", "Peoples", ["laughing", "satisfied"]),
  new Emoji("😅", "Peoples", ["sweat_smile"]),
  new Emoji("😂", "Peoples", ["joy"]),
  new Emoji("🤣", "Peoples", ["rofl"]),
  new Emoji("😌", "Peoples", ["relaxed"]),
  new Emoji("😊", "Peoples", ["blush"]),
  new Emoji("😇", "Peoples", ["innocent"]),
  new Emoji("🙂", "Peoples", ["slightly_smiling_face"]),
  new Emoji("🙃", "Peoples", ["upside_down_face"]),
  new Emoji("😉", "Peoples", ["wink"]),
  new Emoji("😌", "Peoples", ["relieved"]),
  new Emoji("😍", "Peoples", ["heart_eyes"]),
  new Emoji("😘", "Peoples", ["kissing_heart"]),
  new Emoji("😗", "Peoples", ["kissing"]),
  new Emoji("😙", "Peoples", ["kissing_smiling_eyes"]),
  new Emoji("😚", "Peoples", ["kissing_closed_eyes"]),
  new Emoji("😋", "Peoples", ["yum"]),
  new Emoji("😜", "Peoples", ["stuck_out_tongue_winking_eye"]),
  new Emoji("😝", "Peoples", ["stuck_out_tongue_closed_eyes"]),
  new Emoji("😛", "Peoples", ["stuck_out_tongue"]),
  new Emoji("🤑", "Peoples", ["money_mouth_face"]),
  new Emoji("🤗", "Peoples", ["hugs"]),
  new Emoji("🤓", "Peoples", ["nerd_face"]),
  new Emoji("😎", "Peoples", ["sunglasses"]),
  new Emoji("🤡", "Peoples", ["clown_face"]),
  new Emoji("🤠", "Peoples", ["cowboy_hat_face"]),
  new Emoji("😏", "Peoples", ["smirk"]),
  new Emoji("😒", "Peoples", ["unamused"]),
  new Emoji("😞", "Peoples", ["disappointed"]),
  new Emoji("😔", "Peoples", ["pensive"]),
  new Emoji("😟", "Peoples", ["worried"]),
  new Emoji("😕", "Peoples", ["confused"]),
  new Emoji("🙁", "Peoples", ["slightly_frowning_face"]),
  new Emoji("☹️", "Peoples", ["frowning_face"]),
  new Emoji("😣", "Peoples", ["persevere"]),
  new Emoji("😖", "Peoples", ["confounded"]),
  new Emoji("😫", "Peoples", ["tired_face"]),
  new Emoji("😩", "Peoples", ["weary"]),
  new Emoji("😤", "Peoples", ["triumph"]),
  new Emoji("😠", "Peoples", ["angry"]),
  new Emoji("😡", "Peoples", ["rage", "pout"]),
  new Emoji("😶", "Peoples", ["no_mouth"]),
  new Emoji("😐", "Peoples", ["neutral_face"]),
  new Emoji("😑", "Peoples", ["expressionless"]),
  new Emoji("😯", "Peoples", ["hushed"]),
  new Emoji("😦", "Peoples", ["frowning"]),
  new Emoji("😧", "Peoples", ["anguished"]),
  new Emoji("😮", "Peoples", ["open_mouth"]),
  new Emoji("😲", "Peoples", ["astonished"]),
  new Emoji("😵", "Peoples", ["dizzy_face"]),
  new Emoji("😳", "Peoples", ["flushed"]),
  new Emoji("😱", "Peoples", ["scream"]),
  new Emoji("😨", "Peoples", ["fearful"]),
  new Emoji("😰", "Peoples", ["cold_sweat"]),
  new Emoji("😢", "Peoples", ["cry"]),
  new Emoji("😥", "Peoples", ["disappointed_relieved"]),
  new Emoji("🤤", "Peoples", ["drooling_face"]),
  new Emoji("😭", "Peoples", ["sob"]),
  new Emoji("😓", "Peoples", ["sweat"]),
  new Emoji("😪", "Peoples", ["sleepy"]),
  new Emoji("😴", "Peoples", ["sleeping"]),
  new Emoji("🙄", "Peoples", ["roll_eyes"]),
  new Emoji("🤔", "Peoples", ["thinking"]),
  new Emoji("🤥", "Peoples", ["lying_face"]),
  new Emoji("😬", "Peoples", ["grimacing"]),
  new Emoji("🤐", "Peoples", ["zipper_mouth_face"]),
  new Emoji("🤢", "Peoples", ["nauseated_face"]),
  new Emoji("🤧", "Peoples", ["sneezing_face"]),
  new Emoji("😷", "Peoples", ["mask"]),
  new Emoji("🤒", "Peoples", ["face_with_thermometer"]),
  new Emoji("🤕", "Peoples", ["face_with_head_bandage"]),
  new Emoji("😈", "Peoples", ["smiling_imp"]),
  new Emoji("👿", "Peoples", ["imp"]),
  new Emoji("👹", "Peoples", ["japanese_ogre"]),
  new Emoji("👺", "Peoples", ["japanese_goblin"]),
  new Emoji("💩", "Peoples", ["hankey", "poop", "shit"]),
  new Emoji("👻", "Peoples", ["ghost"]),
  new Emoji("💀", "Peoples", ["skull"]),
  new Emoji("☠️", "Peoples", ["skull_and_crossbones"]),
  new Emoji("👽", "Peoples", ["alien"]),
  new Emoji("👾", "Peoples", ["space_invader"]),
  new Emoji("🤖", "Peoples", ["robot"]),
  new Emoji("🎃", "Peoples", ["jack_o_lantern"]),
  new Emoji("😺", "Peoples", ["smiley_cat"]),
  new Emoji("😸", "Peoples", ["smile_cat"]),
  new Emoji("😹", "Peoples", ["joy_cat"]),
  new Emoji("😻", "Peoples", ["heart_eyes_cat"]),
  new Emoji("😼", "Peoples", ["smirk_cat"]),
  new Emoji("😽", "Peoples", ["kissing_cat"]),
  new Emoji("🙀", "Peoples", ["scream_cat"]),
  new Emoji("😿", "Peoples", ["crying_cat_face"]),
  new Emoji("😾", "Peoples", ["pouting_cat"]),
  new Emoji("👐", "Peoples", ["open_hands"]),
  new Emoji("🙌", "Peoples", ["raised_hands"]),
  new Emoji("👏", "Peoples", ["clap"]),
  new Emoji("🙏", "Peoples", ["pray"]),
  new Emoji("🤝", "Peoples", ["handshake"]),
  new Emoji("👍", "Peoples", ["+1", "thumbsup"]),
  new Emoji("👎", "Peoples", ["-1", "thumbsdown"]),
  new Emoji("👊", "Peoples", ["fist_oncoming", "facepunch", "punch"]),
  new Emoji("✊", "Peoples", ["fist_raised", "fist"]),
  new Emoji("🤛", "Peoples", ["fist_left"]),
  new Emoji("🤜", "Peoples", ["fist_right"]),
  new Emoji("🤞", "Peoples", ["crossed_fingers"]),
  new Emoji("✌️", "Peoples", ["v"]),
  new Emoji("🤘", "Peoples", ["metal"]),
  new Emoji("👌", "Peoples", ["ok_hand"]),
  new Emoji("👈", "Peoples", ["point_left"]),
  new Emoji("👉", "Peoples", ["point_right"]),
  new Emoji("👆", "Peoples", ["point_up_2"]),
  new Emoji("👇", "Peoples", ["point_down"]),
  new Emoji("☝️", "Peoples", ["point_up"]),
  new Emoji("✋", "Peoples", ["hand", "raised_hand"]),
  new Emoji("🤚", "Peoples", ["raised_back_of_hand"]),
  new Emoji("🖐", "Peoples", ["raised_hand_with_fingers_splayed"]),
  new Emoji("🖖", "Peoples", ["vulcan_salute"]),
  new Emoji("👋", "Peoples", ["wave"]),
  new Emoji("🤙", "Peoples", ["call_me_hand"]),
  new Emoji("💪", "Peoples", ["muscle"]),
  new Emoji("🖕", "Peoples", ["middle_finger", "fu"]),
  new Emoji("✍️", "Peoples", ["writing_hand"]),
  new Emoji("🤳", "Peoples", ["selfie"]),
  new Emoji("💅", "Peoples", ["nail_care"]),
  new Emoji("💍", "Peoples", ["ring"]),
  new Emoji("💄", "Peoples", ["lipstick"]),
  new Emoji("💋", "Peoples", ["kiss"]),
  new Emoji("👄", "Peoples", ["lips"]),
  new Emoji("👅", "Peoples", ["tongue"]),
  new Emoji("👂", "Peoples", ["ear"]),
  new Emoji("👃", "Peoples", ["nose"]),
  new Emoji("👣", "Peoples", ["footprints"]),
  new Emoji("👁", "Peoples", ["eye"]),
  new Emoji("👀", "Peoples", ["eyes"]),
  new Emoji("🗣", "Peoples", ["speaking_head"]),
  new Emoji("👤", "Peoples", ["bust_in_silhouette"]),
  new Emoji("👥", "Peoples", ["busts_in_silhouette"]),
  new Emoji("👶", "Peoples", ["baby"]),
  new Emoji("👦", "Peoples", ["boy"]),
  new Emoji("👧", "Peoples", ["girl"]),
  new Emoji("👨", "Peoples", ["man"]),
  new Emoji("👩", "Peoples", ["woman"]),
  new Emoji("👱‍♀", "Peoples", ["blonde_woman"]),
  new Emoji("👱", "Peoples", ["blonde_man", "person_with_blond_hair"]),
  new Emoji("👴", "Peoples", ["older_man"]),
  new Emoji("👵", "Peoples", ["older_woman"]),
  new Emoji("👲", "Peoples", ["man_with_gua_pi_mao"]),
  new Emoji("👳‍♀", "Peoples", ["woman_with_turban"]),
  new Emoji("👳", "Peoples", ["man_with_turban"]),
  new Emoji("👮‍♀", "Peoples", ["policewoman"]),
  new Emoji("👮", "Peoples", ["policeman", "cop"]),
  new Emoji("👷‍♀", "Peoples", ["construction_worker_woman"]),
  new Emoji("👷", "Peoples", ["construction_worker_man", "construction_worker"]),
  new Emoji("💂‍♀", "Peoples", ["guardswoman"]),
  new Emoji("💂", "Peoples", ["guardsman"]),
  new Emoji("👩‍⚕", "Peoples", ["woman_health_worker"]),
  new Emoji("👨‍⚕", "Peoples", ["man_health_worker"]),
  new Emoji("👩‍🌾", "Peoples", ["woman_farmer"]),
  new Emoji("👨‍🌾", "Peoples", ["man_farmer"]),
  new Emoji("👩‍🍳", "Peoples", ["woman_cook"]),
  new Emoji("👨‍🍳", "Peoples", ["man_cook"]),
  new Emoji("👩‍🎓", "Peoples", ["woman_student"]),
  new Emoji("👨‍🎓", "Peoples", ["man_student"]),
  new Emoji("👩‍🎤", "Peoples", ["woman_singer"]),
  new Emoji("👨‍🎤", "Peoples", ["man_singer"]),
  new Emoji("👩‍🏫", "Peoples", ["woman_teacher"]),
  new Emoji("👨‍🏫", "Peoples", ["man_teacher"]),
  new Emoji("👩‍🏭", "Peoples", ["woman_factory_worker"]),
  new Emoji("👨‍🏭", "Peoples", ["man_factory_worker"]),
  new Emoji("👩‍💻", "Peoples", ["woman_technologist"]),
  new Emoji("👨‍💻", "Peoples", ["man_technologist"]),
  new Emoji("👩‍💼", "Peoples", ["woman_office_worker"]),
  new Emoji("👨‍💼", "Peoples", ["man_office_worker"]),
  new Emoji("👩‍🔧", "Peoples", ["woman_mechanic"]),
  new Emoji("👨‍🔧", "Peoples", ["man_mechanic"]),
  new Emoji("👩‍🔬", "Peoples", ["woman_scientist"]),
  new Emoji("👨‍🔬", "Peoples", ["man_scientist"]),
  new Emoji("👩‍🎨", "Peoples", ["woman_artist"]),
  new Emoji("👨‍🎨", "Peoples", ["man_artist"]),
  new Emoji("👩‍🚒", "Peoples", ["woman_firefighter"]),
  new Emoji("👨‍🚒", "Peoples", ["man_firefighter"]),
  new Emoji("👩‍🚀", "Peoples", ["woman_astronaut"]),
  new Emoji("👨‍🚀", "Peoples", ["man_astronaut"]),
  new Emoji("🤶", "Peoples", ["mrs_claus"]),
  new Emoji("🎅", "Peoples", ["santa"]),
  new Emoji("👸", "Peoples", ["princess"]),
  new Emoji("🤴", "Peoples", ["prince"]),
  new Emoji("👰", "Peoples", ["bride_with_veil"]),
  new Emoji("🤵", "Peoples", ["man_in_tuxedo"]),
  new Emoji("👼", "Peoples", ["angel"]),
  new Emoji("🤰", "Peoples", ["pregnant_woman"]),
  new Emoji("🙇‍♀", "Peoples", ["bowing_woman"]),
  new Emoji("🙇", "Peoples", ["bowing_man", "bow"]),
  new Emoji("💁", "Peoples", ["tipping_hand_woman", "information_desk_person", "sassy_woman"]),
  new Emoji("💁‍♂", "Peoples", ["tipping_hand_man", "sassy_man"]),
  new Emoji("🙅", "Peoples", ["no_good_woman", "no_good", "ng_woman"]),
  new Emoji("🙅‍♂", "Peoples", ["no_good_man", "ng_man"]),
  new Emoji("🙆", "Peoples", ["ok_woman"]),
  new Emoji("🙆‍♂", "Peoples", ["ok_man"]),
  new Emoji("🙋", "Peoples", ["raising_hand_woman", "raising_hand"]),
  new Emoji("🙋‍♂", "Peoples", ["raising_hand_man"]),
  new Emoji("🤦‍♀", "Peoples", ["woman_facepalming"]),
  new Emoji("🤦‍♂", "Peoples", ["man_facepalming"]),
  new Emoji("🤷‍♀", "Peoples", ["woman_shrugging"]),
  new Emoji("🤷‍♂", "Peoples", ["man_shrugging"]),
  new Emoji("🙎", "Peoples", ["pouting_woman", "person_with_pouting_face"]),
  new Emoji("🙎‍♂", "Peoples", ["pouting_man"]),
  new Emoji("🙍", "Peoples", ["frowning_woman", "person_frowning"]),
  new Emoji("🙍‍♂", "Peoples", ["frowning_man"]),
  new Emoji("💇", "Peoples", ["haircut_woman", "haircut"]),
  new Emoji("💇‍♂", "Peoples", ["haircut_man"]),
  new Emoji("💆", "Peoples", ["massage_woman", "massage"]),
  new Emoji("💆‍♂", "Peoples", ["massage_man"]),
  new Emoji("🕴", "Peoples", ["business_suit_levitating"]),
  new Emoji("💃", "Peoples", ["dancer"]),
  new Emoji("🕺", "Peoples", ["man_dancing"]),
  new Emoji("👯", "Peoples", ["dancing_women", "dancers"]),
  new Emoji("👯‍♂", "Peoples", ["dancing_men"]),
  new Emoji("🚶‍♀", "Peoples", ["walking_woman"]),
  new Emoji("🚶", "Peoples", ["walking_man", "walking"]),
  new Emoji("🏃‍♀", "Peoples", ["running_woman"]),
  new Emoji("🏃", "Peoples", ["running_man", "runner", "running"]),
  new Emoji("👫", "Peoples", ["couple"]),
  new Emoji("👭", "Peoples", ["two_women_holding_hands"]),
  new Emoji("👬", "Peoples", ["two_men_holding_hands"]),
  new Emoji("💑", "Peoples", ["couple_with_heart_woman_man", "couple_with_heart"]),
  new Emoji("👩‍❤️‍👩", "Peoples", ["couple_with_heart_woman_woman"]),
  new Emoji("👨‍❤️‍👨", "Peoples", ["couple_with_heart_man_man"]),
  new Emoji("💏", "Peoples", ["couplekiss_man_woman"]),
  new Emoji("👩‍❤️‍💋‍👩", "Peoples", ["couplekiss_woman_woman"]),
  new Emoji("👨‍❤️‍💋‍👨", "Peoples", ["couplekiss_man_man"]),
  new Emoji("👪", "Peoples", ["family_man_woman_boy", "family"]),
  new Emoji("👨‍👩‍👧", "Peoples", ["family_man_woman_girl"]),
  new Emoji("👨‍👩‍👧‍👦", "Peoples", ["family_man_woman_girl_boy"]),
  new Emoji("👨‍👩‍👦‍👦", "Peoples", ["family_man_woman_boy_boy"]),
  new Emoji("👨‍👩‍👧‍👧", "Peoples", ["family_man_woman_girl_girl"]),
  new Emoji("👩‍👩‍👦", "Peoples", ["family_woman_woman_boy"]),
  new Emoji("👩‍👩‍👧", "Peoples", ["family_woman_woman_girl"]),
  new Emoji("👩‍👩‍👧‍👦", "Peoples", ["family_woman_woman_girl_boy"]),
  new Emoji("👩‍👩‍👦‍👦", "Peoples", ["family_woman_woman_boy_boy"]),
  new Emoji("👩‍👩‍👧‍👧", "Peoples", ["family_woman_woman_girl_girl"]),
  new Emoji("👨‍👨‍👦", "Peoples", ["family_man_man_boy"]),
  new Emoji("👨‍👨‍👧", "Peoples", ["family_man_man_girl"]),
  new Emoji("👨‍👨‍👧‍👦", "Peoples", ["family_man_man_girl_boy"]),
  new Emoji("👨‍👨‍👦‍👦", "Peoples", ["family_man_man_boy_boy"]),
  new Emoji("👨‍👨‍👧‍👧", "Peoples", ["family_man_man_girl_girl"]),
  new Emoji("👩‍👦", "Peoples", ["family_woman_boy"]),
  new Emoji("👩‍👧", "Peoples", ["family_woman_girl"]),
  new Emoji("👩‍👧‍👦", "Peoples", ["family_woman_girl_boy"]),
  new Emoji("👩‍👦‍👦", "Peoples", ["family_woman_boy_boy"]),
  new Emoji("👩‍👧‍👧", "Peoples", ["family_woman_girl_girl"]),
  new Emoji("👨‍👦", "Peoples", ["family_man_boy"]),
  new Emoji("👨‍👧", "Peoples", ["family_man_girl"]),
  new Emoji("👨‍👧‍👦", "Peoples", ["family_man_girl_boy"]),
  new Emoji("👨‍👦‍👦", "Peoples", ["family_man_boy_boy"]),
  new Emoji("👨‍👧‍👧", "Peoples", ["family_man_girl_girl"]),
  new Emoji("👚", "Peoples", ["womans_clothes"]),
  new Emoji("👕", "Peoples", ["shirt", "tshirt"]),
  new Emoji("👖", "Peoples", ["jeans"]),
  new Emoji("👔", "Peoples", ["necktie"]),
  new Emoji("👗", "Peoples", ["dress"]),
  new Emoji("👙", "Peoples", ["bikini"]),
  new Emoji("👘", "Peoples", ["kimono"]),
  new Emoji("👠", "Peoples", ["high_heel"]),
  new Emoji("👡", "Peoples", ["sandal"]),
  new Emoji("👢", "Peoples", ["boot"]),
  new Emoji("👞", "Peoples", ["mans_shoe", "shoe"]),
  new Emoji("👟", "Peoples", ["athletic_shoe"]),
  new Emoji("👒", "Peoples", ["womans_hat"]),
  new Emoji("🎩", "Peoples", ["tophat"]),
  new Emoji("🎓", "Peoples", ["mortar_board"]),
  new Emoji("👑", "Peoples", ["crown"]),
  new Emoji("⛑", "Peoples", ["rescue_worker_helmet"]),
  new Emoji("🎒", "Peoples", ["school_satchel"]),
  new Emoji("👝", "Peoples", ["pouch"]),
  new Emoji("👛", "Peoples", ["purse"]),
  new Emoji("👜", "Peoples", ["handbag"]),
  new Emoji("💼", "Peoples", ["briefcase"]),
  new Emoji("👓", "Peoples", ["eyeglasses"]),
  new Emoji("🕶", "Peoples", ["dark_sunglasses"]),
  new Emoji("🌂", "Peoples", ["closed_umbrella"]),
  new Emoji("☂️", "Peoples", ["open_umbrella"]),
  new Emoji("🐶", "Nature", ["dog"]),
  new Emoji("🐱", "Nature", ["cat"]),
  new Emoji("🐭", "Nature", ["mouse"]),
  new Emoji("🐹", "Nature", ["hamster"]),
  new Emoji("🐰", "Nature", ["rabbit"]),
  new Emoji("🦊", "Nature", ["fox_face"]),
  new Emoji("🐻", "Nature", ["bear"]),
  new Emoji("🐼", "Nature", ["panda_face"]),
  new Emoji("🐨", "Nature", ["koala"]),
  new Emoji("🐯", "Nature", ["tiger"]),
  new Emoji("🦁", "Nature", ["lion"]),
  new Emoji("🐮", "Nature", ["cow"]),
  new Emoji("🐷", "Nature", ["pig"]),
  new Emoji("🐽", "Nature", ["pig_nose"]),
  new Emoji("🐸", "Nature", ["frog"]),
  new Emoji("🐵", "Nature", ["monkey_face"]),
  new Emoji("🙈", "Nature", ["see_no_evil"]),
  new Emoji("🙉", "Nature", ["hear_no_evil"]),
  new Emoji("🙊", "Nature", ["speak_no_evil"]),
  new Emoji("🐒", "Nature", ["monkey"]),
  new Emoji("🐔", "Nature", ["chicken"]),
  new Emoji("🐧", "Nature", ["penguin"]),
  new Emoji("🐦", "Nature", ["bird"]),
  new Emoji("🐤", "Nature", ["baby_chick"]),
  new Emoji("🐣", "Nature", ["hatching_chick"]),
  new Emoji("🐥", "Nature", ["hatched_chick"]),
  new Emoji("🦆", "Nature", ["duck"]),
  new Emoji("🦅", "Nature", ["eagle"]),
  new Emoji("🦉", "Nature", ["owl"]),
  new Emoji("🦇", "Nature", ["bat"]),
  new Emoji("🐺", "Nature", ["wolf"]),
  new Emoji("🐗", "Nature", ["boar"]),
  new Emoji("🐴", "Nature", ["horse"]),
  new Emoji("🦄", "Nature", ["unicorn"]),
  new Emoji("🐝", "Nature", ["bee", "honeybee"]),
  new Emoji("🐛", "Nature", ["bug"]),
  new Emoji("🦋", "Nature", ["butterfly"]),
  new Emoji("🐌", "Nature", ["snail"]),
  new Emoji("🐚", "Nature", ["shell"]),
  new Emoji("🐞", "Nature", ["beetle"]),
  new Emoji("🐜", "Nature", ["ant"]),
  new Emoji("🕷", "Nature", ["spider"]),
  new Emoji("🕸", "Nature", ["spider_web"]),
  new Emoji("🐢", "Nature", ["turtle"]),
  new Emoji("🐍", "Nature", ["snake"]),
  new Emoji("🦎", "Nature", ["lizard"]),
  new Emoji("🦂", "Nature", ["scorpion"]),
  new Emoji("🦀", "Nature", ["crab"]),
  new Emoji("🦑", "Nature", ["squid"]),
  new Emoji("🐙", "Nature", ["octopus"]),
  new Emoji("🦐", "Nature", ["shrimp"]),
  new Emoji("🐠", "Nature", ["tropical_fish"]),
  new Emoji("🐟", "Nature", ["fish"]),
  new Emoji("🐡", "Nature", ["blowfish"]),
  new Emoji("🐬", "Nature", ["dolphin", "flipper"]),
  new Emoji("🦈", "Nature", ["shark"]),
  new Emoji("🐳", "Nature", ["whale"]),
  new Emoji("🐋", "Nature", ["whale2"]),
  new Emoji("🐊", "Nature", ["crocodile"]),
  new Emoji("🐆", "Nature", ["leopard"]),
  new Emoji("🐅", "Nature", ["tiger2"]),
  new Emoji("🐃", "Nature", ["water_buffalo"]),
  new Emoji("🐂", "Nature", ["ox"]),
  new Emoji("🐄", "Nature", ["cow2"]),
  new Emoji("🦌", "Nature", ["deer"]),
  new Emoji("🐪", "Nature", ["dromedary_camel"]),
  new Emoji("🐫", "Nature", ["camel"]),
  new Emoji("🐘", "Nature", ["elephant"]),
  new Emoji("🦏", "Nature", ["rhinoceros"]),
  new Emoji("🦍", "Nature", ["gorilla"]),
  new Emoji("🐎", "Nature", ["racehorse"]),
  new Emoji("🐖", "Nature", ["pig2"]),
  new Emoji("🐐", "Nature", ["goat"]),
  new Emoji("🐏", "Nature", ["ram"]),
  new Emoji("🐑", "Nature", ["sheep"]),
  new Emoji("🐕", "Nature", ["dog2"]),
  new Emoji("🐩", "Nature", ["poodle"]),
  new Emoji("🐈", "Nature", ["cat2"]),
  new Emoji("🐓", "Nature", ["rooster"]),
  new Emoji("🦃", "Nature", ["turkey"]),
  new Emoji("🕊", "Nature", ["dove"]),
  new Emoji("🐇", "Nature", ["rabbit2"]),
  new Emoji("🐁", "Nature", ["mouse2"]),
  new Emoji("🐀", "Nature", ["rat"]),
  new Emoji("🐿", "Nature", ["chipmunk"]),
  new Emoji("🐾", "Nature", ["feet", "paw_prints"]),
  new Emoji("🐉", "Nature", ["dragon"]),
  new Emoji("🐲", "Nature", ["dragon_face"]),
  new Emoji("🌵", "Nature", ["cactus"]),
  new Emoji("🎄", "Nature", ["christmas_tree"]),
  new Emoji("🌲", "Nature", ["evergreen_tree"]),
  new Emoji("🌳", "Nature", ["deciduous_tree"]),
  new Emoji("🌴", "Nature", ["palm_tree"]),
  new Emoji("🌱", "Nature", ["seedling"]),
  new Emoji("🌿", "Nature", ["herb"]),
  new Emoji("☘️", "Nature", ["shamrock"]),
  new Emoji("🍀", "Nature", ["four_leaf_clover"]),
  new Emoji("🎍", "Nature", ["bamboo"]),
  new Emoji("🎋", "Nature", ["tanabata_tree"]),
  new Emoji("🍃", "Nature", ["leaves"]),
  new Emoji("🍂", "Nature", ["fallen_leaf"]),
  new Emoji("🍁", "Nature", ["maple_leaf"]),
  new Emoji("🍄", "Nature", ["mushroom"]),
  new Emoji("🌾", "Nature", ["ear_of_rice"]),
  new Emoji("💐", "Nature", ["bouquet"]),
  new Emoji("🌷", "Nature", ["tulip"]),
  new Emoji("🌹", "Nature", ["rose"]),
  new Emoji("🥀", "Nature", ["wilted_flower"]),
  new Emoji("🌻", "Nature", ["sunflower"]),
  new Emoji("🌼", "Nature", ["blossom"]),
  new Emoji("🌸", "Nature", ["cherry_blossom"]),
  new Emoji("🌺", "Nature", ["hibiscus"]),
  new Emoji("🌎", "Nature", ["earth_americas"]),
  new Emoji("🌍", "Nature", ["earth_africa"]),
  new Emoji("🌏", "Nature", ["earth_asia"]),
  new Emoji("🌕", "Nature", ["full_moon"]),
  new Emoji("🌖", "Nature", ["waning_gibbous_moon"]),
  new Emoji("🌗", "Nature", ["last_quarter_moon"]),
  new Emoji("🌘", "Nature", ["waning_crescent_moon"]),
  new Emoji("🌑", "Nature", ["new_moon"]),
  new Emoji("🌒", "Nature", ["waxing_crescent_moon"]),
  new Emoji("🌓", "Nature", ["first_quarter_moon"]),
  new Emoji("🌔", "Nature", ["moon", "waxing_gibbous_moon"]),
  new Emoji("🌚", "Nature", ["new_moon_with_face"]),
  new Emoji("🌝", "Nature", ["full_moon_with_face"]),
  new Emoji("🌞", "Nature", ["sun_with_face"]),
  new Emoji("🌛", "Nature", ["first_quarter_moon_with_face"]),
  new Emoji("🌜", "Nature", ["last_quarter_moon_with_face"]),
  new Emoji("🌙", "Nature", ["crescent_moon"]),
  new Emoji("💫", "Nature", ["dizzy"]),
  new Emoji("⭐️", "Nature", ["star"]),
  new Emoji("🌟", "Nature", ["star2"]),
  new Emoji("✨", "Nature", ["sparkles"]),
  new Emoji("⚡️", "Nature", ["zap"]),
  new Emoji("🔥", "Nature", ["fire"]),
  new Emoji("💥", "Nature", ["boom", "collision"]),
  new Emoji("☄", "Nature", ["comet"]),
  new Emoji("☀️", "Nature", ["sunny"]),
  new Emoji("🌤", "Nature", ["sun_behind_small_cloud"]),
  new Emoji("⛅️", "Nature", ["partly_sunny"]),
  new Emoji("🌥", "Nature", ["sun_behind_large_cloud"]),
  new Emoji("🌦", "Nature", ["sun_behind_rain_cloud"]),
  new Emoji("🌈", "Nature", ["rainbow"]),
  new Emoji("☁️", "Nature", ["cloud"]),
  new Emoji("🌧", "Nature", ["cloud_with_rain"]),
  new Emoji("⛈", "Nature", ["cloud_with_lightning_and_rain"]),
  new Emoji("🌩", "Nature", ["cloud_with_lightning"]),
  new Emoji("🌨", "Nature", ["cloud_with_snow"]),
  new Emoji("☃️", "Nature", ["snowman_with_snow"]),
  new Emoji("⛄️", "Nature", ["snowman"]),
  new Emoji("❄️", "Nature", ["snowflake"]),
  new Emoji("🌬", "Nature", ["wind_face"]),
  new Emoji("💨", "Nature", ["dash"]),
  new Emoji("🌪", "Nature", ["tornado"]),
  new Emoji("🌫", "Nature", ["fog"]),
  new Emoji("🌊", "Nature", ["ocean"]),
  new Emoji("💧", "Nature", ["droplet"]),
  new Emoji("💦", "Nature", ["sweat_drops"]),
  new Emoji("☔️", "Nature", ["umbrella"]),
  new Emoji("🍏", "Foods", ["green_apple"]),
  new Emoji("🍎", "Foods", ["apple"]),
  new Emoji("🍐", "Foods", ["pear"]),
  new Emoji("🍊", "Foods", ["tangerine", "orange", "mandarin"]),
  new Emoji("🍋", "Foods", ["lemon"]),
  new Emoji("🍌", "Foods", ["banana"]),
  new Emoji("🍉", "Foods", ["watermelon"]),
  new Emoji("🍇", "Foods", ["grapes"]),
  new Emoji("🍓", "Foods", ["strawberry"]),
  new Emoji("🍈", "Foods", ["melon"]),
  new Emoji("🍒", "Foods", ["cherries"]),
  new Emoji("🍑", "Foods", ["peach"]),
  new Emoji("🍍", "Foods", ["pineapple"]),
  new Emoji("🥝", "Foods", ["kiwi_fruit"]),
  new Emoji("🥑", "Foods", ["avocado"]),
  new Emoji("🍅", "Foods", ["tomato"]),
  new Emoji("🍆", "Foods", ["eggplant"]),
  new Emoji("🥒", "Foods", ["cucumber"]),
  new Emoji("🥕", "Foods", ["carrot"]),
  new Emoji("🌽", "Foods", ["corn"]),
  new Emoji("🌶", "Foods", ["hot_pepper"]),
  new Emoji("🥔", "Foods", ["potato"]),
  new Emoji("🍠", "Foods", ["sweet_potato"]),
  new Emoji("🌰", "Foods", ["chestnut"]),
  new Emoji("🥜", "Foods", ["peanuts"]),
  new Emoji("🍯", "Foods", ["honey_pot"]),
  new Emoji("🥐", "Foods", ["croissant"]),
  new Emoji("🍞", "Foods", ["bread"]),
  new Emoji("🥖", "Foods", ["baguette_bread"]),
  new Emoji("🧀", "Foods", ["cheese"]),
  new Emoji("🥚", "Foods", ["egg"]),
  new Emoji("🍳", "Foods", ["fried_egg"]),
  new Emoji("🥓", "Foods", ["bacon"]),
  new Emoji("🥞", "Foods", ["pancakes"]),
  new Emoji("🍤", "Foods", ["fried_shrimp"]),
  new Emoji("🍗", "Foods", ["poultry_leg"]),
  new Emoji("🍖", "Foods", ["meat_on_bone"]),
  new Emoji("🍕", "Foods", ["pizza"]),
  new Emoji("🌭", "Foods", ["hotdog"]),
  new Emoji("🍔", "Foods", ["hamburger"]),
  new Emoji("🍟", "Foods", ["fries"]),
  new Emoji("🥙", "Foods", ["stuffed_flatbread"]),
  new Emoji("🌮", "Foods", ["taco"]),
  new Emoji("🌯", "Foods", ["burrito"]),
  new Emoji("🥗", "Foods", ["green_salad"]),
  new Emoji("🥘", "Foods", ["shallow_pan_of_food"]),
  new Emoji("🍝", "Foods", ["spaghetti"]),
  new Emoji("🍜", "Foods", ["ramen"]),
  new Emoji("🍲", "Foods", ["stew"]),
  new Emoji("🍥", "Foods", ["fish_cake"]),
  new Emoji("🍣", "Foods", ["sushi"]),
  new Emoji("🍱", "Foods", ["bento"]),
  new Emoji("🍛", "Foods", ["curry"]),
  new Emoji("🍚", "Foods", ["rice"]),
  new Emoji("🍙", "Foods", ["rice_ball"]),
  new Emoji("🍘", "Foods", ["rice_cracker"]),
  new Emoji("🍢", "Foods", ["oden"]),
  new Emoji("🍡", "Foods", ["dango"]),
  new Emoji("🍧", "Foods", ["shaved_ice"]),
  new Emoji("🍨", "Foods", ["ice_cream"]),
  new Emoji("🍦", "Foods", ["icecream"]),
  new Emoji("🍰", "Foods", ["cake"]),
  new Emoji("🎂", "Foods", ["birthday"]),
  new Emoji("🍮", "Foods", ["custard"]),
  new Emoji("🍭", "Foods", ["lollipop"]),
  new Emoji("🍬", "Foods", ["candy"]),
  new Emoji("🍫", "Foods", ["chocolate_bar"]),
  new Emoji("🍿", "Foods", ["popcorn"]),
  new Emoji("🍩", "Foods", ["doughnut"]),
  new Emoji("🍪", "Foods", ["cookie"]),
  new Emoji("🥛", "Foods", ["milk_glass"]),
  new Emoji("🍼", "Foods", ["baby_bottle"]),
  new Emoji("☕️", "Foods", ["coffee"]),
  new Emoji("🍵", "Foods", ["tea"]),
  new Emoji("🍶", "Foods", ["sake"]),
  new Emoji("🍺", "Foods", ["beer"]),
  new Emoji("🍻", "Foods", ["beers"]),
  new Emoji("🥂", "Foods", ["clinking_glasses"]),
  new Emoji("🍷", "Foods", ["wine_glass"]),
  new Emoji("🥃", "Foods", ["tumbler_glass"]),
  new Emoji("🍸", "Foods", ["cocktail"]),
  new Emoji("🍹", "Foods", ["tropical_drink"]),
  new Emoji("🍾", "Foods", ["champagne"]),
  new Emoji("🥄", "Foods", ["spoon"]),
  new Emoji("🍴", "Foods", ["fork_and_knife"]),
  new Emoji("🍽", "Foods", ["plate_with_cutlery"]),
  new Emoji("⚽️", "Activity", ["soccer"]),
  new Emoji("🏀", "Activity", ["basketball"]),
  new Emoji("🏈", "Activity", ["football"]),
  new Emoji("⚾️", "Activity", ["baseball"]),
  new Emoji("🎾", "Activity", ["tennis"]),
  new Emoji("🏐", "Activity", ["volleyball"]),
  new Emoji("🏉", "Activity", ["rugby_football"]),
  new Emoji("🎱", "Activity", ["8ball"]),
  new Emoji("🏓", "Activity", ["ping_pong"]),
  new Emoji("🏸", "Activity", ["badminton"]),
  new Emoji("🥅", "Activity", ["goal_net"]),
  new Emoji("🏒", "Activity", ["ice_hockey"]),
  new Emoji("🏑", "Activity", ["field_hockey"]),
  new Emoji("🏏", "Activity", ["cricket"]),
  new Emoji("⛳️", "Activity", ["golf"]),
  new Emoji("🏹", "Activity", ["bow_and_arrow"]),
  new Emoji("🎣", "Activity", ["fishing_pole_and_fish"]),
  new Emoji("🥊", "Activity", ["boxing_glove"]),
  new Emoji("🥋", "Activity", ["martial_arts_uniform"]),
  new Emoji("⛸", "Activity", ["ice_skate"]),
  new Emoji("🎿", "Activity", ["ski"]),
  new Emoji("⛷", "Activity", ["skier"]),
  new Emoji("🏂", "Activity", ["snowboarder"]),
  new Emoji("🏋️‍♀️", "Activity", ["weight_lifting_woman"]),
  new Emoji("🏋", "Activity", ["weight_lifting_man"]),
  new Emoji("🤺", "Activity", ["person_fencing"]),
  new Emoji("🤼‍♀", "Activity", ["women_wrestling"]),
  new Emoji("🤼‍♂", "Activity", ["men_wrestling"]),
  new Emoji("🤸‍♀", "Activity", ["woman_cartwheeling"]),
  new Emoji("🤸‍♂", "Activity", ["man_cartwheeling"]),
  new Emoji("⛹️‍♀️", "Activity", ["basketball_woman"]),
  new Emoji("⛹", "Activity", ["basketball_man"]),
  new Emoji("🤾‍♀", "Activity", ["woman_playing_handball"]),
  new Emoji("🤾‍♂", "Activity", ["man_playing_handball"]),
  new Emoji("🏌️‍♀️", "Activity", ["golfing_woman"]),
  new Emoji("🏌", "Activity", ["golfing_man"]),
  new Emoji("🏄‍♀", "Activity", ["surfing_woman"]),
  new Emoji("🏄", "Activity", ["surfing_man", "surfer"]),
  new Emoji("🏊‍♀", "Activity", ["swimming_woman"]),
  new Emoji("🏊", "Activity", ["swimming_man", "swimmer"]),
  new Emoji("🤽‍♀", "Activity", ["woman_playing_water_polo"]),
  new Emoji("🤽‍♂", "Activity", ["man_playing_water_polo"]),
  new Emoji("🚣‍♀", "Activity", ["rowing_woman"]),
  new Emoji("🚣", "Activity", ["rowing_man", "rowboat"]),
  new Emoji("🏇", "Activity", ["horse_racing"]),
  new Emoji("🚴‍♀", "Activity", ["biking_woman"]),
  new Emoji("🚴", "Activity", ["biking_man", "bicyclist"]),
  new Emoji("🚵‍♀", "Activity", ["mountain_biking_woman"]),
  new Emoji("🚵", "Activity", ["mountain_biking_man", "mountain_bicyclist"]),
  new Emoji("🎽", "Activity", ["running_shirt_with_sash"]),
  new Emoji("🏅", "Activity", ["medal_sports"]),
  new Emoji("🎖", "Activity", ["medal_military"]),
  new Emoji("🥇", "Activity", ["1st_place_medal"]),
  new Emoji("🥈", "Activity", ["2nd_place_medal"]),
  new Emoji("🥉", "Activity", ["3rd_place_medal"]),
  new Emoji("🏆", "Activity", ["trophy"]),
  new Emoji("🏵", "Activity", ["rosette"]),
  new Emoji("🎗", "Activity", ["reminder_ribbon"]),
  new Emoji("🎫", "Activity", ["ticket"]),
  new Emoji("🎟", "Activity", ["tickets"]),
  new Emoji("🎪", "Activity", ["circus_tent"]),
  new Emoji("🤹‍♀", "Activity", ["woman_juggling"]),
  new Emoji("🤹‍♂", "Activity", ["man_juggling"]),
  new Emoji("🎭", "Activity", ["performing_arts"]),
  new Emoji("🎨", "Activity", ["art"]),
  new Emoji("🎬", "Activity", ["clapper"]),
  new Emoji("🎤", "Activity", ["microphone"]),
  new Emoji("🎧", "Activity", ["headphones"]),
  new Emoji("🎼", "Activity", ["musical_score"]),
  new Emoji("🎹", "Activity", ["musical_keyboard"]),
  new Emoji("🥁", "Activity", ["drum"]),
  new Emoji("🎷", "Activity", ["saxophone"]),
  new Emoji("🎺", "Activity", ["trumpet"]),
  new Emoji("🎸", "Activity", ["guitar"]),
  new Emoji("🎻", "Activity", ["violin"]),
  new Emoji("🎲", "Activity", ["game_die"]),
  new Emoji("🎯", "Activity", ["dart"]),
  new Emoji("🎳", "Activity", ["bowling"]),
  new Emoji("🎮", "Activity", ["video_game"]),
  new Emoji("🎰", "Activity", ["slot_machine"]),
  new Emoji("🚗", "Places", ["car", "red_car"]),
  new Emoji("🚕", "Places", ["taxi"]),
  new Emoji("🚙", "Places", ["blue_car"]),
  new Emoji("🚌", "Places", ["bus"]),
  new Emoji("🚎", "Places", ["trolleybus"]),
  new Emoji("🏎", "Places", ["racing_car"]),
  new Emoji("🚓", "Places", ["police_car"]),
  new Emoji("🚑", "Places", ["ambulance"]),
  new Emoji("🚒", "Places", ["fire_engine"]),
  new Emoji("🚐", "Places", ["minibus"]),
  new Emoji("🚚", "Places", ["truck"]),
  new Emoji("🚛", "Places", ["articulated_lorry"]),
  new Emoji("🚜", "Places", ["tractor"]),
  new Emoji("🛴", "Places", ["kick_scooter"]),
  new Emoji("🚲", "Places", ["bike"]),
  new Emoji("🛵", "Places", ["motor_scooter"]),
  new Emoji("🏍", "Places", ["motorcycle"]),
  new Emoji("🚨", "Places", ["rotating_light"]),
  new Emoji("🚔", "Places", ["oncoming_police_car"]),
  new Emoji("🚍", "Places", ["oncoming_bus"]),
  new Emoji("🚘", "Places", ["oncoming_automobile"]),
  new Emoji("🚖", "Places", ["oncoming_taxi"]),
  new Emoji("🚡", "Places", ["aerial_tramway"]),
  new Emoji("🚠", "Places", ["mountain_cableway"]),
  new Emoji("🚟", "Places", ["suspension_railway"]),
  new Emoji("🚃", "Places", ["railway_car"]),
  new Emoji("🚋", "Places", ["train"]),
  new Emoji("🚞", "Places", ["mountain_railway"]),
  new Emoji("🚝", "Places", ["monorail"]),
  new Emoji("🚄", "Places", ["bullettrain_side"]),
  new Emoji("🚅", "Places", ["bullettrain_front"]),
  new Emoji("🚈", "Places", ["light_rail"]),
  new Emoji("🚂", "Places", ["steam_locomotive"]),
  new Emoji("🚆", "Places", ["train2"]),
  new Emoji("🚇", "Places", ["metro"]),
  new Emoji("🚊", "Places", ["tram"]),
  new Emoji("🚉", "Places", ["station"]),
  new Emoji("🚁", "Places", ["helicopter"]),
  new Emoji("🛩", "Places", ["small_airplane"]),
  new Emoji("✈️", "Places", ["airplane"]),
  new Emoji("🛫", "Places", ["flight_departure"]),
  new Emoji("🛬", "Places", ["flight_arrival"]),
  new Emoji("🚀", "Places", ["rocket"]),
  new Emoji("🛰", "Places", ["artificial_satellite"]),
  new Emoji("💺", "Places", ["seat"]),
  new Emoji("🛶", "Places", ["canoe"]),
  new Emoji("⛵️", "Places", ["boat", "sailboat"]),
  new Emoji("🛥", "Places", ["motor_boat"]),
  new Emoji("🚤", "Places", ["speedboat"]),
  new Emoji("🛳", "Places", ["passenger_ship"]),
  new Emoji("⛴", "Places", ["ferry"]),
  new Emoji("🚢", "Places", ["ship"]),
  new Emoji("⚓️", "Places", ["anchor"]),
  new Emoji("🚧", "Places", ["construction"]),
  new Emoji("⛽️", "Places", ["fuelpump"]),
  new Emoji("🚏", "Places", ["busstop"]),
  new Emoji("🚦", "Places", ["vertical_traffic_light"]),
  new Emoji("🚥", "Places", ["traffic_light"]),
  new Emoji("🗺", "Places", ["world_map"]),
  new Emoji("🗿", "Places", ["moyai"]),
  new Emoji("🗽", "Places", ["statue_of_liberty"]),
  new Emoji("⛲️", "Places", ["fountain"]),
  new Emoji("🗼", "Places", ["tokyo_tower"]),
  new Emoji("🏰", "Places", ["european_castle"]),
  new Emoji("🏯", "Places", ["japanese_castle"]),
  new Emoji("🏟", "Places", ["stadium"]),
  new Emoji("🎡", "Places", ["ferris_wheel"]),
  new Emoji("🎢", "Places", ["roller_coaster"]),
  new Emoji("🎠", "Places", ["carousel_horse"]),
  new Emoji("⛱", "Places", ["parasol_on_ground"]),
  new Emoji("🏖", "Places", ["beach_umbrella"]),
  new Emoji("🏝", "Places", ["desert_island"]),
  new Emoji("⛰", "Places", ["mountain"]),
  new Emoji("🏔", "Places", ["mountain_snow"]),
  new Emoji("🗻", "Places", ["mount_fuji"]),
  new Emoji("🌋", "Places", ["volcano"]),
  new Emoji("🏜", "Places", ["desert"]),
  new Emoji("🏕", "Places", ["camping"]),
  new Emoji("⛺️", "Places", ["tent"]),
  new Emoji("🛤", "Places", ["railway_track"]),
  new Emoji("🛣", "Places", ["motorway"]),
  new Emoji("🏗", "Places", ["building_construction"]),
  new Emoji("🏭", "Places", ["factory"]),
  new Emoji("🏠", "Places", ["house"]),
  new Emoji("🏡", "Places", ["house_with_garden"]),
  new Emoji("🏘", "Places", ["houses"]),
  new Emoji("🏚", "Places", ["derelict_house"]),
  new Emoji("🏢", "Places", ["office"]),
  new Emoji("🏬", "Places", ["department_store"]),
  new Emoji("🏣", "Places", ["post_office"]),
  new Emoji("🏤", "Places", ["european_post_office"]),
  new Emoji("🏥", "Places", ["hospital"]),
  new Emoji("🏦", "Places", ["bank"]),
  new Emoji("🏨", "Places", ["hotel"]),
  new Emoji("🏪", "Places", ["convenience_store"]),
  new Emoji("🏫", "Places", ["school"]),
  new Emoji("🏩", "Places", ["love_hotel"]),
  new Emoji("💒", "Places", ["wedding"]),
  new Emoji("🏛", "Places", ["classical_building"]),
  new Emoji("⛪️", "Places", ["church"]),
  new Emoji("🕌", "Places", ["mosque"]),
  new Emoji("🕍", "Places", ["synagogue"]),
  new Emoji("🕋", "Places", ["kaaba"]),
  new Emoji("⛩", "Places", ["shinto_shrine"]),
  new Emoji("🗾", "Places", ["japan"]),
  new Emoji("🎑", "Places", ["rice_scene"]),
  new Emoji("🏞", "Places", ["national_park"]),
  new Emoji("🌅", "Places", ["sunrise"]),
  new Emoji("🌄", "Places", ["sunrise_over_mountains"]),
  new Emoji("🌠", "Places", ["stars"]),
  new Emoji("🎇", "Places", ["sparkler"]),
  new Emoji("🎆", "Places", ["fireworks"]),
  new Emoji("🌇", "Places", ["city_sunrise"]),
  new Emoji("🌆", "Places", ["city_sunset"]),
  new Emoji("🏙", "Places", ["cityscape"]),
  new Emoji("🌃", "Places", ["night_with_stars"]),
  new Emoji("🌌", "Places", ["milky_way"]),
  new Emoji("🌉", "Places", ["bridge_at_night"]),
  new Emoji("🌁", "Places", ["foggy"]),
  new Emoji("⌚️", "Objects", ["watch"]),
  new Emoji("📱", "Objects", ["iphone"]),
  new Emoji("📲", "Objects", ["calling"]),
  new Emoji("💻", "Objects", ["computer"]),
  new Emoji("⌨️", "Objects", ["keyboard"]),
  new Emoji("🖥", "Objects", ["desktop_computer"]),
  new Emoji("🖨", "Objects", ["printer"]),
  new Emoji("🖱", "Objects", ["computer_mouse"]),
  new Emoji("🖲", "Objects", ["trackball"]),
  new Emoji("🕹", "Objects", ["joystick"]),
  new Emoji("🗜", "Objects", ["clamp"]),
  new Emoji("💽", "Objects", ["minidisc"]),
  new Emoji("💾", "Objects", ["floppy_disk"]),
  new Emoji("💿", "Objects", ["cd"]),
  new Emoji("📀", "Objects", ["dvd"]),
  new Emoji("📼", "Objects", ["vhs"]),
  new Emoji("📷", "Objects", ["camera"]),
  new Emoji("📸", "Objects", ["camera_flash"]),
  new Emoji("📹", "Objects", ["video_camera"]),
  new Emoji("🎥", "Objects", ["movie_camera"]),
  new Emoji("📽", "Objects", ["film_projector"]),
  new Emoji("🎞", "Objects", ["film_strip"]),
  new Emoji("📞", "Objects", ["telephone_receiver"]),
  new Emoji("☎️", "Objects", ["phone", "telephone"]),
  new Emoji("📟", "Objects", ["pager"]),
  new Emoji("📠", "Objects", ["fax"]),
  new Emoji("📺", "Objects", ["tv"]),
  new Emoji("📻", "Objects", ["radio"]),
  new Emoji("🎙", "Objects", ["studio_microphone"]),
  new Emoji("🎚", "Objects", ["level_slider"]),
  new Emoji("🎛", "Objects", ["control_knobs"]),
  new Emoji("⏱", "Objects", ["stopwatch"]),
  new Emoji("⏲", "Objects", ["timer_clock"]),
  new Emoji("⏰", "Objects", ["alarm_clock"]),
  new Emoji("🕰", "Objects", ["mantelpiece_clock"]),
  new Emoji("⌛️", "Objects", ["hourglass"]),
  new Emoji("⏳", "Objects", ["hourglass_flowing_sand"]),
  new Emoji("📡", "Objects", ["satellite"]),
  new Emoji("🔋", "Objects", ["battery"]),
  new Emoji("🔌", "Objects", ["electric_plug"]),
  new Emoji("💡", "Objects", ["bulb"]),
  new Emoji("🔦", "Objects", ["flashlight"]),
  new Emoji("🕯", "Objects", ["candle"]),
  new Emoji("🗑", "Objects", ["wastebasket"]),
  new Emoji("🛢", "Objects", ["oil_drum"]),
  new Emoji("💸", "Objects", ["money_with_wings"]),
  new Emoji("💵", "Objects", ["dollar"]),
  new Emoji("💴", "Objects", ["yen"]),
  new Emoji("💶", "Objects", ["euro"]),
  new Emoji("💷", "Objects", ["pound"]),
  new Emoji("💰", "Objects", ["moneybag"]),
  new Emoji("💳", "Objects", ["credit_card"]),
  new Emoji("💎", "Objects", ["gem"]),
  new Emoji("⚖️", "Objects", ["balance_scale"]),
  new Emoji("🔧", "Objects", ["wrench"]),
  new Emoji("🔨", "Objects", ["hammer"]),
  new Emoji("⚒", "Objects", ["hammer_and_pick"]),
  new Emoji("🛠", "Objects", ["hammer_and_wrench"]),
  new Emoji("⛏", "Objects", ["pick"]),
  new Emoji("🔩", "Objects", ["nut_and_bolt"]),
  new Emoji("⚙️", "Objects", ["gear"]),
  new Emoji("⛓", "Objects", ["chains"]),
  new Emoji("🔫", "Objects", ["gun"]),
  new Emoji("💣", "Objects", ["bomb"]),
  new Emoji("🔪", "Objects", ["hocho", "knife"]),
  new Emoji("🗡", "Objects", ["dagger"]),
  new Emoji("⚔️", "Objects", ["crossed_swords"]),
  new Emoji("🛡", "Objects", ["shield"]),
  new Emoji("🚬", "Objects", ["smoking"]),
  new Emoji("⚰️", "Objects", ["coffin"]),
  new Emoji("⚱️", "Objects", ["funeral_urn"]),
  new Emoji("🏺", "Objects", ["amphora"]),
  new Emoji("🔮", "Objects", ["crystal_ball"]),
  new Emoji("📿", "Objects", ["prayer_beads"]),
  new Emoji("💈", "Objects", ["barber"]),
  new Emoji("⚗️", "Objects", ["alembic"]),
  new Emoji("🔭", "Objects", ["telescope"]),
  new Emoji("🔬", "Objects", ["microscope"]),
  new Emoji("🕳", "Objects", ["hole"]),
  new Emoji("💊", "Objects", ["pill"]),
  new Emoji("💉", "Objects", ["syringe"]),
  new Emoji("🌡", "Objects", ["thermometer"]),
  new Emoji("🚽", "Objects", ["toilet"]),
  new Emoji("🚰", "Objects", ["potable_water"]),
  new Emoji("🚿", "Objects", ["shower"]),
  new Emoji("🛁", "Objects", ["bathtub"]),
  new Emoji("🛀", "Objects", ["bath"]),
  new Emoji("🛎", "Objects", ["bellhop_bell"]),
  new Emoji("🔑", "Objects", ["key"]),
  new Emoji("🗝", "Objects", ["old_key"]),
  new Emoji("🚪", "Objects", ["door"]),
  new Emoji("🛋", "Objects", ["couch_and_lamp"]),
  new Emoji("🛏", "Objects", ["bed"]),
  new Emoji("🛌", "Objects", ["sleeping_bed"]),
  new Emoji("🖼", "Objects", ["framed_picture"]),
  new Emoji("🛍", "Objects", ["shopping"]),
  new Emoji("🛒", "Objects", ["shopping_cart"]),
  new Emoji("🎁", "Objects", ["gift"]),
  new Emoji("🎈", "Objects", ["balloon"]),
  new Emoji("🎏", "Objects", ["flags"]),
  new Emoji("🎀", "Objects", ["ribbon"]),
  new Emoji("🎊", "Objects", ["confetti_ball"]),
  new Emoji("🎉", "Objects", ["tada"]),
  new Emoji("🎎", "Objects", ["dolls"]),
  new Emoji("🏮", "Objects", ["izakaya_lantern", "lantern"]),
  new Emoji("🎐", "Objects", ["wind_chime"]),
  new Emoji("✉️", "Objects", ["email", "envelope"]),
  new Emoji("📩", "Objects", ["envelope_with_arrow"]),
  new Emoji("📨", "Objects", ["incoming_envelope"]),
  new Emoji("📧", "Objects", ["e-mail"]),
  new Emoji("💌", "Objects", ["love_letter"]),
  new Emoji("📥", "Objects", ["inbox_tray"]),
  new Emoji("📤", "Objects", ["outbox_tray"]),
  new Emoji("📦", "Objects", ["package"]),
  new Emoji("🏷", "Objects", ["label"]),
  new Emoji("📪", "Objects", ["mailbox_closed"]),
  new Emoji("📫", "Objects", ["mailbox"]),
  new Emoji("📬", "Objects", ["mailbox_with_mail"]),
  new Emoji("📭", "Objects", ["mailbox_with_no_mail"]),
  new Emoji("📮", "Objects", ["postbox"]),
  new Emoji("📯", "Objects", ["postal_horn"]),
  new Emoji("📜", "Objects", ["scroll"]),
  new Emoji("📃", "Objects", ["page_with_curl"]),
  new Emoji("📄", "Objects", ["page_facing_up"]),
  new Emoji("📑", "Objects", ["bookmark_tabs"]),
  new Emoji("📊", "Objects", ["bar_chart"]),
  new Emoji("📈", "Objects", ["chart_with_upwards_trend"]),
  new Emoji("📉", "Objects", ["chart_with_downwards_trend"]),
  new Emoji("🗒", "Objects", ["spiral_notepad"]),
  new Emoji("🗓", "Objects", ["spiral_calendar"]),
  new Emoji("📆", "Objects", ["calendar"]),
  new Emoji("📅", "Objects", ["date"]),
  new Emoji("📇", "Objects", ["card_index"]),
  new Emoji("🗃", "Objects", ["card_file_box"]),
  new Emoji("🗳", "Objects", ["ballot_box"]),
  new Emoji("🗄", "Objects", ["file_cabinet"]),
  new Emoji("📋", "Objects", ["clipboard"]),
  new Emoji("📁", "Objects", ["file_folder"]),
  new Emoji("📂", "Objects", ["open_file_folder"]),
  new Emoji("🗂", "Objects", ["card_index_dividers"]),
  new Emoji("🗞", "Objects", ["newspaper_roll"]),
  new Emoji("📰", "Objects", ["newspaper"]),
  new Emoji("📓", "Objects", ["notebook"]),
  new Emoji("📔", "Objects", ["notebook_with_decorative_cover"]),
  new Emoji("📒", "Objects", ["ledger"]),
  new Emoji("📕", "Objects", ["closed_book"]),
  new Emoji("📗", "Objects", ["green_book"]),
  new Emoji("📘", "Objects", ["blue_book"]),
  new Emoji("📙", "Objects", ["orange_book"]),
  new Emoji("📚", "Objects", ["books"]),
  new Emoji("📖", "Objects", ["book", "open_book"]),
  new Emoji("🔖", "Objects", ["bookmark"]),
  new Emoji("🔗", "Objects", ["link"]),
  new Emoji("📎", "Objects", ["paperclip"]),
  new Emoji("🖇", "Objects", ["paperclips"]),
  new Emoji("📐", "Objects", ["triangular_ruler"]),
  new Emoji("📏", "Objects", ["straight_ruler"]),
  new Emoji("📌", "Objects", ["pushpin"]),
  new Emoji("📍", "Objects", ["round_pushpin"]),
  new Emoji("✂️", "Objects", ["scissors"]),
  new Emoji("🖊", "Objects", ["pen"]),
  new Emoji("🖋", "Objects", ["fountain_pen"]),
  new Emoji("✒️", "Objects", ["black_nib"]),
  new Emoji("🖌", "Objects", ["paintbrush"]),
  new Emoji("🖍", "Objects", ["crayon"]),
  new Emoji("📝", "Objects", ["memo", "pencil"]),
  new Emoji("✏️", "Objects", ["pencil2"]),
  new Emoji("🔍", "Objects", ["mag"]),
  new Emoji("🔎", "Objects", ["mag_right"]),
  new Emoji("🔏", "Objects", ["lock_with_ink_pen"]),
  new Emoji("🔐", "Objects", ["closed_lock_with_key"]),
  new Emoji("🔒", "Objects", ["lock"]),
  new Emoji("🔓", "Objects", ["unlock"]),
  new Emoji("❤️", "Symbols", ["heart"]),
  new Emoji("💛", "Symbols", ["yellow_heart"]),
  new Emoji("💚", "Symbols", ["green_heart"]),
  new Emoji("💙", "Symbols", ["blue_heart"]),
  new Emoji("💜", "Symbols", ["purple_heart"]),
  new Emoji("🖤", "Symbols", ["black_heart"]),
  new Emoji("💔", "Symbols", ["broken_heart"]),
  new Emoji("❣️", "Symbols", ["heavy_heart_exclamation"]),
  new Emoji("💕", "Symbols", ["two_hearts"]),
  new Emoji("💞", "Symbols", ["revolving_hearts"]),
  new Emoji("💓", "Symbols", ["heartbeat"]),
  new Emoji("💗", "Symbols", ["heartpulse"]),
  new Emoji("💖", "Symbols", ["sparkling_heart"]),
  new Emoji("💘", "Symbols", ["cupid"]),
  new Emoji("💝", "Symbols", ["gift_heart"]),
  new Emoji("💟", "Symbols", ["heart_decoration"]),
  new Emoji("☮️", "Symbols", ["peace_symbol"]),
  new Emoji("✝️", "Symbols", ["latin_cross"]),
  new Emoji("☪️", "Symbols", ["star_and_crescent"]),
  new Emoji("🕉", "Symbols", ["om"]),
  new Emoji("☸️", "Symbols", ["wheel_of_dharma"]),
  new Emoji("✡️", "Symbols", ["star_of_david"]),
  new Emoji("🔯", "Symbols", ["six_pointed_star"]),
  new Emoji("🕎", "Symbols", ["menorah"]),
  new Emoji("☯️", "Symbols", ["yin_yang"]),
  new Emoji("☦️", "Symbols", ["orthodox_cross"]),
  new Emoji("🛐", "Symbols", ["place_of_worship"]),
  new Emoji("⛎", "Symbols", ["ophiuchus"]),
  new Emoji("♈️", "Symbols", ["aries"]),
  new Emoji("♉️", "Symbols", ["taurus"]),
  new Emoji("♊️", "Symbols", ["gemini"]),
  new Emoji("♋️", "Symbols", ["cancer"]),
  new Emoji("♌️", "Symbols", ["leo"]),
  new Emoji("♍️", "Symbols", ["virgo"]),
  new Emoji("♎️", "Symbols", ["libra"]),
  new Emoji("♏️", "Symbols", ["scorpius"]),
  new Emoji("♐️", "Symbols", ["sagittarius"]),
  new Emoji("♑️", "Symbols", ["capricorn"]),
  new Emoji("♒️", "Symbols", ["aquarius"]),
  new Emoji("♓️", "Symbols", ["pisces"]),
  new Emoji("🆔", "Symbols", ["id"]),
  new Emoji("⚛️", "Symbols", ["atom_symbol"]),
  new Emoji("🉑", "Symbols", ["accept"]),
  new Emoji("☢️", "Symbols", ["radioactive"]),
  new Emoji("☣️", "Symbols", ["biohazard"]),
  new Emoji("📴", "Symbols", ["mobile_phone_off"]),
  new Emoji("📳", "Symbols", ["vibration_mode"]),
  new Emoji("🈶", "Symbols", ["u6709"]),
  new Emoji("🈚️", "Symbols", ["u7121"]),
  new Emoji("🈸", "Symbols", ["u7533"]),
  new Emoji("🈺", "Symbols", ["u55b6"]),
  new Emoji("🈷️", "Symbols", ["u6708"]),
  new Emoji("✴️", "Symbols", ["eight_pointed_black_star"]),
  new Emoji("🆚", "Symbols", ["vs"]),
  new Emoji("💮", "Symbols", ["white_flower"]),
  new Emoji("🉐", "Symbols", ["ideograph_advantage"]),
  new Emoji("㊙️", "Symbols", ["secret"]),
  new Emoji("㊗️", "Symbols", ["congratulations"]),
  new Emoji("🈴", "Symbols", ["u5408"]),
  new Emoji("🈵", "Symbols", ["u6e80"]),
  new Emoji("🈹", "Symbols", ["u5272"]),
  new Emoji("🈲", "Symbols", ["u7981"]),
  new Emoji("🅰️", "Symbols", ["a"]),
  new Emoji("🅱️", "Symbols", ["b"]),
  new Emoji("🆎", "Symbols", ["ab"]),
  new Emoji("🆑", "Symbols", ["cl"]),
  new Emoji("🅾️", "Symbols", ["o2"]),
  new Emoji("🆘", "Symbols", ["sos"]),
  new Emoji("❌", "Symbols", ["x"]),
  new Emoji("⭕️", "Symbols", ["o"]),
  new Emoji("🛑", "Symbols", ["stop_sign"]),
  new Emoji("⛔️", "Symbols", ["no_entry"]),
  new Emoji("📛", "Symbols", ["name_badge"]),
  new Emoji("🚫", "Symbols", ["no_entry_sign"]),
  new Emoji("💯", "Symbols", ["100"]),
  new Emoji("💢", "Symbols", ["anger"]),
  new Emoji("♨️", "Symbols", ["hotsprings"]),
  new Emoji("🚷", "Symbols", ["no_pedestrians"]),
  new Emoji("🚯", "Symbols", ["do_not_litter"]),
  new Emoji("🚳", "Symbols", ["no_bicycles"]),
  new Emoji("🚱", "Symbols", ["non-potable_water"]),
  new Emoji("🔞", "Symbols", ["underage"]),
  new Emoji("📵", "Symbols", ["no_mobile_phones"]),
  new Emoji("🚭", "Symbols", ["no_smoking"]),
  new Emoji("❗️", "Symbols", ["exclamation", "heavy_exclamation_mark"]),
  new Emoji("❕", "Symbols", ["grey_exclamation"]),
  new Emoji("❓", "Symbols", ["question"]),
  new Emoji("❔", "Symbols", ["grey_question"]),
  new Emoji("‼️", "Symbols", ["bangbang"]),
  new Emoji("⁉️", "Symbols", ["interrobang"]),
  new Emoji("🔅", "Symbols", ["low_brightness"]),
  new Emoji("🔆", "Symbols", ["high_brightness"]),
  new Emoji("〽️", "Symbols", ["part_alternation_mark"]),
  new Emoji("⚠️", "Symbols", ["warning"]),
  new Emoji("🚸", "Symbols", ["children_crossing"]),
  new Emoji("🔱", "Symbols", ["trident"]),
  new Emoji("⚜️", "Symbols", ["fleur_de_lis"]),
  new Emoji("🔰", "Symbols", ["beginner"]),
  new Emoji("♻️", "Symbols", ["recycle"]),
  new Emoji("✅", "Symbols", ["white_check_mark"]),
  new Emoji("🈯️", "Symbols", ["u6307"]),
  new Emoji("💹", "Symbols", ["chart"]),
  new Emoji("❇️", "Symbols", ["sparkle"]),
  new Emoji("✳️", "Symbols", ["eight_spoked_asterisk"]),
  new Emoji("❎", "Symbols", ["negative_squared_cross_mark"]),
  new Emoji("🌐", "Symbols", ["globe_with_meridians"]),
  new Emoji("💠", "Symbols", ["diamond_shape_with_a_dot_inside"]),
  new Emoji("Ⓜ️", "Symbols", ["m"]),
  new Emoji("🌀", "Symbols", ["cyclone"]),
  new Emoji("💤", "Symbols", ["zzz"]),
  new Emoji("🏧", "Symbols", ["atm"]),
  new Emoji("🚾", "Symbols", ["wc"]),
  new Emoji("♿️", "Symbols", ["wheelchair"]),
  new Emoji("🅿️", "Symbols", ["parking"]),
  new Emoji("🈳", "Symbols", ["u7a7a"]),
  new Emoji("🈂️", "Symbols", ["sa"]),
  new Emoji("🛂", "Symbols", ["passport_control"]),
  new Emoji("🛃", "Symbols", ["customs"]),
  new Emoji("🛄", "Symbols", ["baggage_claim"]),
  new Emoji("🛅", "Symbols", ["left_luggage"]),
  new Emoji("🚹", "Symbols", ["mens"]),
  new Emoji("🚺", "Symbols", ["womens"]),
  new Emoji("🚼", "Symbols", ["baby_symbol"]),
  new Emoji("🚻", "Symbols", ["restroom"]),
  new Emoji("🚮", "Symbols", ["put_litter_in_its_place"]),
  new Emoji("🎦", "Symbols", ["cinema"]),
  new Emoji("📶", "Symbols", ["signal_strength"]),
  new Emoji("🈁", "Symbols", ["koko"]),
  new Emoji("🔣", "Symbols", ["symbols"]),
  new Emoji("ℹ️", "Symbols", ["information_source"]),
  new Emoji("🔤", "Symbols", ["abc"]),
  new Emoji("🔡", "Symbols", ["abcd"]),
  new Emoji("🔠", "Symbols", ["capital_abcd"]),
  new Emoji("🆖", "Symbols", ["ng"]),
  new Emoji("🆗", "Symbols", ["ok"]),
  new Emoji("🆙", "Symbols", ["up"]),
  new Emoji("🆒", "Symbols", ["cool"]),
  new Emoji("🆕", "Symbols", ["new"]),
  new Emoji("🆓", "Symbols", ["free"]),
  new Emoji("0️⃣", "Symbols", ["zero"]),
  new Emoji("1️⃣", "Symbols", ["one"]),
  new Emoji("2️⃣", "Symbols", ["two"]),
  new Emoji("3️⃣", "Symbols", ["three"]),
  new Emoji("4️⃣", "Symbols", ["four"]),
  new Emoji("5️⃣", "Symbols", ["five"]),
  new Emoji("6️⃣", "Symbols", ["six"]),
  new Emoji("7️⃣", "Symbols", ["seven"]),
  new Emoji("8️⃣", "Symbols", ["eight"]),
  new Emoji("9️⃣", "Symbols", ["nine"]),
  new Emoji("🔟", "Symbols", ["keycap_ten"]),
  new Emoji("🔢", "Symbols", ["1234"]),
  new Emoji("#️⃣", "Symbols", ["hash"]),
  new Emoji("*️⃣", "Symbols", ["asterisk"]),
  new Emoji("▶️", "Symbols", ["arrow_forward"]),
  new Emoji("⏸", "Symbols", ["pause_button"]),
  new Emoji("⏯", "Symbols", ["play_or_pause_button"]),
  new Emoji("⏹", "Symbols", ["stop_button"]),
  new Emoji("⏺", "Symbols", ["record_button"]),
  new Emoji("⏭", "Symbols", ["next_track_button"]),
  new Emoji("⏮", "Symbols", ["previous_track_button"]),
  new Emoji("⏩", "Symbols", ["fast_forward"]),
  new Emoji("⏪", "Symbols", ["rewind"]),
  new Emoji("⏫", "Symbols", ["arrow_double_up"]),
  new Emoji("⏬", "Symbols", ["arrow_double_down"]),
  new Emoji("◀️", "Symbols", ["arrow_backward"]),
  new Emoji("🔼", "Symbols", ["arrow_up_small"]),
  new Emoji("🔽", "Symbols", ["arrow_down_small"]),
  new Emoji("➡️", "Symbols", ["arrow_right"]),
  new Emoji("⬅️", "Symbols", ["arrow_left"]),
  new Emoji("⬆️", "Symbols", ["arrow_up"]),
  new Emoji("⬇️", "Symbols", ["arrow_down"]),
  new Emoji("↗️", "Symbols", ["arrow_upper_right"]),
  new Emoji("↘️", "Symbols", ["arrow_lower_right"]),
  new Emoji("↙️", "Symbols", ["arrow_lower_left"]),
  new Emoji("↖️", "Symbols", ["arrow_upper_left"]),
  new Emoji("↕️", "Symbols", ["arrow_up_down"]),
  new Emoji("↔️", "Symbols", ["left_right_arrow"]),
  new Emoji("↪️", "Symbols", ["arrow_right_hook"]),
  new Emoji("↩️", "Symbols", ["leftwards_arrow_with_hook"]),
  new Emoji("⤴️", "Symbols", ["arrow_heading_up"]),
  new Emoji("⤵️", "Symbols", ["arrow_heading_down"]),
  new Emoji("🔀", "Symbols", ["twisted_rightwards_arrows"]),
  new Emoji("🔁", "Symbols", ["repeat"]),
  new Emoji("🔂", "Symbols", ["repeat_one"]),
  new Emoji("🔄", "Symbols", ["arrows_counterclockwise"]),
  new Emoji("🔃", "Symbols", ["arrows_clockwise"]),
  new Emoji("🎵", "Symbols", ["musical_note"]),
  new Emoji("🎶", "Symbols", ["notes"]),
  new Emoji("➕", "Symbols", ["heavy_plus_sign"]),
  new Emoji("➖", "Symbols", ["heavy_minus_sign"]),
  new Emoji("➗", "Symbols", ["heavy_division_sign"]),
  new Emoji("✖️", "Symbols", ["heavy_multiplication_x"]),
  new Emoji("💲", "Symbols", ["heavy_dollar_sign"]),
  new Emoji("💱", "Symbols", ["currency_exchange"]),
  new Emoji("™️", "Symbols", ["tm"]),
  new Emoji("©️", "Symbols", ["copyright"]),
  new Emoji("®️", "Symbols", ["registered"]),
  new Emoji("〰️", "Symbols", ["wavy_dash"]),
  new Emoji("➰", "Symbols", ["curly_loop"]),
  new Emoji("➿", "Symbols", ["loop"]),
  new Emoji("🔚", "Symbols", ["end"]),
  new Emoji("🔙", "Symbols", ["back"]),
  new Emoji("🔛", "Symbols", ["on"]),
  new Emoji("🔝", "Symbols", ["top"]),
  new Emoji("🔜", "Symbols", ["soon"]),
  new Emoji("✔️", "Symbols", ["heavy_check_mark"]),
  new Emoji("☑️", "Symbols", ["ballot_box_with_check"]),
  new Emoji("🔘", "Symbols", ["radio_button"]),
  new Emoji("⚪️", "Symbols", ["white_circle"]),
  new Emoji("⚫️", "Symbols", ["black_circle"]),
  new Emoji("🔴", "Symbols", ["red_circle"]),
  new Emoji("🔵", "Symbols", ["large_blue_circle"]),
  new Emoji("🔺", "Symbols", ["small_red_triangle"]),
  new Emoji("🔻", "Symbols", ["small_red_triangle_down"]),
  new Emoji("🔸", "Symbols", ["small_orange_diamond"]),
  new Emoji("🔹", "Symbols", ["small_blue_diamond"]),
  new Emoji("🔶", "Symbols", ["large_orange_diamond"]),
  new Emoji("🔷", "Symbols", ["large_blue_diamond"]),
  new Emoji("🔳", "Symbols", ["white_square_button"]),
  new Emoji("🔲", "Symbols", ["black_square_button"]),
  new Emoji("▪️", "Symbols", ["black_small_square"]),
  new Emoji("▫️", "Symbols", ["white_small_square"]),
  new Emoji("◾️", "Symbols", ["black_medium_small_square"]),
  new Emoji("◽️", "Symbols", ["white_medium_small_square"]),
  new Emoji("◼️", "Symbols", ["black_medium_square"]),
  new Emoji("◻️", "Symbols", ["white_medium_square"]),
  new Emoji("⬛️", "Symbols", ["black_large_square"]),
  new Emoji("⬜️", "Symbols", ["white_large_square"]),
  new Emoji("🔈", "Symbols", ["speaker"]),
  new Emoji("🔇", "Symbols", ["mute"]),
  new Emoji("🔉", "Symbols", ["sound"]),
  new Emoji("🔊", "Symbols", ["loud_sound"]),
  new Emoji("🔔", "Symbols", ["bell"]),
  new Emoji("🔕", "Symbols", ["no_bell"]),
  new Emoji("📣", "Symbols", ["mega"]),
  new Emoji("📢", "Symbols", ["loudspeaker"]),
  new Emoji("👁‍🗨", "Symbols", ["eye_speech_bubble"]),
  new Emoji("💬", "Symbols", ["speech_balloon"]),
  new Emoji("💭", "Symbols", ["thought_balloon"]),
  new Emoji("🗯", "Symbols", ["right_anger_bubble"]),
  new Emoji("♠️", "Symbols", ["spades"]),
  new Emoji("♣️", "Symbols", ["clubs"]),
  new Emoji("♥️", "Symbols", ["hearts"]),
  new Emoji("♦️", "Symbols", ["diamonds"]),
  new Emoji("🃏", "Symbols", ["black_joker"]),
  new Emoji("🎴", "Symbols", ["flower_playing_cards"]),
  new Emoji("🀄️", "Symbols", ["mahjong"]),
  new Emoji("🕐", "Symbols", ["clock1"]),
  new Emoji("🕑", "Symbols", ["clock2"]),
  new Emoji("🕒", "Symbols", ["clock3"]),
  new Emoji("🕓", "Symbols", ["clock4"]),
  new Emoji("🕔", "Symbols", ["clock5"]),
  new Emoji("🕕", "Symbols", ["clock6"]),
  new Emoji("🕖", "Symbols", ["clock7"]),
  new Emoji("🕗", "Symbols", ["clock8"]),
  new Emoji("🕘", "Symbols", ["clock9"]),
  new Emoji("🕙", "Symbols", ["clock10"]),
  new Emoji("🕚", "Symbols", ["clock11"]),
  new Emoji("🕛", "Symbols", ["clock12"]),
  new Emoji("🕜", "Symbols", ["clock130"]),
  new Emoji("🕝", "Symbols", ["clock230"]),
  new Emoji("🕞", "Symbols", ["clock330"]),
  new Emoji("🕟", "Symbols", ["clock430"]),
  new Emoji("🕠", "Symbols", ["clock530"]),
  new Emoji("🕡", "Symbols", ["clock630"]),
  new Emoji("🕢", "Symbols", ["clock730"]),
  new Emoji("🕣", "Symbols", ["clock830"]),
  new Emoji("🕤", "Symbols", ["clock930"]),
  new Emoji("🕥", "Symbols", ["clock1030"]),
  new Emoji("🕦", "Symbols", ["clock1130"]),
  new Emoji("🕧", "Symbols", ["clock1230"]),
  new Emoji("🏳️", "Flags", ["white_flag"]),
  new Emoji("🏴", "Flags", ["black_flag"]),
  new Emoji("🏁", "Flags", ["checkered_flag"]),
  new Emoji("🚩", "Flags", ["triangular_flag_on_post"]),
  new Emoji("🏳️‍🌈", "Flags", ["rainbow_flag"]),
  new Emoji("🇦🇫", "Flags", ["afghanistan"]),
  new Emoji("🇦🇽", "Flags", ["aland_islands"]),
  new Emoji("🇦🇱", "Flags", ["albania"]),
  new Emoji("🇩🇿", "Flags", ["algeria"]),
  new Emoji("🇦🇸", "Flags", ["american_samoa"]),
  new Emoji("🇦🇩", "Flags", ["andorra"]),
  new Emoji("🇦🇴", "Flags", ["angola"]),
  new Emoji("🇦🇮", "Flags", ["anguilla"]),
  new Emoji("🇦🇶", "Flags", ["antarctica"]),
  new Emoji("🇦🇬", "Flags", ["antigua_barbuda"]),
  new Emoji("🇦🇷", "Flags", ["argentina"]),
  new Emoji("🇦🇲", "Flags", ["armenia"]),
  new Emoji("🇦🇼", "Flags", ["aruba"]),
  new Emoji("🇦🇺", "Flags", ["australia"]),
  new Emoji("🇦🇹", "Flags", ["austria"]),
  new Emoji("🇦🇿", "Flags", ["azerbaijan"]),
  new Emoji("🇧🇸", "Flags", ["bahamas"]),
  new Emoji("🇧🇭", "Flags", ["bahrain"]),
  new Emoji("🇧🇩", "Flags", ["bangladesh"]),
  new Emoji("🇧🇧", "Flags", ["barbados"]),
  new Emoji("🇧🇾", "Flags", ["belarus"]),
  new Emoji("🇧🇪", "Flags", ["belgium"]),
  new Emoji("🇧🇿", "Flags", ["belize"]),
  new Emoji("🇧🇯", "Flags", ["benin"]),
  new Emoji("🇧🇲", "Flags", ["bermuda"]),
  new Emoji("🇧🇹", "Flags", ["bhutan"]),
  new Emoji("🇧🇴", "Flags", ["bolivia"]),
  new Emoji("🇧🇶", "Flags", ["caribbean_netherlands"]),
  new Emoji("🇧🇦", "Flags", ["bosnia_herzegovina"]),
  new Emoji("🇧🇼", "Flags", ["botswana"]),
  new Emoji("🇧🇷", "Flags", ["brazil"]),
  new Emoji("🇮🇴", "Flags", ["british_indian_ocean_territory"]),
  new Emoji("🇻🇬", "Flags", ["british_virgin_islands"]),
  new Emoji("🇧🇳", "Flags", ["brunei"]),
  new Emoji("🇧🇬", "Flags", ["bulgaria"]),
  new Emoji("🇧🇫", "Flags", ["burkina_faso"]),
  new Emoji("🇧🇮", "Flags", ["burundi"]),
  new Emoji("🇨🇻", "Flags", ["cape_verde"]),
  new Emoji("🇰🇭", "Flags", ["cambodia"]),
  new Emoji("🇨🇲", "Flags", ["cameroon"]),
  new Emoji("🇨🇦", "Flags", ["canada"]),
  new Emoji("🇮🇨", "Flags", ["canary_islands"]),
  new Emoji("🇰🇾", "Flags", ["cayman_islands"]),
  new Emoji("🇨🇫", "Flags", ["central_african_republic"]),
  new Emoji("🇹🇩", "Flags", ["chad"]),
  new Emoji("🇨🇱", "Flags", ["chile"]),
  new Emoji("🇨🇳", "Flags", ["cn"]),
  new Emoji("🇨🇽", "Flags", ["christmas_island"]),
  new Emoji("🇨🇨", "Flags", ["cocos_islands"]),
  new Emoji("🇨🇴", "Flags", ["colombia"]),
  new Emoji("🇰🇲", "Flags", ["comoros"]),
  new Emoji("🇨🇬", "Flags", ["congo_brazzaville"]),
  new Emoji("🇨🇩", "Flags", ["congo_kinshasa"]),
  new Emoji("🇨🇰", "Flags", ["cook_islands"]),
  new Emoji("🇨🇷", "Flags", ["costa_rica"]),
  new Emoji("🇨🇮", "Flags", ["cote_divoire"]),
  new Emoji("🇭🇷", "Flags", ["croatia"]),
  new Emoji("🇨🇺", "Flags", ["cuba"]),
  new Emoji("🇨🇼", "Flags", ["curacao"]),
  new Emoji("🇨🇾", "Flags", ["cyprus"]),
  new Emoji("🇨🇿", "Flags", ["czech_republic"]),
  new Emoji("🇩🇰", "Flags", ["denmark"]),
  new Emoji("🇩🇯", "Flags", ["djibouti"]),
  new Emoji("🇩🇲", "Flags", ["dominica"]),
  new Emoji("🇩🇴", "Flags", ["dominican_republic"]),
  new Emoji("🇪🇨", "Flags", ["ecuador"]),
  new Emoji("🇪🇬", "Flags", ["egypt"]),
  new Emoji("🇸🇻", "Flags", ["el_salvador"]),
  new Emoji("🇬🇶", "Flags", ["equatorial_guinea"]),
  new Emoji("🇪🇷", "Flags", ["eritrea"]),
  new Emoji("🇪🇪", "Flags", ["estonia"]),
  new Emoji("🇪🇹", "Flags", ["ethiopia"]),
  new Emoji("🇪🇺", "Flags", ["eu", "european_union"]),
  new Emoji("🇫🇰", "Flags", ["falkland_islands"]),
  new Emoji("🇫🇴", "Flags", ["faroe_islands"]),
  new Emoji("🇫🇯", "Flags", ["fiji"]),
  new Emoji("🇫🇮", "Flags", ["finland"]),
  new Emoji("🇫🇷", "Flags", ["fr"]),
  new Emoji("🇬🇫", "Flags", ["french_guiana"]),
  new Emoji("🇵🇫", "Flags", ["french_polynesia"]),
  new Emoji("🇹🇫", "Flags", ["french_southern_territories"]),
  new Emoji("🇬🇦", "Flags", ["gabon"]),
  new Emoji("🇬🇲", "Flags", ["gambia"]),
  new Emoji("🇬🇪", "Flags", ["georgia"]),
  new Emoji("🇩🇪", "Flags", ["de"]),
  new Emoji("🇬🇭", "Flags", ["ghana"]),
  new Emoji("🇬🇮", "Flags", ["gibraltar"]),
  new Emoji("🇬🇷", "Flags", ["greece"]),
  new Emoji("🇬🇱", "Flags", ["greenland"]),
  new Emoji("🇬🇩", "Flags", ["grenada"]),
  new Emoji("🇬🇵", "Flags", ["guadeloupe"]),
  new Emoji("🇬🇺", "Flags", ["guam"]),
  new Emoji("🇬🇹", "Flags", ["guatemala"]),
  new Emoji("🇬🇬", "Flags", ["guernsey"]),
  new Emoji("🇬🇳", "Flags", ["guinea"]),
  new Emoji("🇬🇼", "Flags", ["guinea_bissau"]),
  new Emoji("🇬🇾", "Flags", ["guyana"]),
  new Emoji("🇭🇹", "Flags", ["haiti"]),
  new Emoji("🇭🇳", "Flags", ["honduras"]),
  new Emoji("🇭🇰", "Flags", ["hong_kong"]),
  new Emoji("🇭🇺", "Flags", ["hungary"]),
  new Emoji("🇮🇸", "Flags", ["iceland"]),
  new Emoji("🇮🇳", "Flags", ["india"]),
  new Emoji("🇮🇩", "Flags", ["indonesia"]),
  new Emoji("🇮🇷", "Flags", ["iran"]),
  new Emoji("🇮🇶", "Flags", ["iraq"]),
  new Emoji("🇮🇪", "Flags", ["ireland"]),
  new Emoji("🇮🇲", "Flags", ["isle_of_man"]),
  new Emoji("🇮🇱", "Flags", ["israel"]),
  new Emoji("🇮🇹", "Flags", ["it"]),
  new Emoji("🇯🇲", "Flags", ["jamaica"]),
  new Emoji("🇯🇵", "Flags", ["jp"]),
  new Emoji("🎌", "Flags", ["crossed_flags"]),
  new Emoji("🇯🇪", "Flags", ["jersey"]),
  new Emoji("🇯🇴", "Flags", ["jordan"]),
  new Emoji("🇰🇿", "Flags", ["kazakhstan"]),
  new Emoji("🇰🇪", "Flags", ["kenya"]),
  new Emoji("🇰🇮", "Flags", ["kiribati"]),
  new Emoji("🇽🇰", "Flags", ["kosovo"]),
  new Emoji("🇰🇼", "Flags", ["kuwait"]),
  new Emoji("🇰🇬", "Flags", ["kyrgyzstan"]),
  new Emoji("🇱🇦", "Flags", ["laos"]),
  new Emoji("🇱🇻", "Flags", ["latvia"]),
  new Emoji("🇱🇧", "Flags", ["lebanon"]),
  new Emoji("🇱🇸", "Flags", ["lesotho"]),
  new Emoji("🇱🇷", "Flags", ["liberia"]),
  new Emoji("🇱🇾", "Flags", ["libya"]),
  new Emoji("🇱🇮", "Flags", ["liechtenstein"]),
  new Emoji("🇱🇹", "Flags", ["lithuania"]),
  new Emoji("🇱🇺", "Flags", ["luxembourg"]),
  new Emoji("🇲🇴", "Flags", ["macau"]),
  new Emoji("🇲🇰", "Flags", ["macedonia"]),
  new Emoji("🇲🇬", "Flags", ["madagascar"]),
  new Emoji("🇲🇼", "Flags", ["malawi"]),
  new Emoji("🇲🇾", "Flags", ["malaysia"]),
  new Emoji("🇲🇻", "Flags", ["maldives"]),
  new Emoji("🇲🇱", "Flags", ["mali"]),
  new Emoji("🇲🇹", "Flags", ["malta"]),
  new Emoji("🇲🇭", "Flags", ["marshall_islands"]),
  new Emoji("🇲🇶", "Flags", ["martinique"]),
  new Emoji("🇲🇷", "Flags", ["mauritania"]),
  new Emoji("🇲🇺", "Flags", ["mauritius"]),
  new Emoji("🇾🇹", "Flags", ["mayotte"]),
  new Emoji("🇲🇽", "Flags", ["mexico"]),
  new Emoji("🇫🇲", "Flags", ["micronesia"]),
  new Emoji("🇲🇩", "Flags", ["moldova"]),
  new Emoji("🇲🇨", "Flags", ["monaco"]),
  new Emoji("🇲🇳", "Flags", ["mongolia"]),
  new Emoji("🇲🇪", "Flags", ["montenegro"]),
  new Emoji("🇲🇸", "Flags", ["montserrat"]),
  new Emoji("🇲🇦", "Flags", ["morocco"]),
  new Emoji("🇲🇿", "Flags", ["mozambique"]),
  new Emoji("🇲🇲", "Flags", ["myanmar"]),
  new Emoji("🇳🇦", "Flags", ["namibia"]),
  new Emoji("🇳🇷", "Flags", ["nauru"]),
  new Emoji("🇳🇵", "Flags", ["nepal"]),
  new Emoji("🇳🇱", "Flags", ["netherlands"]),
  new Emoji("🇳🇨", "Flags", ["new_caledonia"]),
  new Emoji("🇳🇿", "Flags", ["new_zealand"]),
  new Emoji("🇳🇮", "Flags", ["nicaragua"]),
  new Emoji("🇳🇪", "Flags", ["niger"]),
  new Emoji("🇳🇬", "Flags", ["nigeria"]),
  new Emoji("🇳🇺", "Flags", ["niue"]),
  new Emoji("🇳🇫", "Flags", ["norfolk_island"]),
  new Emoji("🇲🇵", "Flags", ["northern_mariana_islands"]),
  new Emoji("🇰🇵", "Flags", ["north_korea"]),
  new Emoji("🇳🇴", "Flags", ["norway"]),
  new Emoji("🇴🇲", "Flags", ["oman"]),
  new Emoji("🇵🇰", "Flags", ["pakistan"]),
  new Emoji("🇵🇼", "Flags", ["palau"]),
  new Emoji("🇵🇸", "Flags", ["palestinian_territories"]),
  new Emoji("🇵🇦", "Flags", ["panama"]),
  new Emoji("🇵🇬", "Flags", ["papua_new_guinea"]),
  new Emoji("🇵🇾", "Flags", ["paraguay"]),
  new Emoji("🇵🇪", "Flags", ["peru"]),
  new Emoji("🇵🇭", "Flags", ["philippines"]),
  new Emoji("🇵🇳", "Flags", ["pitcairn_islands"]),
  new Emoji("🇵🇱", "Flags", ["poland"]),
  new Emoji("🇵🇹", "Flags", ["portugal"]),
  new Emoji("🇵🇷", "Flags", ["puerto_rico"]),
  new Emoji("🇶🇦", "Flags", ["qatar"]),
  new Emoji("🇷🇪", "Flags", ["reunion"]),
  new Emoji("🇷🇴", "Flags", ["romania"]),
  new Emoji("🇷🇺", "Flags", ["ru"]),
  new Emoji("🇷🇼", "Flags", ["rwanda"]),
  new Emoji("🇧🇱", "Flags", ["st_barthelemy"]),
  new Emoji("🇸🇭", "Flags", ["st_helena"]),
  new Emoji("🇰🇳", "Flags", ["st_kitts_nevis"]),
  new Emoji("🇱🇨", "Flags", ["st_lucia"]),
  new Emoji("🇵🇲", "Flags", ["st_pierre_miquelon"]),
  new Emoji("🇻🇨", "Flags", ["st_vincent_grenadines"]),
  new Emoji("🇼🇸", "Flags", ["samoa"]),
  new Emoji("🇸🇲", "Flags", ["san_marino"]),
  new Emoji("🇸🇹", "Flags", ["sao_tome_principe"]),
  new Emoji("🇸🇦", "Flags", ["saudi_arabia"]),
  new Emoji("🇸🇳", "Flags", ["senegal"]),
  new Emoji("🇷🇸", "Flags", ["serbia"]),
  new Emoji("🇸🇨", "Flags", ["seychelles"]),
  new Emoji("🇸🇱", "Flags", ["sierra_leone"]),
  new Emoji("🇸🇬", "Flags", ["singapore"]),
  new Emoji("🇸🇽", "Flags", ["sint_maarten"]),
  new Emoji("🇸🇰", "Flags", ["slovakia"]),
  new Emoji("🇸🇮", "Flags", ["slovenia"]),
  new Emoji("🇸🇧", "Flags", ["solomon_islands"]),
  new Emoji("🇸🇴", "Flags", ["somalia"]),
  new Emoji("🇿🇦", "Flags", ["south_africa"]),
  new Emoji("🇬🇸", "Flags", ["south_georgia_south_sandwich_islands"]),
  new Emoji("🇰🇷", "Flags", ["kr"]),
  new Emoji("🇸🇸", "Flags", ["south_sudan"]),
  new Emoji("🇪🇸", "Flags", ["es"]),
  new Emoji("🇱🇰", "Flags", ["sri_lanka"]),
  new Emoji("🇸🇩", "Flags", ["sudan"]),
  new Emoji("🇸🇷", "Flags", ["suriname"]),
  new Emoji("🇸🇿", "Flags", ["swaziland"]),
  new Emoji("🇸🇪", "Flags", ["sweden"]),
  new Emoji("🇨🇭", "Flags", ["switzerland"]),
  new Emoji("🇸🇾", "Flags", ["syria"]),
  new Emoji("🇹🇼", "Flags", ["taiwan"]),
  new Emoji("🇹🇯", "Flags", ["tajikistan"]),
  new Emoji("🇹🇿", "Flags", ["tanzania"]),
  new Emoji("🇹🇭", "Flags", ["thailand"]),
  new Emoji("🇹🇱", "Flags", ["timor_leste"]),
  new Emoji("🇹🇬", "Flags", ["togo"]),
  new Emoji("🇹🇰", "Flags", ["tokelau"]),
  new Emoji("🇹🇴", "Flags", ["tonga"]),
  new Emoji("🇹🇹", "Flags", ["trinidad_tobago"]),
  new Emoji("🇹🇳", "Flags", ["tunisia"]),
  new Emoji("🇹🇷", "Flags", ["tr"]),
  new Emoji("🇹🇲", "Flags", ["turkmenistan"]),
  new Emoji("🇹🇨", "Flags", ["turks_caicos_islands"]),
  new Emoji("🇹🇻", "Flags", ["tuvalu"]),
  new Emoji("🇺🇬", "Flags", ["uganda"]),
  new Emoji("🇺🇦", "Flags", ["ukraine"]),
  new Emoji("🇦🇪", "Flags", ["united_arab_emirates"]),
  new Emoji("🇬🇧", "Flags", ["gb", "uk"]),
  new Emoji("🇺🇸", "Flags", ["us"]),
  new Emoji("🇻🇮", "Flags", ["us_virgin_islands"]),
  new Emoji("🇺🇾", "Flags", ["uruguay"]),
  new Emoji("🇺🇿", "Flags", ["uzbekistan"]),
  new Emoji("🇻🇺", "Flags", ["vanuatu"]),
  new Emoji("🇻🇦", "Flags", ["vatican_city"]),
  new Emoji("🇻🇪", "Flags", ["venezuela"]),
  new Emoji("🇻🇳", "Flags", ["vietnam"]),
  new Emoji("🇼🇫", "Flags", ["wallis_futuna"]),
  new Emoji("🇪🇭", "Flags", ["western_sahara"]),
  new Emoji("🇾🇪", "Flags", ["yemen"]),
  new Emoji("🇿🇲", "Flags", ["zambia"]),
  new Emoji("🇿🇼", "Flags", ["zimbabwe"])
];
</script>
