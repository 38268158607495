import VvenueChat from "./vvenue-chat.vue";
import { App } from "vue";

import VueFileAgentNext from "@boindil/vue-file-agent-next";
import "@boindil/vue-file-agent-next/dist/vue-file-agent-next.css";

//See: https://github.com/ankurk91/vue-loading-overlay/issues/117#issuecomment-1576182347
import VueLoadingOverlay from "vue-loading-overlay/src/js/Component.vue";
import "vue-loading-overlay/dist/css/index.css";

const Plugin = {
  install: (app: App) => {
    app.use(VueFileAgentNext);
    app.component("VueLoadingOverlay", VueLoadingOverlay);
    app.component("vvenue-chat", VvenueChat);
  }
};

export default Plugin;

export { VvenueChat };
export type { Translations } from "./types";
