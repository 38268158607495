<template>
  <span>
    <div class="main-title">
      <span v-if="alter && !restricted">{{ translations.chat.group_channel.update }}</span
      ><span v-if="!alter && !restricted">{{ translations.chat.group_channel.create }}</span>
      {{ translations.chat.group_channel.group_channel }}
      <span class="icon icon-close" v-on:click="$emit('close')"></span>
    </div>

    <div class="settings-wrap">
      <div class="settings-section" v-if="!restricted">
        <div class="main-title">
          {{ translations.chat.group_channel.channel_name }}
          <edit-button
            class="icon-component"
            v-on:click.native="setCaretToEnd($event.currentTarget.parentElement.nextElementSibling)"
          ></edit-button>
        </div>
        <input
          class="channel-name-input"
          v-on:keydown="$event.stopPropagation()"
          v-on:keyup="$event.stopPropagation()"
          v-on:keypress="$event.stopPropagation()"
          type="text"
          v-model="display_name"
          :placeholder="translations.chat.group_channel.enter_a_channel_name"
        />
      </div>
      <div class="settings-section">
        <div class="main-title">
          {{ translations.chat.group_channel.channel_users }}
        </div>
        <div class="title-description" v-if="!restricted">
          {{ translations.chat.group_channel.selected_users }}
        </div>
        <div class="channel-users">
          <div class="user-el" v-for="(uuid, index) in selectedChannels" :key="index">
            {{ cutString(user?.name || translations.main.loading, 15) }}
            <span v-if="!restricted" class="remove" v-on:click="selectChannel(uuid, false)">x</span>
          </div>
        </div>
        <div class="title-description" v-if="!restricted">
          {{ translations.chat.group_channel.available_users }}
        </div>
        <div class="channel-users" v-if="!restricted">
          <div class="search-bar">
            <div class="btn-wrap">
              <search-button
                class="svg-white-color"
                v-on:click.native="setCaretToEnd($event.currentTarget.parentElement.nextElementSibling)"
              ></search-button>
            </div>
            <input
              type="text"
              v-model="searchUser"
              class="search-add-user"
              v-on:keydown="$event.stopPropagation()"
              v-on:keyup="$event.stopPropagation()"
              v-on:keypress="$event.stopPropagation()"
              :placeholder="translations.chat.group_channel.search_user"
            />
            <div
              class="empty-search"
              v-if="searchUser.length"
              v-bind:style="{ color: secondaryColor }"
              v-on:click="searchUser = ''"
            >
              ×
            </div>
          </div>
          <div
            class="user-el unselected"
            v-for="(uuid, index) in availableChannelsSearched"
            :key="index"
            v-on:click="selectChannel(uuid, true)"
          >
            {{ cutString((user && user.name) || translations.main.loading, 15) }}
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button v-on:click="createGroup()" v-if="!restricted">
          {{ alter ? translations.chat.group_channel.update : translations.chat.group_channel.create }}
          {{ translations.chat.group_channel.group_channel }}
        </button>
        <button v-on:click="leaveChannel()" v-if="restricted">
          {{ translations.chat.group_channel.leave_channel }}
        </button>
        <div v-on:click="deleteGroupChannel()" v-if="alter && !restricted">
          {{ translations.chat.group_channel.delete }} {{ translations.chat.group_channel.group_channel }}
        </div>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
import { CloseButton, AddUserButton, EditButton, SearchButton } from "./svgs.vue";
import { nanoid } from "nanoid";
import { expMixins } from "../functions";
import { Channel, Channels, Translations, UserData, UsersData } from "@/types";
import { PropType } from "vue";

export default {
  name: "GroupSettings", // vue component name
  components: {
    CloseButton,
    AddUserButton,
    EditButton,
    SearchButton
  },
  data: () => {
    return {
      showModal: true,
      availableChannels: [],
      selectedChannels: [],
      alter: true,
      display_name: "",
      isLoading: false,
      searchUser: ""
    };
  },
  props: {
    pns: {
      type: Object,
      required: true
    },
    uuid: {
      type: String,
      required: true
    },
    restricted: {
      type: Boolean,
      required: true
    },
    currentGroupChannel: {
      type: Object,
      required: true
    },
    user: {
      type: Object as PropType<UserData>
    },
    usersData: {
      type: Object as PropType<UsersData>,
      required: true
    },
    secondaryColor: {
      type: String,
      default: "#983AD2"
    },
    channels: {
      type: Object as PropType<Channels>,
      required: true
    },
    activeUsersUUIDs: {
      type: Array as PropType<string[]>,
      default() {
        return [];
      }
    },
    translations: {
      type: Object as PropType<Translations>,
      required: true
    }
  },
  async mounted() {
    // this.groupChannel = this.currentGroupChannel;
    //group channel exists already
    if (this.currentGroupChannel.subscription_name && this.currentGroupChannel.type === "group") {
      this.display_name = this.currentGroupChannel.display_name;
      if (!this.currentGroupChannel.group_participants) {
        this.currentGroupChannel.group_participants = [];
      }
      this.setChannels(false);
    }
    //new group channel from private channel
    else if (this.currentGroupChannel.subscription_name && this.currentGroupChannel.type === "private") {
      this.setChannels(true);
    }
  },
  computed: {
    actualChannelUuids() {
      return Object.values(this.channels)
        .filter((el: Channel) => el.type === "private" && typeof this.usersData[el.uuid] !== "undefined")
        .map((el: Channel) => el.uuid);
    },
    availableChannelsSearched() {
      if (!this.searchUser || this.searchUser === "") {
        return this.availableChannels;
      }
      return this.availableChannels.filter((uuid: string) =>
        this.usersData[uuid].name.toLowerCase().includes(this.searchUser.toLowerCase())
      );
    }
  },
  methods: {
    cutString: expMixins.filters.cutString,
    hslColorFromArbitraryString: expMixins.methods.hslColorFromArbitraryString,
    logErrors: expMixins.methods.logErrors,

    setChannels(newChannel = true) {
      this.alter = !newChannel;
      this.selectedChannels = [];
      this.availableChannels = [];
      this.availableChannels = Object.values(this.channels)
        .filter((chan: Channel) => chan.type === "private" && chan.uuid)
        .map((chan: Channel) => chan.uuid)
        .filter(
          (uuid: string) =>
            this.user &&
            this.user.name &&
            //old channel
            (newChannel || !this.currentGroupChannel.group_participants.includes(uuid)) &&
            this.user.custom?.status !== "Offline"
        )
        .concat(
          this.activeUsersUUIDs.filter(
            (uuid) =>
              !this.actualChannelUuids.includes(uuid) &&
              (newChannel || !this.currentGroupChannel.group_participants.includes(uuid)) &&
              this.user &&
              this.user.name &&
              this.user.custom?.status !== "Offline"
          )
        );

      if (!newChannel) {
        this.selectedChannels = this.currentGroupChannel.group_participants.slice();
      }

      if (this.restricted) {
        this.selectedChannels.splice(this.selectedChannels.indexOf(this.uuid), 1);
        this.selectedChannels.push(this.currentGroupChannel.group_owner);
      }
    },
    selectChannel(uuid: string, select: boolean) {
      let moveChannel = null;
      if (select) {
        const index = this.availableChannels.indexOf(uuid);
        if (index > -1) {
          moveChannel = this.availableChannels.splice(index, 1)[0];
          this.selectedChannels.push(moveChannel);
        }
      } else {
        const index = this.selectedChannels.indexOf(uuid);
        moveChannel = this.selectedChannels.splice(index, 1)[0];
        this.availableChannels.push(moveChannel);
      }
    },
    async createGroup() {
      if (this.selectedChannels.length < 2) {
        alert(this.translations.errors.please_select_at_least_two_users);
        return;
      }
      if (!this.display_name || this.display_name === "") {
        alert(this.translations.errors.please_enter_a_channel_name);
        return;
      }

      this.$emit("loading", true);

      this.display_name = this.display_name.replace(/\r?\n|\r/g, "");
      this.display_name = this.display_name.replace(/\&nbsp;/g, "");
      this.display_name = this.display_name.trim();

      if (!this.alter) {
        const channelToInsert: Channel = {
          //### ChatWindow ###
          isOpen: false,
          isExpanded: false,
          currentMessage: "",
          container_div_id: (Math.random() * 1000000).toFixed(0),

          //### ChannelToSubscribe ###
          display_name: this.display_name,
          subscription_name: "group_" + nanoid(),
          avatarBackground: this.secondaryColor,
          avatarBackgroundOpacity: this.hslColorFromArbitraryString(this.secondaryColor, 50, 75),
          hasMembership: true,
          type: "group", //'public', 'private', 'group'
          group_owner: this.uuid,
          group_participants: this.selectedChannels
        };
        this.pns.updateChannelObject(channelToInsert);

        const now = Date.now();

        await Promise.all([
          this.pns.pubnub_setChannelMetaData(channelToInsert.subscription_name),
          this.pns.pubnub_setChannelMembership(channelToInsert, `${now}9999`, true)
        ]);

        this.pns
          .pubnub_subscribeToChannels(
            [channelToInsert.subscription_name],
            channelToInsert.group_participants.concat([channelToInsert.group_owner])
          )
          .then(() => this.$emit("open-chat", channelToInsert.subscription_name));
      } else {
        //update group chat
        const newGroupParticipants = this.selectedChannels;
        const groupParticipantsToRemove = this.currentGroupChannel.group_participants.filter(
          (el) => !newGroupParticipants.includes(el)
        );

        this.currentGroupChannel.display_name = this.display_name;
        this.currentGroupChannel.group_participants = newGroupParticipants;
        const now = Date.now();

        Promise.all([
          this.pns.pubnub_setChannelMetaData(this.currentGroupChannel.subscription_name),
          this.pns.pubnub_setChannelMembership(this.currentGroupChannel, `${now}9999`, true, groupParticipantsToRemove)
        ]);

        const promises = [
          this.pns.pubnub_subscribeToChannels(
            [this.currentGroupChannel.subscription_name],
            this.currentGroupChannel.group_participants.concat([this.currentGroupChannel.group_owner])
          )
        ];
        if (groupParticipantsToRemove && groupParticipantsToRemove.length) {
          promises.push(
            this.pns.pubnub_unsubscribeFromChannels(
              [this.currentGroupChannel.subscription_name],
              [groupParticipantsToRemove]
            )
          );
        }
        Promise.all(promises);
      }
      this.$emit("close");
      this.$emit("loading", false);
    },
    async leaveChannel() {
      if (confirm(this.translations.chat.group_channel.are_you_sure_leave)) {
        this.$emit("loading", true);
        while (this.intervalIsUpdatingChats) {
          await new Promise((res) =>
            setTimeout(() => {
              res(true);
            }, 300)
          );
        }
        try {
          this.intervalIsUpdatingChats = true;
          await this.pns.deleteChannel(this.currentGroupChannel, this.uuid);
          await this.pns.removeDeletedChannels();
          this.$emit("force-rerender");
          this.$emit("loading", false);
          this.intervalIsUpdatingChats = false;
        } catch (error) {
          this.intervalIsUpdatingChats = false;
          this.logErrors(error);
          this.$emit("loading", false);
        }
      }
    },
    async deleteGroupChannel() {
      if (confirm(this.translations.chat.group_channel.are_you_sure_delete)) {
        this.$emit("loading", true);
        while (this.intervalIsUpdatingChats) {
          await new Promise((res) =>
            setTimeout(() => {
              res(true);
            }, 300)
          );
        }
        try {
          this.intervalIsUpdatingChats = true;
          await this.pns.deleteChannel(this.currentGroupChannel);
          await this.pns.removeDeletedChannels();
          this.$emit("loading", false);
          this.$emit("force-rerender");
          this.intervalIsUpdatingChats = false;
        } catch (error) {
          this.$emit("loading", false);
          this.intervalIsUpdatingChats = false;
          this.logErrors(error);
        }
      }
    },
    setCaretToEnd(target /*: HTMLDivElement*/) {
      if (typeof target === "string") {
        target = this.$el.querySelector("#chat_input_" + target);
      }
      setTimeout(() => {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(target);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
        target.focus();
        range.detach(); // optimization

        // set scroll to the end if multiline
        target.scrollTop = target.scrollHeight;
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
$primaryColor: var(--vvc-primary-color);
$secondaryColor: var(--vvc-secondary-color);

.settings-wrap {
  .icon-component {
    margin-left: 12px !important;
    transform: translateY(4.7px);
  }
  overflow-y: scroll;
  .main-title {
    margin-bottom: 8px;
  }
  input {
    color: white;
    background-color: transparent;
    font-size: 17px;
    &::placeholder {
      color: white;
      opacity: 0.56;
    }
  }

  color: white;
  .close {
    float: right;
    cursor: pointer;
    color: white;
    text-align: center;
    opacity: 0.56;
    transform: translateY(-1px);
    &:hover {
      opacity: 1;
    }
  }
  .channel-users {
    padding-bottom: 8px;
    max-height: 200px;
    overflow-y: auto;
  }
  .channel-name-input {
    border: none;
  }

  .user-el {
    font-size: 10px;
    display: inline-block;
    padding: 6px 10px;
    color: white;
    background-color: $primaryColor;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 6px;
    &:hover {
      opacity: 0.9;
    }
    .remove {
      font-weight: bolder;
      cursor: pointer;
    }
    &.unselected {
      cursor: pointer;
    }
  }

  button {
    margin: auto;
    display: block;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .search-bar {
    position: relative;
    background: white;
    border-radius: 6px;
    margin-bottom: 8px;
    display: flex;
    .btn-wrap {
      cursor: pointer;
      display: flex;
      border-radius: 6px 0px 0px 6px;
      background-color: $primaryColor;
      height: 28px;
      align-items: center;
      padding: 0px 3px;
      // margin-right: 6px;
    }

    .search-add-user {
      border: none;
      border-radius: 6px;
      width: 100%;
      height: 28px;
      color: black;
      font-size: 13px;
      &::placeholder {
        // color: white;
        color: gray;
        font-size: 13px;
      }
      padding: 0px 10px;
    }
    .empty-search {
      font-size: 23px;
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }
  }
  .modal-footer {
    div {
      display: block;
      text-decoration: underline;
      margin-top: 15px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
