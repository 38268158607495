<template>
  <div id="otDragContainer" :class="{ 'full-screen': video_expanded }">
    <!-- Include a header DIV with the same name as the draggable DIV, followed by "header" -->
    <div id="otDragContainerheader">
      <div class="main-title">
        {{ currentVideoChat.display_name }}

        <div class="icon" v-on:click="$emit('close')">
          <close-button class="close-button"></close-button>
        </div>
        <div class="icon" v-on:click="video_expanded = !video_expanded">
          <full-screen-button
            class="full-screen-button"
            :class="{ transformrotate180: video_expanded }"
          ></full-screen-button>
        </div>
      </div>
    </div>
    <div class="info-msg" v-if="!streams.length">
      {{ translations.video_chat.currently_no_other_users }}
    </div>
    <div class="info-msg" v-if="hasSession && !canPublish">
      {{ translations.errors.unfortunately_you_cannot_publish }}
    </div>
    <div class="info-msg" v-if="accessDenied">
      {{ translations.errors.video_microphone_access_denied }}
    </div>
    <div class="info-msg" v-if="pleaseGrantAccess">
      {{ translations.errors.please_grant_access_to_video_microphone }}
    </div>
    <div id="session" :class="{ only_one_participant: streams?.length < 2 }">
      <div v-if="isLoading" class="loader">
        <VueLoadingOverlay :active="true" :width="50" :height="50" :enforce-focus="false" :is-full-page="false" :opacity="0"/>
      </div>
      <publisher
        :session="session"
        @error="errorHandler"
        @access-denied="accessDenied = $event"
        @grant-access="pleaseGrantAccess = $event"
        ref="publisher"
        class="publisher"
        :profileSettings="profileSettings"
        :options="publisherOpts"
        :microphoneOn="microphoneOn"
        :cameraOn="cameraOn"
        v-if="hasSession && canPublish"
        :chatIsVisibleAndUnmutedData="chatIsVisibleAndUnmutedData"
      ></publisher>
      <subscriber
        v-for="stream in streams"
        :key="stream.streamId"
        @error="errorHandler"
        :stream="stream"
        :session="session"
        class="subscriber"
        :options="subscriberOpts"
      ></subscriber>
      <div class="audio-video-selection" v-if="deviceSettingsOpen">
        <close-button class="close" v-on:click.native="openDeviceSettings()"></close-button>
        <h4>{{ translations.video_chat.hardware_selection }}</h4>
        <div class="video" v-if="videoDevices && videoDevices.length">
          <div class="is-not-mobile">
            <div class="video-selection">
              <label for="videoInput">{{ translations.video_chat.video_input }}</label>
              <select
                :disabled="isMobileDevice"
                id="videoInput"
                v-model="currentVideoInput"
                @change="changeVideoSource"
              >
                <option
                  v-for="item in videoDevices"
                  :value="item.deviceId"
                  :key="item.deviceId"
                  :selected="item.deviceId == currentVideoInput"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
            <div class="toggle-button">
              <div class="icon" v-on:click="switchCam()">
                <camera-switch-button></camera-switch-button>
              </div>
            </div>
          </div>
        </div>
        <div class="voice" v-if="audioDevices && audioDevices.length">
          <label for="audioInput">{{ translations.video_chat.audio_input }}</label>
          <select id="audioInput" v-model="currentAudioInput" @change="changeAudioInput">
            <option
              v-for="item in audioDevices"
              :value="item.label"
              :key="item.label"
              :selected="item.label == currentAudioInput"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="otControls">
      <div class="otControlsInner">
        <div class="icon" v-if="microphoneOn" v-on:click="microphoneOn = false">
          <microphone-button class="icon-component"></microphone-button>
        </div>
        <div class="icon" v-if="!microphoneOn" v-on:click="microphoneOn = true">
          <microphone-mute-button class="icon-component"></microphone-mute-button>
        </div>
        <div class="icon" v-on:click="$emit('close')">
          <hang-up-button class="icon-component hang-up"></hang-up-button>
        </div>
        <div class="icon" v-if="cameraOn" v-on:click="cameraOn = false">
          <video-button class="icon-component video-button"></video-button>
        </div>
        <div class="icon" v-if="!cameraOn" v-on:click="cameraOn = true">
          <no-video-button class="icon-component"></no-video-button>
        </div>
        <div class="icon">
          <settings-button
            style="height: 26px"
            class="icon-component"
            v-on:click.native="openDeviceSettings()"
          ></settings-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  SvgComponent,
  CloseButton,
  MicrophoneButton,
  MicrophoneMuteButton,
  HangUpButton,
  NoVideoButton,
  VideoButton,
  CameraSwitchButton,
  FullScreenButton,
  SettingsButton
} from "./svgs.vue";
import Subscriber from "./Subscriber.vue";
import Publisher from "./Publisher.vue";
import OT from "@opentok/client";
import { VideoChat, ProfileSettings, Translations } from "@/types";
import { PropType, markRaw } from "vue";
import expMixins from "@/functions";

export default {
  components: {
    Subscriber,
    Publisher,
    SvgComponent,
    CloseButton,
    MicrophoneButton,
    MicrophoneMuteButton,
    HangUpButton,
    NoVideoButton,
    VideoButton,
    CameraSwitchButton,
    FullScreenButton,
    SettingsButton
  },
  props: {
    currentVideoChat: {
      required: true,
      type: Object as PropType<VideoChat>
    },
    profileSettings: {
      required: false,
      type: Object as PropType<ProfileSettings>
    },
    chatIsVisibleAndUnmutedData: {
      required: false,
      type: Boolean,
      default: false
    },
    translations: {
      type: Object as PropType<Translations>,
      required: true
    },
    //Function to Generate a OpenTok Token
    // async () => string
    otGetToken: {
      required: true
    }
  },
  data() {
    return {
      streams: [],
      session: null as OT.Session,
      hasSession: false,
      canPublish: false,
      microphoneOn: true,
      cameraOn: true,
      publisherOpts: {},
      subscriberOpts: {},
      video_expanded: false,
      accessDenied: false,
      pleaseGrantAccess: false,
      devices: [],
      currentVideoInput: null,
      currentAudioInput: null,
      deviceSettingsOpen: false,
      isMobileDevice: false,
      isLoading: true
    };
  },
  created() {
    this.publisherOpts["name"] = this.profileSettings.name;
    this.publisherOpts["style"] = { nameDisplayMode: "off" };
    const nameDisplayMode = this.currentVideoChat.type === "private" ? "off" : "on";
    this.subscriberOpts["style"] = {
      nameDisplayMode,
      buttonDisplayMode: "on",
      audioBlockedDisplayMode: "on"
    };
  },
  async mounted() {
    try {
      if (typeof this.currentVideoChat?.otSession === "undefined") {
        throw new Error("otSession token required");
      }

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        this.isMobileDevice = true;
        this.consoleLog(`this.isMobileDevice`, this.isMobileDevice);
      }

      const otToken = await this.otGetToken(this.currentVideoChat.otSession);

      OT.getDevices((error, devices) => {
        this.devices = JSON.parse(JSON.stringify(devices));
        if (error) {
          this.consoleError("Error getting devices", error, devices);
        } else {
          this.consoleLog(`Devices list`, devices);
        }
      });

      this.session = markRaw<OT.Session>(OT.initSession(this.currentVideoChat.otApiKey, this.currentVideoChat.otSession));
      this.session.connect(otToken, (err) => {
        this.setLoadingIndicator(false);
        if (err) {
          this.setLoadingIndicator(false);
          this.errorHandler(err);
          return;
        }
        this.session.on({
          "streamCreated": (event) => {
            this.consoleLog("Stream created");
            this.streams.push(event.stream);
          },
          "streamDestroyed": (event) => {
            const idx = this.streams.indexOf(event.stream);
            if (idx > -1) {
              this.streams.splice(idx, 1);
            }
          },
          "sessionDisconnected": () => this.hasSession = false
        });

        this.consoleLog("Session connected", this.session?.capabilities);
        this.hasSession = true;
        this.canPublish = this.session.capabilities.publish === 1;
      });
    } catch (error) {
      this.setLoadingIndicator(false);
      try {
        if (this.session) {
          this.session.destroy();
        }
        this.logErrors(error);
      } catch (err) {
        this.logErrors(err);
      }
    }
    this.dragElement(document.querySelector("#otDragContainer"));
  },
  unmounted() {
    this.consoleLog("Session destroy");
    this.hasSession = false;
    this.session.destroy();
  },
  computed: {
    videoDevices() {
      return this.devices.filter((el) => el.kind === "videoInput");
    },
    audioDevices() {
      return this.devices.filter((el) => el.kind === "audioInput");
    }
  },
  methods: {
    consoleLog: expMixins.methods.consoleLog,
    logErrors: expMixins.methods.logErrors,
    setLoadingIndicator(isLoading: boolean) {
      this.isLoading = isLoading;
      this.$emit("loading", isLoading);
    },
    async cycleAudio() {
      // Setting an audio source to a new MediaStreamTrack
      const stream = await OT.getUserMedia({
        videoSource: null
      });

      const [audioSource] = stream.getAudioTracks();
      const publisher = this.$refs.publisher.exposed.getPublisher();

      publisher.setAudioSource(audioSource).then(() => this.consoleLog("Audio source updated"));

      // Cycling through microphone inputs
      let audioInputs;
      let currentIndex = 0;
      OT.getDevices((err, devices) => {
        this.logErrors(err);
        audioInputs = devices.filter((device) => device.kind === "audioInput");
        // Find the right starting index for cycleMicrophone
        audioInputs.forEach((device, idx) => {
          if (device.label === publisher.getAudioSource().label) {
            currentIndex = idx;
          }
        });
        currentIndex += 1;
        const deviceId = audioInputs[currentIndex % audioInputs.length].deviceId;
        publisher.setAudioSource(deviceId);
      });
    },
    changeAudioInput() {
      const device = this.devices.filter((el) => el.label === this.currentAudioInput);
      if (device[0]) {
        this.$refs.publisher.exposed
          .getPublisher()
          .setAudioSource(device[0].deviceId)
          .catch((error) => {
            this.consoleLog(`setAudioSource error`, error);
          });
      }

      this.consoleLog(`this.currentAudioInput`, this.currentAudioInput);
    },
    changeVideoSource() {
      this.$refs.publisher.exposed
        .getPublisher()
        .setVideoSource(this.currentVideoInput)
        .then(() => {})
        .catch((error) => {
          this.consoleLog(`setVideoSource error`, error);
        });
      this.consoleLog(`this.currentVideoInput`, this.currentVideoInput);
    },
    openDeviceSettings() {
      OT.getDevices((error, devices) => {
        this.devices = JSON.parse(JSON.stringify(devices));

        if (error) {
          this.logErrors(error);
        }

        if (this.$refs.publisher) {
          const currentPublisher = this.$refs.publisher.exposed.getPublisher();
          this.currentVideoInput = currentPublisher.getVideoSource()?.deviceId;
          this.currentAudioInput = currentPublisher.getAudioSource()?.label;
        }
        this.deviceSettingsOpen = !this.deviceSettingsOpen;
      });
    },
    toggleAudio(on: boolean) {
      this.microphoneOn = on;
    },
    switchCam() {
      const publisher: OT.Publisher = this.$refs.publisher.exposed.getPublisher();
      publisher
        .cycleVideo()
        .then(() => {
          if (this.$refs.publisher) {
            const currentPublisher = this.$refs.publisher.exposed.getPublisher();
            this.currentVideoInput = currentPublisher.getVideoSource()?.deviceId;
            this.currentAudioInput = currentPublisher.getAudioSource()?.label;
          }
        })
        .catch(this.logErrors);
    },
    errorHandler(err) {
      this.logErrors(err);
      if (err.name === `OT_HARDWARE_UNAVAILABLE`) {
        // As another camera can't be used unless we first have a video stream,
        // we close the window. The user should fix the problem (close other app) or 
        // change settings in their browser (select another camera by default).
        alert(this.translations.errors.your_hardware_is_occupied_by_another_program);
      } else {
        alert(this.translations.errors.something_went_wrong_with_the_videochat);
      }
      this.$emit("close");
    },
    dragElement(elmnt) {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:

        let yPos = elmnt.offsetTop - pos2;
        if (!(yPos < 0 || yPos > window.innerHeight - 300)) {
          elmnt.style.top = yPos + "px";
        } else {
          if (yPos < 0) {
            elmnt.style.top = 0 + "px";
          } else {
            elmnt.style.top = window.innerHeight - 300 + "px";
          }
        }
        let xPos = elmnt.offsetLeft - pos1;
        if (!(xPos < 0 || xPos > window.innerWidth - 300)) {
          elmnt.style.left = xPos + "px";
        } else {
          if (xPos < 0) {
            elmnt.style.left = 0 + "px";
          } else {
            elmnt.style.left = window.innerWidth - 300 + "px";
          }
        }
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  }
};
</script>
<style scoped>
.loader {
  position: relative;
  width: 100%;
  min-height: 50px;
  overflow: visible;
}
</style>
<style lang="scss">
#otDragContainer {
  // position: absolute;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: #f0f1f3;
  text-align: center;
  // border-radius: 14px 14px 0px 0px;
  // border-radius: 14px 14px 14px 14px;
  border-radius: 30px;
  overflow: auto;
  resize: both;
  min-width: 350px;
  width: 635px;
  max-width: calc(100% - 20px);
  height: 430px;
  max-height: calc(100% - 20px);
  // min-height: 320px;
  display: flex;
  flex-direction: column;

  &.full-screen {
    top: 0px !important;
    left: 0px !important;
    max-width: 100%;
    max-height: 100%;
    height: 100% !important;
    width: 100% !important;
  }
  #otDragContainerheader {
    padding: 30px 30px 0px 30px;
    cursor: move;
    background-color: var(--vvc-secondary-color);
    color: #fff;
    // height: 42px;
    flex-basis: 42px;
    border-radius: 14px 14px 0px 0px;
    text-align: left;
  }
  .info-msg {
    // margin: auto;
    &:first-child {
      border-top: 15px solid var(--vvc-secondary-color);
    }
    border-top: 3px solid var(--vvc-secondary-color);

    padding: 7px;
    background-color: #e0ac10;
  }
  .icon {
    // margin: 7px;
    height: 30px;
    width: 30px;
    // border: 1px solid rgba(255, 255, 255, 0.56);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    &:hover {
      opacity: 0.56;
    }
    svg {
      margin-left: 0px;
      height: 30px;
      width: auto;
      path {
        fill: white;
      }
      circle {
        fill: white;
      }
    }

    .close-button {
      transform: scale(1.3);
    }
    .full-screen-button {
      width: 25px;
      margin-bottom: 3px;
      &.transformrotate180 {
        transform: rotate(180deg);
      }
    }
  }

  #session {
    background-color: var(--vvc-secondary-color);

    // width: fit-content;
    position: relative;
    overflow-y: auto;
    padding: 30px;

    // justify-content: space-around;
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
    // flex-basis: 100%;
    gap: 15px;
    height: 100%;
    &.only_one_participant {
      grid-template-columns: 1fr;
    }

    .audio-video-selection {
      z-index: 3;
      &::before {
        background: #e0ac10;

        content: "";
      }
      // top: -180px;
      position: absolute;
      width: 330px;
      // height: 170px;
      top: calc(50% - 85px);
      left: calc(50% - 165px);
      padding: 11px 23px;
      border-radius: 6px;
      background: #e0ac10;
      .close {
        position: absolute;
        right: 18px;
        top: 20px;
        cursor: pointer;
      }
      h4 {
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: white;
        margin: 12px 0px;
      }
      div {
        margin-bottom: 12px;
        width: 100%;
        label {
          margin-bottom: 2px;
          float: left;
          color: white;
          width: 100%;
          text-align: left;
        }
        select {
          font-size: 16px;
          width: 100%;
        }
      }
      .is-not-mobile {
        display: flex;
        .video-selection {
          flex-grow: 1;
        }
        .toggle-button {
          margin-bottom: 0px;
          width: auto;
          display: flex;
          align-items: flex-end;
          .icon {
            transform: translateY(4px);
            width: 30px;
            align-items: flex-end;
          }
        }
      }
    }
    .publisher {
      position: absolute;
      bottom: -33px;
      z-index: 3;
      right: -50px;
      transform: scale(0.5);
      border: 3px solid var(--vvc-secondary-color);
      // width: 132px;
      // height: 99px;
      border-radius: 14px;
    }
    .subscriber {
      flex-grow: 1;
      // max-width: 353px;
      min-height: 140px;
      min-width: 270px;
      border-radius: 14px;
      height: 100% !important;
      width: 100% !important;
    }
  }

  .otControls {
    background-color: white;
    display: flex;
    // flex-basis: 74px;
    padding: 10px;
    width: 100%;
    height: 85px;
    justify-content: center;
    align-self: flex-end;
    .otControlsInner {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-around;
      min-width: 250px;

      .icon {
        margin: 7px;
        height: 30px;
        width: 30px;
        // border: 1px solid rgba(255, 255, 255, 0.56);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.video-button {
          height: 45px;
        }
        .hang-up {
          opacity: 1;
          path {
            fill: red;
          }
        }
        &:hover {
          opacity: 0.7;
        }
        svg {
          // color: rgba(255, 255, 255, 0.56);
          path {
            fill: black;
          }
          margin-left: 0px;
          height: 45px;
          width: auto;
        }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

// MOBILE
@media screen and (max-width: 920px), screen and (max-height: 550px) {
  #otDragContainer {
    top: 0px !important;
    left: 0px !important;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }
}
</style>
