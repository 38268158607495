<template>
  <div class="chat-window">
    <div
      :id="'header_' + channel.container_div_id"
      class="header"
      :class="{
        'secondary-color': channel.type !== 'public',
        'settings-open': channel.addEditGroupOpen || channel.embeddedSettingsOpen
      }"
    >
      <group-settings
        v-if="channel.addEditGroupOpen"
        @close="
          channel.addEditGroupOpen = false;
          forceRerender();
        "
        @loading="(areSettingsLoading) => (isLoading = areSettingsLoading)"
        :pns="pns"
        :uuid="uuid"
        :restricted="channel.group_owner !== uuid && channel.type === 'group'"
        :currentGroupChannel="channel"
        :users-data="usersData"
        :user="currentUserData"
        :secondary-color="secondaryColor"
        :channels="channels"
        :active-users-uuids="activeUsersUUIDs"
        :translations="translations"
      ></group-settings>

      <!-- embedded settings start -->
      <span
        v-if="channel.embeddedSettingsOpen"
        @keyup.enter="
          $emit('close-profile-settings');
          channel.embeddedSettingsOpen = false;
          forceRerender();
        "
      >
        <div
          class="main-title"
          v-bind:style="{
            paddingRight: embeddedIconPaddingRight
          }"
        >
          Settings<span
            class="icon icon-close"
            @click="
              $emit('close-profile-settings');
              channel.embeddedSettingsOpen = false;
              forceRerender();
            "
          ></span>
        </div>
        <div class="settings-wrap">
          <div class="settings-section">
            <div class="main-title">
              <avatar-button class="icon-component svg-white-color avatar"></avatar-button>
              <p
                class="change-name main-title"
                @keydown="$event.stopPropagation()"
                @keyup="$event.stopPropagation()"
                @keypress="$event.stopPropagation()"
                contenteditable="true"
                v-text="profileSettings.name"
                @keydown.enter="profileSettings.name = ($event.target as HTMLInputElement).innerText"
                @blur="profileSettings.name = ($event.target as HTMLInputElement).innerText"
                placeholder="Enter a Name"
              ></p>
              <edit-button
                class="icon-component edit-btn"
                @click.native="setCaretToEnd($event.currentTarget.previousElementSibling)"
              ></edit-button>
            </div>
          </div>
        </div>
      </span>
      <!-- embedded settings end -->

      <span v-if="!channel.addEditGroupOpen && !channel.embeddedSettingsOpen">
        <div class="title-description" v-if="channel.isExpanded" @click="$emit(`uuid-clicked`, channel.uuid)">
          {{
            channel.type == "public"
              ? translations.chat.group_chatroom
              : channel.type == "group"
              ? translations.chat.private_group_chat
              : translations.chat.private_chat
          }}
        </div>
        <div
          class="chat-actions"
          v-bind:style="{
            paddingRight: embeddedIconPaddingRight
          }"
        >
          <!-- isEmbed &&  -->
          <close-button
            v-if="fullScreen"
            class="icon-component embedded-close"
            @click.native="
              $emit(`update-screen-settings`, {
                fullScreen: false,
                respMainWindowOpen: false,
                mobileButtonChecked: false
              });
              forceRerender();
            "
          >
          </close-button>
          <close-button
            class="icon-component"
            @click.native="
              $emit('open-chat', { channelKey, forceToggle: true, forceOpen: false, forceClose: true });
              channel.expansion_touched = true;
            "
            v-if="!fullScreen && !isEmbed"
          >
          </close-button>
          <minimize-button
            class="icon-component"
            v-if="channel.isExpanded && !fullScreen && !isEmbed"
            @click.native="
              channel.isExpanded = false;
              channel.expansion_touched = true;
              forceRerender();
            "
          ></minimize-button>
          <mute-button
            class="icon-component no-opacity"
            @click.native="
              profileSettings.muteAllSounds = false;
              channel.mute = false;
              forceRerender();
            "
            v-if="channel.mute || profileSettings.muteAllSounds"
          ></mute-button>
          <speaker-button
            class="icon-component"
            @click.native="
              channel.mute = true;
              forceRerender();
            "
            v-if="!channel.mute && !profileSettings.muteAllSounds"
            :class="{ 'no-opacity': channel.mute }"
          ></speaker-button>
          <add-user-button
            class="icon-component"
            v-if="
              (channel.type == 'private' || (channel.type == 'group' && channel.group_owner == uuid)) &&
              channel.isExpanded
            "
            @click.native="
              channel.addEditGroupOpen = true;
              channel.isExpanded = true;
              forceRerender();
            "
          ></add-user-button>
          <settings-button
            class="icon-component settings"
            v-if="channel.type == 'group' && channel.group_owner != uuid && channel.isExpanded"
            @click.native="
              channel.addEditGroupOpen = true;
              channel.isExpanded = true;
              forceRerender();
            "
          >
          </settings-button>
          <!-- settings name change -->
          <settings-button
            class="translateY15"
            v-if="isAnonymVenue && hasOnlyEmbedded"
            @click.native="
              channel.embeddedSettingsOpen = true;
              forceRerender();
            "
          >
          </settings-button>
          <!-- settings name change -->
          <video-button
            :class="{ 'blur-actions': lockSend }"
            class="icon-component"
            v-if="['private', 'group'].includes(channel.type) && enableVideoChat && channel.isExpanded"
            @click.native="
              $emit('open-video-chat', channel);
              channel.isExpanded = true;
              forceRerender();
            "
          ></video-button>
        </div>
        <div class="main-title" @click="$emit('uuid-clicked', channel.uuid)">
          <div
            :class="{
              pointer: !channel.isExpanded,
              bold: messageCount
            }"
            @click="
              !channel.isExpanded &&
                $emit('open-chat', { channelKey, forceToggle: true, forceOpen: true, forceClose: false })
            "
          >
            {{ cutString(channel.display_name, 35) }}
            <div
              class="counter"
              v-if="messageCount && channel.isOpen && !channel.isExpanded"
              v-bind:style="{
                color: channel.avatarBackground
              }"
            >
              {{ messageCount }}
            </div>
          </div>
        </div>
      </span>
    </div>
    <div
      ref="chatMessages"
      class="content"
      :class="{ open: channel.isExpanded }"
      :id="'content_' + channel.container_div_id"
    >
      <VueEternalLoading position="top" :load="infiniteHandler" :container="chatMessages">
        <template #loading><div data-testid="loading"></div></template>
        <template #no-more>
          <div data-testid="no-more-messages"></div>
        </template>
        <template #no-results>
          <div data-testid="no-messages"></div>
        </template>
        <template #error>
          <div data-testid="loading-messages-failed">Error loading messages</div>
        </template>
      </VueEternalLoading>
      <div
        class="msg"
        v-for="(msg, $index) of messages"
        :class="{ right: (msg.publisher || msg.uuid) == uuid }"
        :style="{
          backgroundColor:
            (msg.publisher || msg.uuid) == uuid
              ? channel.type == 'public'
                ? primaryColorWithOpacity
                : secondaryColorWithOpacity
              : 'white'
        }"
        :key="$index"
      >
        <div class="message-title" v-if="!channel.uuid && (msg.publisher || msg.uuid) !== uuid">
          {{ cutString(msg.name) }}
        </div>
        <span class="download-file" v-if="msg.message.file" @click="downloadFile(msg)">
          <span v-if="!msg.preview">
            {{ msg.message.file.name }}
          </span>
          <img v-if="msg.preview" :src="msg.preview" alt="Preview" />
        </span>
        <span
          v-if="msg.message.description == 'otChatOpen_____invite'"
          @click="$emit('open-video-chat', channel, true)"
          class="open-chat"
        >
          <video-button></video-button>{{ translations.chat.video_chat_invitation }}
        </span>
        <span v-else v-html="urlify(msg.message.description)"> </span>
        <div class="date-formatted">{{ formatDate(msg) }}</div>
        <div
          class="triangle"
          :style="{
            borderColor:
              (msg.publisher || msg.uuid) == uuid
                ? channel.type == 'public'
                  ? primaryColorWithOpacity
                  : secondaryColorWithOpacity
                : 'white'
          }"
        ></div>
      </div>
    </div>
    <div
      class="footer"
      :class="{
        open: channel.isExpanded,
        'blur-actions': lockSend,
        'input-sticky': stickyInput === 'footer_' + channel.container_div_id && isLandscapeMode
      }"
      :id="'footer_' + channel.container_div_id"
    >
      <emojis
        @selectEmoji="
          selectEmoji($event);
          forceRerender();
        "
        @close="
          channel.emojiExpanded = false;
          forceRerender();
          scrollToBottom('#content_' + channel.container_div_id, 50);
        "
        v-if="channel.emojiExpanded"
        :channel="channel"
        :translations="translations"
      ></emojis>
      <close-button
        v-if="channel.uploadExpanded"
        @click.native="
          channel.uploadExpanded = false;
          channel.fileRecords = [];
          forceRerender();
          scrollToBottom('#content_' + channel.container_div_id, 50);
        "
        class="icon-component"
        :class="{
          'svg-primary-color': channel.type == 'public',
          'svg-secondary-color': channel.type != 'public'
        }"
        style="margin-left: auto; margin-bottom: 8px"
      ></close-button>
      <VueFileAgent
        class="vue-file-agent-wrapper"
        :class="{ hidden: !channel.uploadExpanded }"
        :ref="'vueFileAgent-' + channel.container_div_id"
        :theme="'list'"
        :multiple="false"
        :deletable="true"
        :meta="true"
        :maxSize="'5MB'"
        :maxFiles="10"
        :helpText="translations.chat.file_upload.help_text"
        :errorText="{
          type: translations.chat.file_upload.not_allowed_type_error,
          size: translations.chat.file_upload.max_size_error
        }"
        v-model:raw-model-value="channel.fileRecords"
        @beforedelete="
          channel.fileRecords = [];
          channel.uploadExpanded = false;
        "
        @input="setCaretToEnd(channel.container_div_id)"
      ></VueFileAgent>
      <span
        class="num_files_attached"
        v-if="channel.fileRecords && channel.fileRecords.length && !channel.uploadExpanded"
        >{{ channel.fileRecords.length }} {{ translations.chat.file_upload.num_files_attached }}</span
      >
      <input
        :id="'chat_input_' + channel.container_div_id"
        type="text"
        class="chat-input"
        v-model="channel.currentMessage"
        @keydown.stop=""
        @keyup.stop=""
        @keypress.stop=""
        @keydown.enter.stop="chatPressEnter($event)"
        @focus="stickyInput = 'footer_' + channel.container_div_id"
        @blur="stickyInput = ''"
        :placeholder="translations.chat.write_message"
      />
      <div v-if="isLoading" class="loader">
        <VueLoadingOverlay :active="true" :width="20" :height="20" :enforce-focus="false" :is-full-page="false" :opacity="0" />
      </div>
      <div class="actions" :class="{ private: channel.type != 'public' }">
        <smiley-button
          class="svg-primary-color"
          @click.native="
            channel.emojiExpanded = !channel.emojiExpanded;
            channel.uploadExpanded = channel.emojiExpanded ? false : channel.uploadExpanded;
            forceRerender();
            scrollToBottom('#content_' + channel.container_div_id, 50);
          "
        ></smiley-button>
        <attach-button
          class="svg-primary-color"
          @click.native="
            channel.uploadExpanded = !channel.uploadExpanded;
            channel.emojiExpanded = channel.uploadExpanded ? false : channel.emojiExpanded;
            forceRerender();
            scrollToBottom('#content_' + channel.container_div_id, 50);
          "
          v-if="dataFilesEnabled"
        ></attach-button>
        <send-button class="svg-primary-color" @click.native="chatPressSend()"></send-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Channel, Channels, ProfileSettings, UserData, UsersData, ChatMessage, Translations } from "@/types";
import { VueEternalLoading } from "@ts-pro/vue-eternal-loading";
import { PropType, ref, defineComponent } from "vue";
import { expMixins } from "../functions";
import Emojis, { IEmoji } from "./Emoji.vue";
import {
  SendButton,
  SmileyButton,
  AttachButton,
  CloseButton,
  AddUserButton,
  MuteButton,
  SpeakerButton,
  MinimizeButton,
  AvatarButton,
  EditButton,
  SettingsButton,
  VideoButton
} from "./svgs.vue";
import GroupSettings from "./GroupSettings.vue";

export default defineComponent({
  name: "ChatWindow",
  components: {
    VueEternalLoading,
    Emojis,
    SendButton,
    SmileyButton,
    AttachButton,
    CloseButton,
    AddUserButton,
    MuteButton,
    SpeakerButton,
    MinimizeButton,
    AvatarButton,
    EditButton,
    SettingsButton,
    VideoButton,
    GroupSettings
  },
  setup() {
    const chatMessages = ref();
    return { chatMessages };
  },
  data() {
    return {
      lockSend: false,
      primaryColorWithOpacity: "",
      secondaryColorWithOpacity: "",
      stickyInput: "",
      isLoading: false
    };
  },
  props: {
    pns: {
      type: Object,
      required: true
    },
    channelKey: {
      type: String,
      required: true
    },
    channels: {
      type: Object as PropType<Channels>,
      required: true
    },
    embeddedIconPaddingRight: {
      type: String,
      default: "0px"
    },
    uuid: {
      type: String,
      required: true
    },
    fullScreen: {
      required: false,
      type: Boolean
    },
    isEmbed: {
      type: Boolean
    },
    isLandscapeMode: {
      type: Boolean
    },
    profileSettings: {
      type: Object as PropType<ProfileSettings>,
      required: true
    },
    enableVideoChat: {
      type: Boolean,
      default: false
    },
    isAnonymVenue: {
      type: Boolean,
      default: false
    },
    hasOnlyEmbedded: {
      type: Boolean,
      default: false
    },
    messageCount: {
      type: Number,
      default: 0
    },
    messages: {
      type: Array as PropType<ChatMessage[]>,
      default: []
    },
    primaryColor: {
      type: String,
      default: "#22AABB"
    },
    secondaryColor: {
      type: String,
      default: "#983AD2"
    },
    dataFilesEnabled: {
      type: Boolean,
      default: true
    },
    usersData: {
      type: Object as PropType<UsersData>,
      required: true
    },
    activeUsersUUIDs: {
      type: Object as PropType<string[]>,
      default: []
    },
    translations: {
      type: Object as PropType<Translations>,
      required: true
    }
  },
  mounted() {
    this.primaryColorWithOpacity = expMixins.methods.hexToRgba(this.primaryColor, 0.26);
    this.secondaryColorWithOpacity = expMixins.methods.hexToRgba(this.secondaryColor, 0.26);
  },
  computed: {
    channel(): Channel {
      return this.channels[this.channelKey];
    },
    currentUserData(): UserData | undefined {
      if (this.uuid && this.usersData && this.usersData[this.uuid]) {
        return this.usersData[this.uuid];
      }
    }
  },
  methods: {
    cutString: expMixins.filters.cutString,
    scrollToBottom: expMixins.methods.scrollToBottom,
    logErrors: expMixins.methods.logErrors,
    formatDate: expMixins.filters.formatDate,
    urlify: expMixins.filters.urlify,
    forceRerender() {
      this.$emit("force-rerender");
    },
    setCaretToEnd(target /*: HTMLDivElement*/) {
      if (typeof target === "string") {
        target = this.$el.querySelector("#chat_input_" + target);
      }
      setTimeout(() => {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(target);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
        target.focus();
        range.detach(); // optimization

        // set scroll to the end if multiline
        target.scrollTop = target.scrollHeight;
      }, 200);
    },
    async chatPressEnter(event: KeyboardEvent) {
      if (!this.lockSend) {
        this.lockSend = true;
        await this.handleFileUpload(this.channelKey);
        // this.channel.currentMessage = (event.target as HTMLElement).innerText;
        if (
          !event ||
          event.shiftKey ||
          !this.channel.currentMessage ||
          !this.channel.currentMessage.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029\s]+/g, "").length
        ) {
          this.lockSend = false;
          return;
        }
        await new Promise((resolve) =>
          this.$emit("send-message", this.channelKey, this.channel.currentMessage, resolve)
        );
        this.channel.currentMessage = "";
        (event.target as HTMLElement).innerHTML = "";
        this.channel.uploadExpanded = false;
        this.channel.emojiExpanded = false;
        this.scrollToBottom("#content_" + this.channel.container_div_id, 50, true);
        this.lockSend = false;
      }
    },
    async handleFileUpload() {
      if (this.channel && this.channel.fileRecords && this.channel.fileRecords.length) {
        this.isLoading = true;
        await this.pns.pubnub_sendFiles(this.channelKey);
        this.isLoading = false;
        this.channel.fileRecords = [];
        this.channel.uploadExpanded = false;
      }
    },
    async downloadFile(message: ChatMessage) {
      this.pns.pubnub_downloadFile(message);
    },
    async chatPressSend() {
      if (!this.lockSend) {
        this.lockSend = true;
        await this.handleFileUpload(this.channelKey);

        if (
          this.channel.currentMessage &&
          this.channel.currentMessage.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029\s]+/g, "").length
        ) {
          await this.pns.pubnub_pushMessage(this.channelKey, this.channel.currentMessage);
          this.channel.currentMessage = "";
        }
        this.channel.uploadExpanded = false;
        this.channel.emojiExpanded = false;
        this.scrollToBottom("#content_" + this.channel.container_div_id, 50, true);
        this.lockSend = false;
      }
    },
    getLastTimeToken() {
      const messages: ChatMessage[] = this.messages;
      if (messages.length > 0 && messages[0]?.timetoken) {
        let timeToken = BigInt(messages[0]?.timetoken);
        timeToken--;
        return timeToken;
      }
      return null;
    },
    async infiniteHandler({ loaded, noMore, noResults }, { isFirstLoad }) {
      this.isLoading = true;
      const subscriptionName = this.channel.subscription_name;
      const lastTimeToken = this.getLastTimeToken();

      await this.pns.pubnub_fetchMessages([subscriptionName], lastTimeToken);
      await this.update_previewUrls();

      if (isFirstLoad && this.messages.length > 0) {
        // Add to channel history when it's not an empty chat
        this.addChannelMembership();
      }

      if (this.messages.length === 0 && isFirstLoad) {
        noResults();
      } else if (this.messages.length < 25 || lastTimeToken === this.getLastTimeToken()) {
        noMore();
      } else {
        loaded();
      }
      this.isLoading = false;
    },
    addChannelMembership() {
      this.pns.pubnub_setChannelMembership(this.channel, `${Date.now()}9999`);
      this.channel.hasMembership = true;
    },
    isMessageWithFileAndNoPreviewUrl(message?: ChatMessage) {
      if (!message.preview && message.message?.file) {
        const fname = message.message.file.name;
        if (fname.endsWith(".jpg") || fname.endsWith(".jpeg") || fname.endsWith(".png") || fname.endsWith(".gif")) {
          return true;
        }
      }
      return false;
    },
    async update_previewUrls() {
      if (this.messages.length === 0) {
        return;
      }
      for (const msg of [...this.messages].reverse()) {
        try {
          if (this.isMessageWithFileAndNoPreviewUrl(msg)) {
            const res = await this.pns.pubnub_downloadFile(msg, true);
            msg.preview = res;
          }
        } catch (error) {
          this.logErrors(error);
        }
      }
    },
    selectEmoji($event: IEmoji) {
      this.channel.currentMessage = this.channel.currentMessage + $event.data;
      const el = this.$el.querySelector("#chat_input_" + this.channel.container_div_id);
      this.setCaretToEnd(el);
    }
  }
});
</script>
<style scoped>
.loader {
  position: relative;
  height: 24px;
  width: 24px;
  margin-top: 3px;
  margin-right: 2px;
}
</style>
