<template>
  <div ref="element"></div>
</template>
<script lang="ts" setup>
import { defineComponent, onBeforeUnmount, onMounted, ref, toRaw } from "vue";
import OT from "@opentok/client";
import expMixins from "@/functions";

defineComponent({
  name: "subscriber"
});

const props = withDefaults(
  defineProps<{
    stream: OT.Stream;
    session: OT.Session;
    options?: OT.SubscriberProperties;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: "subscriber-connected", ev: OT.Subscriber): void;
  (e: "subscriber-created", ev: OT.Subscriber): void;
  (e: "error", ev: OT.OTError): void;
}>();

const consoleLog = expMixins.methods.consoleLog;
const element = ref(null);
let subscriber: OT.Subscriber;

onMounted(() => {
  const session = toRaw(props.session);
  const stream = toRaw(props.stream);
  const options = toRaw(props.options);

  subscriber = session.subscribe(stream, element.value, options, (err) => {
    if (err) {
      emit("error", err);
    } else {
      emit("subscriber-connected", subscriber);
    }
  });
  emit("subscriber-created", subscriber);
});

onBeforeUnmount(() => {
  consoleLog("Subscriber destroy");
  toRaw(props.session).unsubscribe(subscriber);
});
</script>
