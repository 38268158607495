<template>
  <div ref="element"></div>
</template>

<script lang="ts" setup>
import OT from "@opentok/client";
import { defineComponent, onBeforeUnmount, onMounted, watch, ref, toRaw } from "vue";
import expMixins from "@/functions";

defineComponent({
  name: "publisher"
});

const props = withDefaults(
  defineProps<{
    cameraOn: boolean;
    microphoneOn: boolean;
    session?: OT.Session;
    options?: OT.PublisherProperties;
  }>(),
  {}
);

defineExpose({ exposed: { getPublisher } });

const emit = defineEmits<{
  (e: "publisher-init-completed"): void;
  (e: "publisher-created", ev: OT.Publisher): void;
  (e: "publisher-connected", ev: OT.Publisher): void;
  (e: "access-denied", ev: boolean): void;
  (e: "grant-access", ev: boolean): void;
  (e: "error", ev: OT.OTError): void;
}>();

const element = ref(null);
let publisher: OT.Publisher = null;

const { consoleLog } = expMixins.methods;

function getPublisher() {
  return publisher;
}

onMounted(() => {
  const session = toRaw(props.session);
  const options = toRaw(props.options);

  publisher = OT.initPublisher(element.value, options, (err) => {
    if (err) {
      emit("error", err);
    } else {
      emit("publisher-init-completed");
    }
  });
  publisher.on({
    accessDenied: () => {
      emit("access-denied", true);
    },
    accessAllowed: () => {
      emit("access-denied", false);
    },
    accessDialogOpened: () => {
      emit("grant-access", true);
    },
    accessDialogClosed: () => {
      emit("grant-access", false);
    }
  });

  emit("publisher-created", publisher);

  if (session) {
    const publish = () => {
      consoleLog("Session publish");
      session.publish(publisher, (err) => {
        if (err) {
          emit("error", err);
        } else {
          emit("publisher-connected", publisher);
        }
      });
    };
    if (session.connection) {
      publish();
    } else {
      consoleLog("Waiting for session connect before publish");
      session.on("sessionConnected", () => {
        publish();
      });
    }
  }
});

watch(
  () => props.microphoneOn,
  (isOn) => publisher.publishAudio(isOn)
);

watch(
  () => props.cameraOn,
  (isOn) => publisher.publishVideo(isOn)
);

onBeforeUnmount(async () => {
  consoleLog("Publisher destroy");
  toRaw(props.session).unpublish(publisher);
  publisher.destroy();
});
</script>
